import React from 'react'
import { Formik, Form } from 'formik'
import { AllocationProfile, CustomerIncome, IncomeTaxReturnType, OccupationStatus, OccupationType } from '@healthmoney/domain'
import { Yup } from 'helpers'

import { Button, FieldGroup, MoneyField, NumberField, SelectFieldOptions, TextField } from '../../../../components';
import { useLocation } from 'react-router-dom';
import useRegistrationBoarding from '../register-boarding/useRegistrationBoarding';

const CustomerIncomeSchema = Yup.object().shape({
    occupationType: Yup.string().required(),
    occupationStatus: Yup.string().optional(),
    occupation: Yup.string().required(),
    montlySavingDay: Yup.number().required(),
    montlySaving: Yup.number().required()
        .test("min saving", "Montly Saving must be grater than 0", val => val > 0),
    incomeTaxReturnType: Yup.string().required(),
    expectedRetirementAge: Yup.string().required(),
    desiredMontlyCompensation: Yup.number().required()
        .test("min desired montly compensation", "Desired Montly Compensation must be grater than 0", val => val > 0),
    compensation: Yup.number().required()
        .test("min compensation", "Compensation must be grater than 0", val => val > 0),
});

type ComponentProps = {
    initialValues?: CustomerIncome
    allocationProfiles?: AllocationProfile[]
    showInvestorProfile?: boolean
    onSubmit: (income: CustomerIncome) => Promise<void>
}

type CustomerIncomeFormProps = Pick<CustomerIncome,
    'occupationType' |
    'occupationStatus' |
    'occupation' |
    'montlySavingDay' |
    'montlySaving' |
    'incomeTaxReturnType' |
    'expectedRetirementAge' |
    'desiredMontlyCompensation' |
    'compensation'
>;

export default function IncomeForm({
    initialValues = {
        occupationType: OccupationType.Clt,
        occupationStatus: OccupationStatus.Active,
        occupation: '',
        montlySavingDay: 10,
        montlySaving: 0,
        incomeTaxReturnType: IncomeTaxReturnType.Complete,
        expectedRetirementAge: '75',
        desiredMontlyCompensation: 0,
        compensation: 0,
    },
    showInvestorProfile = false,
    allocationProfiles,
    onSubmit,
}: ComponentProps) {

    const [error, setError] = React.useState<any>(null)
    const { pathname } = useLocation()
    const { maxStep } = useRegistrationBoarding()

    const buttonText = !pathname.includes("/registration") ?
        'Salvar' : maxStep > 1
            ? 'Salvar'
            : 'Próximo'

    return (
        <Formik
            initialValues={initialValues as CustomerIncomeFormProps}
            validationSchema={CustomerIncomeSchema}
            validateOnMount
            onSubmit={async (values, { setSubmitting }) => {
                try {
                    setSubmitting(true)

                    //passar dados um por um

                    // const income:CustomerIncome={
                    //     incomeTaxReturnType: "C:/Users/velos/Área de Trabalho/Trabalho/hm/behemoth/modules/domain/build/domain.generated".Complete,
                    //     occupationStatus: "C:/Users/velos/Área de Trabalho/Trabalho/hm/behemoth/modules/domain/build/domain.generated".Active,
                    //     occupationType: "C:/Users/velos/Área de Trabalho/Trabalho/hm/behemoth/modules/domain/build/domain.generated".Businessman
                    // }

                    await onSubmit(values)
                } catch (error) {
                    console.error(error)
                    setError(error)
                } finally {
                    setSubmitting(false)
                }
            }}>
            {(formikProps) => (
                <Form>
                    <p className='text-sm mt-6 pb-4 font-bold'>Dados financeiros</p>
                    <FieldGroup>
                        <SelectFieldOptions
                            label='Situação profissional'
                            name='occupationStatus'
                            options={[
                                { text: 'Ativo', value: OccupationStatus.Active },
                                { text: 'Inativo', value: OccupationStatus.Inactive },
                                { text: 'Aposentado', value: OccupationStatus.Retired },
                            ]}
                        />
                        <TextField
                            name='occupation'
                            label='Profissão'
                            type='text'
                        />
                    </FieldGroup>
                    <FieldGroup>
                        <SelectFieldOptions
                            label='Regime de trabalho'
                            name='occupationType'
                            options={[
                                { text: 'Desempregado', value: OccupationType.Unemployed },
                                { text: 'CLT', value: OccupationType.Clt },
                                { text: 'PJ', value: OccupationType.Businessman },
                                { text: 'Empresário', value: OccupationType.LegalPerson },
                            ]}
                        />
                        <MoneyField
                            name='compensation'
                            label='Renda líquida mensal atual'
                            formik={formikProps}
                        />

                    </FieldGroup>
                    <FieldGroup>
                        <MoneyField
                            name='montlySaving'
                            label='Capacidade de poupança mensal'
                            formik={formikProps}
                        />
                        <NumberField
                            name='montlySavingDay'
                            label='Data do aporte mensal'
                            type='number'
                            min='1'
                            max='31'
                        />
                    </FieldGroup>
                    <FieldGroup>
                        <TextField
                            label='Idade esperada de aposentadoria'
                            name='expectedRetirementAge'
                        />
                        <MoneyField
                            name='desiredMontlyCompensation'
                            label='Renda líquida mensal desejada'
                            formik={formikProps}
                        />

                    </FieldGroup>
                    <FieldGroup>
                        <SelectFieldOptions
                            label='Tipo de declaração do Imposto de Renda'
                            name='incomeTaxReturnType'
                            options={[
                                { text: 'Completa', value: IncomeTaxReturnType.Complete },
                                { text: 'Simplificada', value: IncomeTaxReturnType.Simple },
                            ]}
                        />
                    </FieldGroup>
                    {allocationProfiles && (
                        <>
                            <p className='text-sm mt-6 pb-4 font-bold'>Perfil atual</p>
                            <FieldGroup>
                                {showInvestorProfile && (
                                    <SelectFieldOptions
                                        label='Perfil de alocação'
                                        name='investorProfile'
                                        options={allocationProfiles.map((profile => ({
                                            text: profile.name as string,
                                            value: profile.id as string
                                        })))}
                                    />
                                )}
                            </FieldGroup>
                        </>
                    )}
                    {error && (
                        <div className='py-4 font-bold'>
                            {error.message}
                        </div>
                    )}
                    <div className='py-6'>
                        <div className="flex flex-row">
                            <Button
                                disabled={formikProps.isSubmitting || !formikProps.isValid}
                                type="submit">
                                {buttonText}
                            </Button>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}
