import React from 'react'
import { CustomerDetails } from '@healthmoney/domain'
import { doc, getDoc, updateDoc } from 'firebase/firestore'
import { useFirestore } from 'reactfire'

export default function useDetails(uid: string) {
    const [initial, setInitial] = React.useState<CustomerDetails | null>(null);
    const [error, setError] = React.useState<any>(null);
    const db = useFirestore()

    React.useEffect(() => {
        const fetchData = async () => {
            const reference = doc(db, 'core_customers', uid, 'core_customers_details', 'default')
            const snapshot = await getDoc<CustomerDetails>(reference)
            setInitial(snapshot.data() || null)
        }

        fetchData()
            .catch(setError)

    }, [uid])

    const update = async (details: CustomerDetails): Promise<void> => {
        const reference = doc(db, 'core_customers', uid, 'core_customers_details', 'default')
        await updateDoc(reference, details)
    }

    return {
        initial, update, error
    }
}