import {
    AllocationCategory,
    AllocationInvestment,
} from "@healthmoney/domain";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

type UnclassifiedTableExplorerComponentProps = {
    investments: AllocationInvestment[];
    handleSetClassification: (investment: AllocationInvestment, classification: string) => void;
    categories: AllocationCategory[];
    categoryMap: Record<string, AllocationCategory>;
    banksMap: any;
};

export default function UnclassifiedTableExplorerComponent({
    investments,
    handleSetClassification,
    categories,
    categoryMap,
}: UnclassifiedTableExplorerComponentProps) {
    return (
        <>
            <DataTable value={investments} tableStyle={{ minWidth: "50rem" }}>
                <Column field='code' header='Código' sortable />
                <Column field='name' header='Nome' sortable />
                <Column field='type' header='Tipo' sortable />
                <Column field='subtype' header='Sub tipo' sortable />
                <Column field='rateType' header='Tipo de taxa' sortable />
                <Column field='rate' header='Taxa' sortable />
                <Column field='fixedAnnualRate' header='Taxa anual fixa' sortable />
                <Column
                    field='classification'
                    sortable
                    header='Classe'
                    body={(investment: AllocationInvestment) => (
                        <select
                            key={investment.id}
                            className='appearance-none w-full py-1 text-neutral-800 focus:outline-none focus:shadow-outline text-xs'
                            onChange={(v) => handleSetClassification(investment, v.target.value)}
                            defaultValue={investment.categoryId ? categoryMap[investment.categoryId]?.id : ""}>
                            <option key='' value=''>
                                ---
                            </option>
                            {categories.map((category) => (
                                <option key={category.id} value={category.id}>
                                    {category.name}
                                </option>
                            ))}
                        </select>
                    )}
                />
            </DataTable>
        </>
    );
}
