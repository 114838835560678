import RegisterBoardingProducts from '../steps/register-boarding-products'
import Loading from 'components/containers/loading'
import useRegistrationBoarding from '../useRegistrationBoarding'

const RegisterBoardingProductsView = () => {

  const { loaded } = useRegistrationBoarding()

  return (
    <>
      <Loading isLoading={!loaded}>
        <RegisterBoardingProducts />
      </Loading>
    </>
  )
}

export default RegisterBoardingProductsView