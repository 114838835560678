import classNames from 'classnames';
import { PieChart, Pie, ResponsiveContainer, Cell } from 'recharts'

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.25;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text className=' text-xxs font-medium' x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {`${(percent * 100).toFixed(2)}%`}
        </text>
    );
};

type WalletChartComponentProps = {
    values: {
        name: string,
        value: number
    }[]
}

export default function WalletChartComponent({
    values = []
}: WalletChartComponentProps) {

    const showElement = (type: "Preservação" | "Acumulação" | "Crescimento"): boolean => {
        return !!values.find(item => item.name === type && item.value > 0) ?? false
    }

    return (
        <ResponsiveContainer>
            <PieChart width={260} height={260}>
                <Pie
                    data={values}
                    dataKey="value"
                    cx="50%"
                    cy="50%"
                    innerRadius={60}
                    outerRadius={140}
                    label={renderCustomizedLabel}
                    labelLine={false}>
                    {showElement('Preservação') ?
                        <Cell className={'fill-report-100'} /> : null}
                    {showElement('Acumulação') ?
                        <Cell className={'fill-report-500'} /> : null}
                    {showElement('Crescimento') ?
                        <Cell className={'fill-report-700'} /> : null}
                </Pie>
            </PieChart>
        </ResponsiveContainer>
    )
}
