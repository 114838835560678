import classNames from "classnames"

type TabItemProps = {
    text: string
    badge?: string
    active: boolean
    onClick: () => void
}

export default function TabItem({
    text,
    badge,
    active,
    onClick
}: TabItemProps) {
    const linkStyle = classNames(
        'flex-1 p-2 flex items-center justify-center cursor-pointer hover:bg-neutral-100',
        active
            ? 'border-b-primary-500 border-b-2'
            : 'border-b-neutral-400 border-b'
    )

    const spanStyle = classNames(
        active
            ? 'font-bold'
            : 'text-neutral-500'
    )

    return (
        <button onClick={onClick} className={linkStyle}>
            <span className={spanStyle}>{text}</span>
            {badge && <span className='ml-2 text-xxxs bg-primary-500 text-neutral-0 font-bold px-2 py-1 rounded-full'>{badge}</span>}
        </button>
    )
}