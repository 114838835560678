import { useContext, useMemo, useState } from "react";
import { moment } from 'helpers'
import CCV, { CCVResult } from "features/simulators/ccv";
import { RegisterBoardingContext } from "../register-boarding-provider";
import { setRegisterMaxStep, setRegisterRetirementPlan } from "repositories/customers-repository";
import { useNavigate } from "react-router-dom";
import useRegistrationBoarding from "../useRegistrationBoarding";

export default function RegisterBoardingCVV() {

    const { user, uid, maxStep } = useRegistrationBoarding()

    const { profile } = useContext(RegisterBoardingContext)
    const lifeExpectancy = useMemo(() => user?.retirementPlan?.endYear ?? 90, [])
    const defaultRate = useMemo(() => 2.6, [])
    const navigate = useNavigate()
    const [error, setError] = useState<string | null>(null)

    const setRetirementPlan = setRegisterRetirementPlan(uid!)
    const setMaxStep = setRegisterMaxStep()

    const info = useMemo(() => {
        const age =
            moment().diff((user?.details?.birthdate as any).toDate(), 'years', false)

        const retirementPlan = user?.retirementPlan
        return {
            startEquity: retirementPlan?.startEquity ?? 0,
            anualIncomeRate: (profile?.anualIncomeRate || defaultRate) / 100,
            startYear: age,
            endYear: lifeExpectancy,
            contribution: user?.income?.montlySaving || 1000,
            desiredPassiveIncome: user?.income?.desiredMontlyCompensation || 0,
            retirementYear: parseInt(user?.income?.expectedRetirementAge!)
        }
    }, [])

    const onNext = async (result: CCVResult) => {
        try {
            setError(null)

            await Promise.all([
                setRetirementPlan({
                    id: 'default',
                    uid: uid!,
                    cid: user?.details!.cid!,
                    startYear: result.startYear,
                    endYear: result.endYear,
                    startEquity: result.startEquity,
                    contribution: result.contribution,
                    retirementYear: result.retirementYear,
                    pontetialPassiveIncome: result.pontetialPassiveIncome,
                    maximumAccumulatedWealth: result.maximumAccumulatedWealth,
                    createdAt: moment().toDate(),
                }),
                setMaxStep(user?.details?.email!, maxStep > 5 ? maxStep : 5)
            ])

            navigate(`/registration/${uid}/wallet`)
        } catch (error: any) {
            setError(error.toString())
            console.error(error)
        }
    }

    return (
        <>
            <CCV {...info} onNext={onNext} />
            {
                !defaultRate && (
                    <p className="py-2">Perfil sem taxa atribuída, considerando {defaultRate.toFixed(1)}% ao ano.</p>
                )
            }
            {
                error && (
                    <p className="py-2">{error}</p>
                )
            }
        </>
    )
}