import useDashboard from './use-dashboard';
import DashboardViewComponent from './dashboard-view-component';
import { AllocationProfile, CustomerDetails, RegistrationRegister } from '@healthmoney/domain';
import { TUserRegister } from 'repositories/customers-repository';

export default function DashboardView() {
    const {
        customers,
        registers,
        standards,
        plTotal,
        nextMeetingsCount,
        profiles,
        tasks,
        usersTasks
    } = useDashboard()

    return (
        <DashboardViewComponent
            isLoading={customers.status === 'loading'}
            customers={(customers.data || []) as CustomerDetails[]}
            registers={{
                app: registers.app.data as RegistrationRegister[] || [],
                website: registers.website.data as (TUserRegister & RegistrationRegister)[] || []
            }}
            standards={standards}
            plTotal={plTotal}
            nextMeetingsCount={nextMeetingsCount}
            profiles={profiles.data as AllocationProfile[]}
            tasks={tasks}
            usersTasks={usersTasks}
        />
    )
}