import { useEffect, useState } from "react";
import { getProducts } from "repositories/products-repository";
import { Product } from "@healthmoney/domain";
import { TUserRegister, useSetProcessingUserPayment, useSetRegisterState } from "repositories/customers-repository";
import useRegistrationBoarding from "../useRegistrationBoarding";
import calculateRecomendedProduct from "../calculate-recomended-product";
import { useNavigate } from "react-router-dom";
import { useAuth } from "reactfire";

function useRegisterBoardingProducts() {
    const navigate = useNavigate()

    const auth = useAuth();
    const [requesting, setRequesting] = useState(false)
    const [products, setProducts] = useState<Product[] | null>(null)
    const [error, setError] = useState<Error | null>(null)

    const { user, maxStep, uid } = useRegistrationBoarding()
    const setRegisterState = useSetRegisterState()
    const setProcessingUserPayment = useSetProcessingUserPayment()

    useEffect(() => {
        const handle = async () => {
            const products = await getProducts()
            const calculatedProducts = calculateRecomendedProduct({
                retirementPlan: user?.retirementPlan!,
                products
            })
            setProducts(calculatedProducts)
        }
        handle()
    }, [])

    const handleRequestSubscription = async (product: Product) => {
        const email = user?.details?.email

        if (!email) return

        try {
            setRequesting(true)

            const newState: TUserRegister = {
                maxStep: maxStep > 7 ? maxStep : 7,
                product: product.id,
                processing: false,
                locked: true
            }

            await Promise.all([
                setRegisterState(email, newState),
                setProcessingUserPayment(
                    auth.currentUser!.email!,
                    email,
                )
            ])

            navigate(`/registration/${uid}/complete`)
        }
        catch (error: any) {
            setError(error)
        }
        finally {
            setRequesting(false)
        }
    }

    return {
        products,
        requesting,
        handleRequestSubscription,
        error
    }
}

export default useRegisterBoardingProducts