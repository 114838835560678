import { A, PageHeading } from 'components'
import { useParams } from 'react-router-dom'
import { useAllocationEntries, useCategories, useDeleteCustomAllocationEntry, useExclusiveIndications } from 'repositories/allocations-repository'
import CustomAllocationsEntries from '../containers/custom-allocation-entries'
import { ExclusiveAllocationEntry } from '@healthmoney/domain'
import Loading from 'components/containers/loading'
import AllocationsEntries from '../containers/allocation-entries'
import { useCustomerIncome } from 'repositories/customers-repository'
import { Checkbox } from 'primereact/checkbox'
import { useMemo, useState } from 'react'
import { moment } from 'helpers'

const CustomAllocationsDashboardView = () => {

    const params = useParams()
    const exclusiveAllocations = useExclusiveIndications(params.uid!)
    const generalAllocations = useAllocationEntries()
    const user = useCustomerIncome(params.uid!)
    const categories = useCategories()
    const deleteEntry = useDeleteCustomAllocationEntry(params.uid!)

    const notExpiredGeneralAllocations = useMemo(() => {
        const today = moment();
        return generalAllocations.data?.filter(entry =>
            moment(entry.contributionDate.toDate()).add(1, 'day') > today
        )
    }, [generalAllocations.data])

    const notExpiredExclusiveAllocations = useMemo(() => {
        const today = moment();
        return exclusiveAllocations.data?.filter(entry =>
            moment(entry.contributionDate.toDate()).add(1, 'day') > today
        )
    }, [exclusiveAllocations.data])

    const [showExpired, setShowExpired] = useState<boolean>(false)

    const handleDelete = async (entry: ExclusiveAllocationEntry) => {
        await deleteEntry(entry)
    }

    return (
        <>
            <Loading isLoading={exclusiveAllocations.status !== "success" || user.status !== "success"}>
                <PageHeading
                    title={'Recomendações Exclusivas'}
                    description='Aplicadas somente para este cliente'
                    breadcrumb={{
                        homeLink: '/',
                        items: [
                            { text: 'Perfil do cliente', href: `/customers/${params.uid!}` },
                            { text: 'Alocações' }
                        ]
                    }}
                />
                <div className='container mx-auto py-8'>
                    <div className='flex flex-row pb-2'>
                        <A
                            className="mb-3 w-fit"
                            button
                            buttonSecondary
                            buttonCompact
                            href={`/customers/${params.uid}/allocations/create`}>
                            Cadastrar recomendação exclusiva
                        </A>
                        <div className="flex align-items-center gap-2 ml-3">
                            <label htmlFor="expirados" className='select-none'>Mostrar expirados</label>
                            <Checkbox
                                inputId="expirados"
                                name="expirados"
                                onChange={(e) => setShowExpired(e.target.checked!)}
                                checked={showExpired} />
                        </div>
                    </div>
                    <div className='mb-3 flex flex-col gap-3'>
                        <CustomAllocationsEntries
                            categories={categories.data}
                            entries={showExpired ? exclusiveAllocations.data : notExpiredExclusiveAllocations}
                            handleDelete={handleDelete}
                        />
                    </div>
                </div>
                <PageHeading
                    title={'Recomendações Globais'}
                    description='Aplicadas a todos os clientes com o mesmo perfil de investidor'
                />
                <div className='container mx-auto py-8'>
                    <div className='mb-3'>
                        <AllocationsEntries
                            categories={categories.data}
                            entries={notExpiredGeneralAllocations}
                            selectedInvestoProfile={user.data?.investorProfile!}
                            hideAdminActions={true}
                        />
                    </div>
                </div>
            </Loading>
        </>
    )
}

export default CustomAllocationsDashboardView
