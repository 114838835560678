import { Button, FieldGroup, PageHeading, TextField } from "components";
import { Form, Formik } from "formik";
import { Link } from "react-router-dom";

const HelpCard = ({ title, link }: { title: string, link: string }) => (
    <Link to={link} className="border block p-4 mr-2 border-neutral-500">
        <div>
            <p>{title}</p>
        </div>
    </Link>
)


export default function HelpView() {

    const handleSearch = (search: string) => {
        console.log(search)
    }

    return (
        <>
            <PageHeading
                title='Ajuda'
                description='Perguntas frequentes, documentação e registro de alterações do site.'
            />
            <div className='container mx-auto'>
                <div className="flex flex-row pt-4">
                    <HelpCard title='Base de Conhecimento' link='/docs' />
                    <HelpCard title='Historico de Alterações' link='/changelog' />
                </div>
                <div className="py-3 max-w-md">
                    <Formik
                        validateOnChange
                        initialValues={{ search: '' }}
                        onSubmit={({ search }) => handleSearch(search)}>
                        <Form>
                            <FieldGroup>
                                <TextField
                                    name='search'
                                    label='Pesquisar'
                                    type='text'
                                />
                                <div>
                                    <Button className="mt-2" secondary type="submit">Pesquisar</Button>
                                </div>
                            </FieldGroup>
                        </Form>
                    </Formik>
                </div>
            </div>
        </>
    )
}