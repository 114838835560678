type LoadingProps = {
    isLoading: boolean
    children: React.ReactNode
}

export default function Loading({
    children,
    isLoading
}: LoadingProps) {
    if (!isLoading)
        return (
            <>
                {children}
            </>
        )

    return (
        <div className='container mx-auto py-8'>
            <p>Carregando...</p>
        </div>
    )
}