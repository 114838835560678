import { Outlet } from "react-router-dom";
import CustomersStateView from "./views/customers-state-view";
import VisualizeAllConsultantsView from "./views/visualize-all-consultants-view";
import VisualizeAllCustomersView from "./views/visualize-all-customers-view";
import DropdownMenu, { DropdownItem } from "components/elements/dropdown-menu";
import DropdownMenuItem from "components/elements/dropdown-menu-item";
import AllocationsDashboardView from "features/allocations/views/allocations-dashboard-view";
import AllocationsRecommendationView from "features/allocations/views/allocations-recommendation-view";
import AllocationsInvestmentsView from "features/allocations/views/allocations-investments-view";
import EditAllocationProfileView from "features/allocations/views/edit-allocation-profile/edit-allocation-profile-view";
import AddAllocationEntryView from "features/allocations/views/add-allocation-entry-view";
import AdminDashboard from "./views/dashboard";
import Notifications from "./views/notifications";

function AdminProvider() {
    return (
        <>
            <DropdownMenu>
                <DropdownItem title='Painel' link='/admin' />

                <DropdownMenuItem title='Clientes' items={[
                    {
                        title: 'Todos os clientes',
                        link: '/admin/all-customers'
                    },
                    {
                        title: 'Sincronização com aplicativo',
                        link: '/admin/customers-state'
                    },
                    {
                        title: 'Enviar push notification',
                        link: '/admin/customers/send-push-notification'
                    }
                ]} />
                <DropdownMenuItem title='Consultores' items={[
                    {
                        title: 'Todos os consultores',
                        link: '/admin/all-consultants'
                    },
                    // {
                    //     title: 'Cadastrar novo consultor',
                    //     link: '/admin/all-consultants'
                    // }
                ]} />
                <DropdownMenuItem title='Assets' items={[
                    {
                        title: 'Perfils de investidor',
                        link: '/admin/investor-profiles'
                    },
                    {
                        title: 'Recomendações globais',
                        link: '/admin/global-asset-allocation'
                    },
                    {
                        title: 'Cadastrar recomendação global',
                        link: '/admin/global-asset-allocation/create'
                    },
                    {
                        title: 'Classificações pendentes',
                        link: '/admin/unclassified-assets'
                    }
                ]} />
            </DropdownMenu>
            <Outlet />
        </>
    );
}

const router = {
    path: "/admin",
    Component: AdminProvider,
    children: [
        {
            path: '',
            element: <AdminDashboard />
        },
        {
            path: 'customers-state',
            element: <CustomersStateView />
        },
        {
            path: 'all-customers',
            element: <VisualizeAllCustomersView />
        },
        {
            path: 'all-consultants',
            element: <VisualizeAllConsultantsView />
        },
        {
            path: "investor-profiles",
            element: <AllocationsDashboardView />,
        },
        {
            path: "global-asset-allocation",
            element: <AllocationsRecommendationView />,
        },
        {
            path: "unclassified-assets",
            element: <AllocationsInvestmentsView />,
        },
        {
            path: "investor-profiles/:id",
            element: <EditAllocationProfileView />,
        },
        {
            path: "global-asset-allocation/create",
            element: <AddAllocationEntryView />,
        },
        {
            path: "customers/send-push-notification",
            element: <Notifications />,
        }
    ]
}

export default router