import { PageHeading } from 'components'
import Loading from 'components/containers/loading'
import useAdminDashboard from './use-admin-dashboard';
import { formatCurrency } from 'helpers';

function AdminDashboard() {
    const { fullAssetValue, accountsTotal } = useAdminDashboard();

    return (
        <Loading isLoading={false}>
            <PageHeading
                title='Painel'
                description='Situação geral da plataforma' />
            <section className="w-full bg-neutral-0 pb-4">
                <div className='container mx-auto flex flex-row pt-6 gap-4'>
                    <div className=' bg-neutral-0 rounded shadow-xl relative' style={{ width: 900 }}>
                        <div className='pt-6 pl-6'>
                            <h3 className='font-bold text-md leading-far'>Visualizações</h3>
                            <p>Total de {accountsTotal} usuários com conta ativa</p>
                        </div>
                        <iframe
                            title="Screene"
                            width="900"
                            height="675"
                            src="https://lookerstudio.google.com/embed/reporting/b5f34bc3-ab30-47e1-8a3d-c9d8116523b7/page/ukfoD"
                            style={{ border: 0 }}
                            allowFullScreen
                            sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"></iframe>
                    </div>
                    <div className='flex-col justify-stretch'>
                        <div className='bg-neutral-0 rounded shadow-xl relative flex-1 mb-3'>
                            <div className='pt-6 pl-6'>
                                <h3 className='font-bold text-md leading-far'>Patrimônio</h3>
                                <p>Patrimônio total de todos clientes (ativos e desativados)</p>
                            </div>
                            <div className='px-6 py-4'>
                                <p className='mb-3 text-md leading-far'>
                                    {formatCurrency(fullAssetValue)}
                                </p>
                            </div>
                        </div>

                        <div className='bg-neutral-0 rounded shadow-xl relative flex-1'>
                            <div className='pt-6 pl-6'>
                                <h3 className='font-bold text-md leading-far'>Links</h3>
                                <p>Acesso as plataformas parceiras</p>
                            </div>
                            <div className='p-6'>
                                <p className='mb-3'>Para uma visão detalhada dos eventos comuns do aplicativo
                                    <a
                                        className=' text-primary-500'
                                        rel='noreferrer'
                                        target='_blank'
                                        href='https://analytics.google.com/analytics/web/#/p220469740/reports/dashboard?params=_u..nav%3Dmaui&r=firebase-overview&ruid=firebase-overview,app,firebase&collectionId=app'>
                                        {' '}acesse o painel no Google Analytics
                                    </a>.
                                </p>
                                <p>Caso precise alterar senha ou bloquear algum usuário,
                                    <a
                                        className=' text-primary-500'
                                        rel='noreferrer'
                                        target='_blank'
                                        href='https://console.firebase.google.com/u/0/project/health-money/authentication/users'>
                                        {' '}acesse o painel do Firebase
                                    </a>.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Loading>
    )
}

export default AdminDashboard;