import React, { createContext } from 'react';
import { getAuth } from 'firebase/auth'

type Session = {
    initialized: boolean,
    logged: boolean,
    uid?: string,
    email?: string,
    isAdmin?: boolean,
    isConsultant?: boolean,
    update?: (newSession: Session) => void,
    logout?: () => void,
}

export const SessionContext = createContext<Session>({
    initialized: false,
    logged: false,
})

export const SessionProvider = ({ children }: { children: React.ReactNode }) => {
    const [session, setSession] = React.useState<Session>({
        initialized: false,
        logged: false,
    });

    React.useEffect(() => {
        const auth = getAuth()

        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user != null && user.email) {
                const newSession = {
                    initialized: true,
                    logged: true,
                    email: user.email!,
                    uid: user.uid,
                    isAdmin: false,
                    isConsultant: false,
                }

                user.getIdTokenResult().then((idTokenResult) => {
                    setSession({
                        ...newSession,
                        isAdmin: idTokenResult.claims.isAdmin,
                        isConsultant: idTokenResult.claims.isConsultant,
                    })
                })
            } else {
                setSession({
                    initialized: true,
                    logged: false,
                })
            }
        })

        return () => unsubscribe()
    }, [])

    return (
        <SessionContext.Provider value={{ ...session, update: setSession }}>
            {children}
        </SessionContext.Provider>
    )
}

