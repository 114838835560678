import ChangelogView from "./views/changelog";
import DocsView from "./views/docs";
import HelpView from "./views/help";

export const helpRoutes = [
    {
        path: '/help',
        element: <HelpView />,
    },
    {
        path: '/changelog',
        element: <ChangelogView />,
    },
    {
        path: '/docs',
        element: <DocsView />,
    },
]