import { useContext } from 'react'
import { RegisterBoardingContext } from './register-boarding-provider'

const useRegistrationBoarding = () => {

  const {
    categories, loaded, profile,
    user, location, uid,
    maxStep, profiles, processing
  } = useContext(RegisterBoardingContext)

  return { categories, loaded, profile, user, location, uid, maxStep, profiles, processing }
}

export default useRegistrationBoarding