import classNames from "classnames"
import { A } from "components/elements"

export type StepperItem = {
    text: string,
    href: string
}

export type StepperProps = {
    currentIndex: number
    items: StepperItem[],
    uid?: string,
    maxStep: number
}

export default function Stepper(props: StepperProps) {
    return (
        <div className="flex flex-row">
            {props.items.map((item, index) => (
                <div key={index} className="flex flex-1 flex-col p-1">
                    <A
                        href={props.uid ? `${ props.uid }/${ item.href }` : `${ item.href }`}
                        className={classNames(
                            index > props.maxStep ? 'pointer-events-none' : ''
                        )}
                    >
                        <div className={classNames(
                            "pb-2 text-neutral-900 text-xs",
                            index === props.currentIndex ? 'font-bold' : ''
                        )}>
                            {index + 1}. {item.text}
                        </div>
                        <div className="relative">
                            <div className={classNames(
                                'absolute w-full h-2 bg-neutral-300'
                            )}></div>
                            <div className={classNames(
                                'absolute h-2 bg-success-500 left-0',
                                index < props.maxStep ? 'w-full' : '',
                                index === props.maxStep ? 'w-1/2' : ''
                            )}></div>
                        </div>
                    </A>
                </div>
            ))}
        </div>
    )
}