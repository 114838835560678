import { getAuth, signOut } from "firebase/auth"
import { A } from "../components"
import { useNavigate } from "react-router-dom"
import { UserCircleIcon, ArrowRightOnRectangleIcon, QuestionMarkCircleIcon } from '@heroicons/react/24/outline'
import { useContext } from "react"
import { SessionContext } from "providers/session"

function Item({ title, link }: { title: string, link: string }) {
    return (
        <div className='p-3 flex items-center justify-center'>
            <A className=' text-neutral-900 text-xxs leading-compact' href={link}>{title}</A>
        </div>
    )
}

export default function Header() {
    const session = useContext(SessionContext)
    const navigate = useNavigate()


    const handleLogout = async () => {
        const auth = getAuth()
        await signOut(auth)
        navigate('/login')
    }

    return (
        <header className='w-full bg-neutral-0 shadow relative z-10'>
            <div className='container mx-auto flex flex-row items-stretch py-2'>
                <a href="/" className="flex items-center justify-center">
                    <img className="h-5" src='\logo-preto.png' alt="Logo da Healthmoney" />
                </a>
                <div className="flex-1"></div>
                <Item title='Gestão' link="/" />
                {session!.isAdmin && <Item title='Admin' link="/admin" />}
                <div className='p-3 flex items-center justify-center'>
                    <A className="mr-3" href="/profile">
                        <UserCircleIcon className="w-4 h-4 stroke-neutral-800" />
                    </A>
                    <A className="mr-3" href="/help">
                        <QuestionMarkCircleIcon className="w-4 h-4 stroke-neutral-800" />
                    </A>
                    <button onClick={handleLogout}>
                        <ArrowRightOnRectangleIcon className="w-4 h-4 stroke-neutral-800" />
                    </button>
                </div>
            </div>
        </header>
    )
}