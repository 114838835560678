import { InvestorProfile } from "@healthmoney/domain";
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { Button, SelectFieldOptions } from "components";
import { Form, Formik } from "formik";
import { useState } from "react";
import { useAllocationProfiles } from "repositories/allocations-repository";

type SuitabilityBannerProps = {
    profile: InvestorProfile,
    children?: React.ReactElement,
    editable?: boolean,
    onProfileChanged?: (profile: string) => Promise<void> | void
}

export default function SuitabilityBanner({
    profile,
    children,
    editable,
    onProfileChanged
}: SuitabilityBannerProps) {

    const [isEditing, setIsEditing] = useState(false)
    const { data: profiles } = useAllocationProfiles()
    const [prevProfile] = useState(profiles.find(p => p.investorProfile === profile))

    const selectedProfile = profiles.find(p => p.investorProfile === profile)

    return (
        <>
            <div className="flex flex-row">
                <div className="pr-5">
                    <img
                        className="w-[200px]"
                        src={`/assets/images/profile-${ profile }.svg`}
                        alt={`Perfil ${ profile } de investidor`}
                    />
                </div>
                <div className="flex-1 flex flex-col justify-center">
                    <h3 className="font-bold text-md leading-compact">Perfil investidor {selectedProfile?.name}</h3>
                    <p className="font-bold text-sm leading-compact mt-5">Meta</p>
                    <p className="leading-compact">
                        {fixGoalTitle(selectedProfile?.anualIncomeRate)}
                    </p>
                    {!editable && children}
                    {editable && !isEditing && (
                        <Button compact secondary className="mt-6 w-11" onClick={() => setIsEditing(true)}>Editar perfil</Button>
                    )}
                    {editable && isEditing && (
                        <Formik
                            initialValues={{ newProfile: profile }}
                            onSubmit={async (values) => {
                                onProfileChanged && await onProfileChanged!(values.newProfile)
                                setIsEditing(false)
                            }}>
                            {() => (
                                <Form>
                                    <div className="pt-4 w-[350px] flex flex-row">
                                        <SelectFieldOptions
                                            name="newProfile"
                                            options={profiles.map(p => ({
                                                text: p.name,
                                                value: p.investorProfile
                                            }))}
                                        />
                                        <div className="flex flex-row">
                                            <Button compact secondary className="mr-2" type="submit">
                                                <CheckIcon className="w-3 h-3" />
                                            </Button>
                                            <Button compact secondary onClick={() => setIsEditing(false)}>
                                                <XMarkIcon className="w-3 h-3" />
                                            </Button>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    )}

                    {prevProfile?.investorProfile !== profile ? (
                        <div className="mt-4">
                            <p className="text-danger-300 font-bold">Perfil de investidor do cliente está sendo alterado!</p>
                            <p>Anterior: {prevProfile?.name}</p>
                            <p>Atual: {selectedProfile?.name}</p>
                        </div>
                    ) : null
                    }
                </div>
            </div>
        </>
    )
}

const fixGoalTitle = (anualIncomeRate?: number | null) =>
    `Atingir uma rentabilidade de ${ String(anualIncomeRate ?? 0).replace('.', ',') }% (juro real) acima da inflação.`