import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Root from "./root";
import PrivateRoutes from "./private-routes";
import { accountInternalRoutes, accountRoutes } from "../features/account";
import { dashboardRoutes } from "../features/dashboard";
import { schedulesRoutes } from "../features/schedules";
import { customersRoutes } from "../features/customers";
import adminRouter from "features/admin";
import { helpRoutes } from "features/help";
import CustomerRegistration from "features/customers/features/register-boarding";

function AppRouter() {

    const router = createBrowserRouter([
        {
            path: "/",
            Component: Root,
            children: [
                ...accountRoutes,
                {
                    path: '/',
                    Component: PrivateRoutes,
                    children: [
                        ...accountInternalRoutes,
                        ...dashboardRoutes,
                        ...schedulesRoutes,
                        ...customersRoutes,
                        ...helpRoutes,
                        CustomerRegistration,
                        adminRouter
                    ]
                },
            ],
        },
    ]);

    return (
        <RouterProvider router={router} />
    );
}

export default AppRouter;
