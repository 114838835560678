type Props = {
    children: React.ReactNode,
}

export default function FieldBlock({ children }: Props) {
    return (
        <div className='flex flex-col p-2 w-full'>
            {children}
        </div>
    )
}