import { Field, ErrorMessage } from 'formik';
import FieldGroup from './field_block'

type Props = {
    name: string,
    label?: string,
    values: string[],
    multiple?: boolean
}

export default function TextField({
    name,
    label,
    values,
    multiple = false
}: Props) {
    return (
        <FieldGroup>
            <div className='flex flex-col flex-1'>
                <label
                    className='text-xxs'
                    htmlFor={name}>{label}</label>
                <Field
                    id={name}
                    name={name}
                    component="select"
                    multiple={multiple}
                    className='appearance-none border-b w-full py-1 text-neutral-800 focus:outline-none focus:shadow-outline text-xs'>
                    {values.map(v => <option key={v} value={v}>{v}</option>)}
                </Field>
                <ErrorMessage name={name} component="span" className='text-danger-500 font-bold py-1' />
            </div>
        </FieldGroup>
    )
}