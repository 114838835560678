import { AllocationProfile, AllocationCategory, CustomerInvestmentSummary } from '@healthmoney/domain'
import { A } from '../../elements'
import { investorProfileToText } from 'helpers/constants'
import WalletChartComponent from './wallet-chart-component'
import WalletSummary from './wallet-summary'
import useWalletSummaryChart from './use-wallet-summary-chart'

interface WalletSummaryChartProps {
    profile: AllocationProfile,
    categories: AllocationCategory[],
    summary: CustomerInvestmentSummary,
    showAllCategories?: boolean
    titleLink?: string
}

export default function WalletSummaryChart({
    profile,
    categories = [],
    summary,
    titleLink
}: WalletSummaryChartProps) {

    const {
        preservation,
        accumulation,
        growth,
        notAssigned,
    } = useWalletSummaryChart(categories, summary)

    if (!summary || !categories || !profile || categories.length === 0)
        return null

    const renderItems = (item: any) => {
        return (
            <p className='text-xxxs' key={item.name}>
                <span>{item.value.toFixed(2)}</span>
                <span>% - </span>
                <span>{item.name}</span>
            </p>
        )
    }

    const values = []

    if (preservation.total > 0)
        values.push({ name: 'Preservação', value: preservation.total })

    if (accumulation.total > 0)
        values.push({ name: 'Acumulação', value: accumulation.total })

    if (growth.total > 0)
        values.push({ name: 'Crescimento', value: growth.total })

    if (notAssigned && notAssigned > 0.5) {
        values.push({ name: 'Não classificado', value: notAssigned })
    }

    return (
        <div className='bg-neutral-0 shadow border border-neutral-200 p-6'>
            <div className='pb-3'>
                {titleLink
                    ? (
                        <A href={titleLink}>
                            <p className='text-sm font-bold'>{profile.name}</p>
                        </A>
                    )
                    : (
                        <p className='text-sm font-bold'>{profile.name}</p>
                    )}
                <p className='text-xxxs text-neutral-400'>{investorProfileToText[profile.investorProfile]}</p>
            </div>
            <div className='flex flex-row'>
                <div className='h-[300px] flex-1 mr-3'>
                    <WalletChartComponent values={values} />
                </div>
                <div className='flex-1 flex flex-col'>
                    {preservation.total > 0 && (
                        <WalletSummary
                            title='Preservação'
                            total={preservation.total}
                            accentColor='bg-report-100'
                            items={preservation.items.map(renderItems)}
                        />
                    )}
                    {accumulation.total > 0 && (
                        <WalletSummary
                            title='Acumulação'
                            total={accumulation.total}
                            accentColor='bg-report-500'
                            items={accumulation.items.map(renderItems)}
                        />
                    )}
                    {growth.total > 0 && (
                        <WalletSummary
                            title='Crescimento'
                            total={growth.total}
                            accentColor='bg-report-700'
                            items={growth.items.map(renderItems)}
                        />
                    )}
                    {notAssigned && notAssigned > 0.5 ? (
                        <span className='text-danger-500 font-bold'>
                            {notAssigned.toFixed(2)}% não classificado
                        </span>
                    ) : null}
                </div>
            </div>
        </div>
    )
}