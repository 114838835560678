export function CalendarHeaderItem({ dayOfTheWeek }: { dayOfTheWeek: number }) {
    let display = ''

    switch (dayOfTheWeek) {
        case 0:
            display = 'Domingo';
            break;
        case 1:
            display = 'Segunda';
            break;
        case 2:
            display = 'Terça';
            break;
        case 3:
            display = 'Quarta';
            break;
        case 4:
            display = 'Quinta';
            break;
        case 5:
            display = 'Sexta';
            break;
        case 6:
            display = 'Sábado';
            break;
    }

    return (
        <div className='flex-1 bg-neutral-200 p-2'>
            {display}
        </div>
    )
}