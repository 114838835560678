import { UserCircleIcon } from '@heroicons/react/24/outline'
import { useCustomerAccount, useCustomerDetails, useCustomerIncome, useInvestmentsSummary } from "../../../../repositories/customers-repository";
import { A } from "../../../../components";
import { formatCurrency } from "helpers";
import { investorProfileToText } from "helpers/constants";
import { useMemo } from 'react';
import parsePhoneNumber from 'libphonenumber-js';
import { moment } from 'helpers'

const getProductDisplay = (product: string | null) => {
    switch (product) {
        case 'money-private':
            return 'Money Private'
        case 'money-select':
            return 'Money Select'
        case 'money-start':
            return 'Money Start';
        case 'money-family-office':
            return 'Money Family Office';
        case null:
            return '---'
        default:
            return product
    }
}

export default function CustomerBoard({ uid }: { uid: string }) {

    const details = useCustomerDetails(uid)
    const income = useCustomerIncome(uid)
    const account = useCustomerAccount(uid)
    const summary = useInvestmentsSummary(uid)

    const phone = useMemo(() => {
        if (details.data?.phone != null) {
            const parsed = parsePhoneNumber(details.data.phone, 'BR')
            return parsed!.number.replace('+', '')
        } else {
            return null;
        }
    }, [details, details.data?.phone])

    if (details.status !== 'success') return <span>Carregando</span>

    const displayProfile = income?.data?.investorProfile
        ? investorProfileToText[income?.data?.investorProfile]
        : '---'

    return (
        <div className="flex flex-row mt-5 p-6 border border-neutral-400 shadow-lg rounded-md bg-neutral-0">
            <div className="flex-1 flex flex-col">
                <div className="flex flex-row pr-4">
                    <div>
                        <img src="/avatar.png" alt='Avatar placeholder' />
                    </div>
                    <div className="flex flex-1 flex-col pl-6">
                        <h2 className="text-lg font-bold">{details.data.displayName || details.data.fullName}</h2>
                        <div className="flex flex-1 flex-row pt-3">
                            <div className="flex flex-col flex-1 max-w-xs">
                                <div>Perfil</div>
                                <div className="font-bold mb-3">{displayProfile}</div>
                                <div>Aporte mensal</div>
                                <div className="font-bold">{formatCurrency(income.data?.montlySaving || 0)}</div>
                            </div>
                            <div className="flex flex-col flex-1 max-w-xs">
                                <div>Produto contratado</div>
                                <div className="font-bold mb-3">
                                    {getProductDisplay(account.data?.product || null)}
                                </div>
                                <div>Data do aporte mensal</div>
                                <div className="font-bold">{income.data?.montlySavingDay || '--'}</div>
                            </div>
                            <div className="flex flex-col flex-1 max-w-xs">
                                <div>Última posição consolidada</div>
                                <div className="font-bold mb-3">{summary.data?.updatedAt
                                    ? moment(summary.data?.updatedAt).format('DD/MM/YYYY')
                                    : '---'
                                }</div>
                                <div>Aporte do mês vigente</div>
                                <div className="font-bold">--</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-row pt-5">
                    <A href={`/customers/${uid}/details`} button buttonSecondary buttonCompact className="mr-3">Informações básicas</A>
                    <A href={`/customers/${uid}/income`} button buttonSecondary buttonCompact className="mr-3">Informações financeiras</A>
                    {/* <Button secondary compact className="mr-3" disabled>Patrimônio</Button> */}
                    <A href={`/customers/${uid}/investments`} button buttonSecondary buttonCompact className="mr-3">Investimentos</A>
                    {/* <Button secondary disabled compact className="mr-3">Previdência</Button> */}
                    <A href={`/customers/${uid}/account`} button buttonSecondary buttonCompact>
                        <UserCircleIcon className='w-4 h-4' />
                    </A>
                </div>
            </div>
            <div className="max-w-xs flex flex-col">
                {/* <Button secondary className="mb-3" disabled>Próxima Reunião</Button> */}
                {phone && (<A href={`https://wa.me/${phone}`} target='_blank' button className="mb-3">Whatsapp</A>)}
                <A
                    className="mb-3"
                    button
                    buttonSecondary
                    href={`/customers/${uid}/allocations/create`}>
                    Recomendar Alocação
                </A>
                <A
                    button
                    href={`/customers/${uid}/allocations`}
                >
                    Todas as recomendações
                </A>
            </div>
        </div>
    )
}
