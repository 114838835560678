import { RegistrationRequest } from "@healthmoney/domain";
import useRegistrationBoarding from "../useRegistrationBoarding";

import { useEffect, useState } from "react"
import { useWatchRegisterRequest } from "repositories/customers-repository"

export default function RegisterBoardingComplete() {

    const [request, setRequest] = useState<RegistrationRequest | null>(null)
    const { user } = useRegistrationBoarding()
    const watchRegisterRequest = useWatchRegisterRequest()

    useEffect(() => {
        const unsub = watchRegisterRequest(user!.details!.email!, (data) => {
            setRequest(data)
        });

        return () => unsub()
    }, [])

    return (
        <div className="container mx-auto max-w-screen-lg pt-8 flex flex-col flex-wrap">
            {request && request.checkoutLink && (
                <div className="p-4 bg-sand-100 mb-3">
                    <p>{request.checkoutLink}</p>
                </div>
            )}
            {(!request || !request.checkoutLink) && (
                <span>
                    Carregando link...
                </span>
            )}
        </div>
    )
}