import {
	collection,
	doc,
	deleteDoc,
	addDoc,
	updateDoc,
	collectionGroup,
	setDoc,
	getDoc,
	getFirestore,
	query,
	where,
	getDocs,
	writeBatch,
	onSnapshot,
	Timestamp,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import {
	useFirestore,
	useFirestoreCollectionData,
	useFirestoreDocData,
	useFirestoreDocDataOnce,
	useFunctions,
} from "reactfire";
import {
	AllocationCategory,
	CoreConstantsCurrencies,
	CustomerAccount,
	CustomerDetails,
	CustomerIncome,
	CustomerInvestment,
	CustomerProject,
	CustomerRetirementPlan,
	CustomerSuitability,
	CustomerTask,
	RegistrationRequest,
	RegistrationRegister,
} from "@healthmoney/domain";
import React from "react";

export type TUserRegister = {
	details?: CustomerDetails,
	income?: CustomerIncome,
	suitability?: CustomerSuitability & { allocationProfileId: string },
	projects?: CustomerProject[],
	retirementPlan?: CustomerRetirementPlan,
	maxStep?: number,
	processing?: boolean,
	product?: string,
	locked?: boolean,
	createdAt?: Timestamp
}

export function useRegister() {
	const functions = useFunctions();

	const register = httpsCallable<CustomerDetails, CustomerDetails>(functions, "customerRegister");

	return (details: CustomerDetails) => {
		return register(details);
	};
}

export function usePreRegistration(prid: string) {
	const db = useFirestore();

	const reference = doc(db, "registration", "default", "registration_registers", prid);

	const observableStatus = useFirestoreDocDataOnce(reference);

	return {
		...observableStatus,
		data: observableStatus.data as RegistrationRegister | null,
	};
}

export function getPreRegistration(prid: string) {
	const db = getFirestore();

	const reference = doc(db, "registration", "default", "registration_registers", prid);

	return getDoc(reference);
}

export function useCustomersDetails() {
	const db = useFirestore();

	const reference = collectionGroup(db, "core_customers_details");

	const result = useFirestoreCollectionData(reference, { idField: "id" });

	return {
		...result,
		data: (result.data || []) as CustomerDetails[],
	};
}

export function useCustomerDetails(uid: string) {
	const db = useFirestore();

	const customerReference = doc(db, "core_customers", uid, "core_customers_details", "default");

	const observableStatus = useFirestoreDocData(customerReference);

	return {
		...observableStatus,
		data: observableStatus.data as CustomerDetails,
	};
}

export async function getCustomerDetails(uid: string): Promise<CustomerDetails> {
	const db = getFirestore();

	const reference = doc(db, "core_customers", uid, "core_customers_details", "default");

	const snapshot = await getDoc(reference);

	return { ...snapshot.data(), id: snapshot.id } as CustomerDetails;
}

export function useCustomerRetirementPlan(uid: string) {
	const db = useFirestore();

	const customerReference = doc(db, "core_customers", uid, "core_customers_retirement_plan", "default");

	const observableStatus = useFirestoreDocData(customerReference);

	return {
		...observableStatus,
		data: observableStatus.data as CustomerRetirementPlan,
	};
}

export function useCustomerAccount(uid: string) {
	const db = useFirestore();

	const accountReference = doc(db, "core_customers", uid, "core_customers_account", "default");

	const observableStatus = useFirestoreDocDataOnce(accountReference);

	return {
		...observableStatus,
		data: observableStatus.data as CustomerAccount | null,
	};
}

export function useCustomerIncome(uid: string) {
	const db = useFirestore();

	const accountReference = doc(db, "core_customers", uid, "core_customers_income", "default");

	const observableStatus = useFirestoreDocDataOnce(accountReference);

	return {
		...observableStatus,
		data: observableStatus.data as CustomerIncome | null,
	};
}

export async function getCustomerIncome(uid: string): Promise<CustomerIncome> {
	const db = getFirestore();

	const reference = doc(db, "core_customers", uid, "core_customers_income", "default");

	const snapshot = await getDoc(reference);

	return { ...snapshot.data(), id: snapshot.id } as CustomerIncome;
}

export async function getCustomerAccount(uid: string): Promise<CustomerAccount> {
	const db = getFirestore();

	const reference = doc(db, "core_customers", uid, "core_customers_account", "default");

	const snapshot = await getDoc(reference);

	return { ...snapshot.data(), id: snapshot.id } as CustomerAccount;
}

export function useSetCustomerIncome() {
	const db = useFirestore();

	return async (income: CustomerIncome) => {
		await setDoc(doc(db, "core_customers", income.uid!, "core_customers_income", "default"), income, {
			merge: true,
		});
	};
}

export function useSetCustomerAccount() {
	const db = useFirestore();

	return async (account: CustomerAccount) => {
		await setDoc(doc(db, "core_customers", account.uid!, "core_customers_account", "default"), account, {
			merge: true,
		});
	};
}

export function useBanks(uid: string) {
	const db = useFirestore();

	const observableStatus = useFirestoreCollectionData(collection(db, "core_customers", uid, "core_customers_banks"), {
		idField: "id",
	});

	return {
		...observableStatus,
		data: observableStatus.data,
	};
}

export function useInvestments(uid: string) {
	const db = useFirestore();

	const investmentsReference = query(
		collection(db, "core_customers", uid, "core_customers_investments"),
		where("archived", "==", false),
	);

	const observableStatus = useFirestoreCollectionData(investmentsReference, {
		idField: "id",
	});

	return {
		...observableStatus,
		data: observableStatus.data as CustomerInvestment[] | null,
	};
}

export function useInvestment(uid: string, iid: string) {
	const db = useFirestore();

	const observableStatus = useFirestoreDocData(
		doc(db, "core_customers", uid, "core_customers_investments", iid),
		{
			idField: "id",
		},
	);

	return {
		...observableStatus,
		data: observableStatus.data as CustomerInvestment,
	};

}
export function useInvestmentsNotClassified(cid: string) {
	const db = useFirestore();

	const qInvestmentsNotClassified = query(
		collectionGroup(db, "core_customers_investments"),
		where("cid", "==", cid),
		where("archived", "==", false),
		where("classification", '==', null),
	);

	const observableStatus = useFirestoreCollectionData(qInvestmentsNotClassified, {
		idField: "id",
	});

	const uniqueIdentifierArr: string[] = []
	const uniqueInvestiment = observableStatus.data?.filter(obj => {
		if (!uniqueIdentifierArr.includes(`${obj.code}${obj.name}${obj.issuer}`)) {
			uniqueIdentifierArr.push(`${obj.code}${obj.name}${obj.issuer}`)
			return true
		}
		return false
	})

	return {
		...observableStatus,
		data: uniqueInvestiment as CustomerInvestment[] | [],
	};
}

export function useInvestmentsSummary(uid: string) {
	const db = useFirestore();

	const observableStatus = useFirestoreDocData(
		doc(db, "core_customers", uid, "core_customers_investments_summary", "default"),
		{
			idField: "id",
		},
	);

	return {
		...observableStatus,
		data: observableStatus.data,
	};
}

export function useCoreConstantsCurrencies() {
	const db = useFirestore();

	const observableStatus = useFirestoreDocData(doc(db, "core_constants/currencies"));

	return {
		...observableStatus,
		data: observableStatus.data as CoreConstantsCurrencies,
	};
}

export function useSetInvestmentClassification(uid: string) {
	const db = useFirestore();

	return async (investmentId: string, classification: string) => {
		const reference = doc(db, "core_customers", uid, "core_customers_investments", investmentId);

		await updateDoc(reference, { classification });
	};
}

export function useDeleteInvestment(uid: string, iid: string) {
	const db = useFirestore();

	return async () => {
		const docReference = doc(db, "core_customers", uid, "core_customers_investments", iid)
		await deleteDoc(docReference,);
	};
}

export function useSetInvestment(uid: string) {
	const db = useFirestore();

	return async (investment: CustomerInvestment) => {
		const collectionReference = collection(db, "core_customers", uid, "core_customers_investments")
		await addDoc(collectionReference, { ...investment, createdAt: Timestamp.fromDate(new Date()) });
	};
}
export function useUpdateInvestment(uid: string, iid: string) {
	const db = useFirestore();

	return async (newInvestment: CustomerInvestment) => {
		const updateInvestmentReference = doc(db, "core_customers", uid, "core_customers_investments", iid);
		await updateDoc(updateInvestmentReference, newInvestment);
	};
}

/**
 * Only admin can run this function
 * @returns
 */
export function useSetMultiplesInvestmentClassification() {
	const db = useFirestore();

	return async (category: AllocationCategory, investment: CustomerInvestment) => {
		const reference = query(
			collectionGroup(db, "core_customers_investments"),
			where("classification", "==", null),
			where("type", "==", investment.type),
			where("name", "==", investment.name),
			where("code", "==", investment.code),
		);

		const querySnapshot = await getDocs(reference);

		const batch = writeBatch(db)

		querySnapshot.forEach((doc) => {
			const updatedClassification = category.id;
			batch.update(doc.ref, { classification: updatedClassification });
		});

		await batch.commit();
	};
}

export function useTasks(uid: string) {
	const db = useFirestore();

	const observableStatus = useFirestoreCollectionData(collection(db, "core_customers", uid, "core_customers_tasks"), {
		idField: "id",
	});

	return {
		...observableStatus,
		data: observableStatus.data as CustomerTask[] | null,
	};
}

export function useSetRetirementPlan() {
	const db = useFirestore();

	return async (plan: CustomerRetirementPlan) => {

		const document = doc(
			db,
			"core_customers",
			plan.uid,
			"core_customers_retirement_plan",
			'default'
		)

		await setDoc(document, plan)
	}
}

export async function getRetirementPlan(uid: string): Promise<CustomerRetirementPlan | null> {
	const db = getFirestore();

	const document = doc(
		db,
		"core_customers",
		uid,
		"core_customers_retirement_plan",
		'default'
	)

	const plan = await getDoc(document);

	if (!plan.exists()) {
		return null
	}

	return plan.data() as CustomerRetirementPlan
}

export function useCreateTask() {
	const db = useFirestore();

	return async (task: CustomerTask) => {

		const dateForCompletion = Timestamp.fromDate(task.dateForCompletion)

		await addDoc(collection(db, "core_customers", task.uid!, "core_customers_tasks"), { ...task, dateForCompletion });
	};
}

export function useDeleteTask() {
	const db = useFirestore();

	return async (uid: string, taskId: string) => {
		await deleteDoc(doc(db, "core_customers", uid, "core_customers_tasks", taskId));
	};
}

export function useCreateProject() {
	const db = useFirestore();

	return async (project: CustomerProject) => {
		await addDoc(collection(db, "core_customers", project.uid!, "core_customers_projects"), project);
	};
}

export function useProjects(uid: string) {
	const db = useFirestore();

	const observableStatus = useFirestoreCollectionData(
		collection(db, "core_customers", uid, "core_customers_projects"),
		{
			idField: "id",
		},
	);

	return {
		...observableStatus,
		data: observableStatus.data as CustomerProject[] | null,
	};
}

export function useDeleteProject() {
	const db = useFirestore();

	return async (uid: string, projectId: string) => {
		await deleteDoc(doc(db, "core_customers", uid, "core_customers_projects", projectId));
	};
}

export function setRegisterDetails() {
	const db = getFirestore();

	return async (details: CustomerDetails) => {
		const reference = doc(db, "registration", "default", "registration_state", details.email!)

		const birthdate = Timestamp.fromDate(details.birthdate)

		await setDoc(reference, { details: { ...details, birthdate }, locked: false }, { merge: true })
	}
}
export function setRegisterCreatedAt() {
	const db = getFirestore();

	return async (email: string) => {
		const reference = doc(db, "registration", "default", "registration_state", email)


		await setDoc(reference, { createdAt: Timestamp.fromDate(new Date()) }, { merge: true })
	}
}

export function setRegisterIncome(email: string) {
	const db = getFirestore();

	return async (income: CustomerIncome) => {


		const reference = doc(db, "registration", "default", "registration_state", email)

		await setDoc(reference, { income }, { merge: true })
	}
}

export function setRegisterSuitability(email: string) {
	const db = getFirestore();

	return async (suitability: CustomerSuitability & { allocationProfileId: string }) => {

		const reference = doc(db, "registration", "default", "registration_state", email)

		await setDoc(reference, { suitability }, { merge: true })
	}
}

export function setRegisterAddProject(email: string) {
	const db = getFirestore();

	return async (projects: CustomerProject[]) => {
		const reference = doc(db, "registration", "default", "registration_state", email)

		await setDoc(reference, { projects }, { merge: true });
	};
}

export function setRegisterDeleteProject(email: string) {
	const db = getFirestore();

	return async (newProjects: CustomerProject[]) => {
		const reference = doc(db, "registration", "default", "registration_state", email)

		await setDoc(reference, { projects: [...newProjects] }, { merge: true });

	};
}

export function setRegisterRetirementPlan(email: string) {
	const db = getFirestore();

	return async (plan: CustomerRetirementPlan) => {
		const reference = doc(db, "registration", "default", "registration_state", email)

		await setDoc(reference, { retirementPlan: plan }, { merge: true });
	}
}
export function setRegisterMaxStep() {
	const db = getFirestore();

	return async (email: string, maxStep: number) => {
		const reference = doc(db, "registration", "default", "registration_state", email)

		await setDoc(reference, { maxStep }, { merge: true });
	}
}

export function useSetRegisterState() {
	const db = getFirestore();

	return async (email: string, stateToBeMerged: TUserRegister) => {
		const reference = doc(db, "registration", "default", "registration_state", email)
		await setDoc(reference, stateToBeMerged, { merge: true });
	}
}

export function useSetRegisterProcessing() {
	const db = getFirestore();

	return async (email: string, processing: boolean) => {
		const reference = doc(db, "registration", "default", "registration_state", email)

		await setDoc(reference, {
			processing
		}, { merge: true });
	}
}

export function useSetProcessingUserPayment() {
	const db = getFirestore();

	return async (cid: string, email: string) => {
		const reference = doc(db, "registration", "default", "registration_request", email!)

		const data: RegistrationRequest = {
			cid,
			email,
			createdAt: Timestamp.fromDate(new Date()),
			error: null,
			done: false,
		}

		await setDoc(reference, { ...data }, { merge: true })
	}
}

type UnsubCallback = () => void;

export function useWatchRegisterRequest() {
	const db = getFirestore();

	return (
		email: string,
		onData: (request: RegistrationRequest) => void
	): UnsubCallback => {
		const reference = doc(db, "registration", "default", "registration_request", email!)

		return onSnapshot(reference, {
			next: (doc) => {
				const data = doc.data() as RegistrationRequest
				onData(data)
			},
			error: (error) => {
				throw error
			}
		})
	}
}

export type TUserProcessing = {
	done: boolean,
	createdAt: Date,
	erro: string | null,
	product: string
}
export function useGetProcessingUserPayment(email: string) {
	const db = getFirestore();

	const reference = doc(db, "registration", "default", "registration_request", email!)

	const observableStatus = useFirestoreDocData(
		reference,
		{
			idField: "id",
		},
	);


	return {
		...observableStatus,
		data: {
			...observableStatus.data,
		} as TUserProcessing
	};
}

export function useGetUserRegister(uid?: string) {
	const db = useFirestore();

	const observableStatus = useFirestoreDocData(
		doc(db, "registration", "default", "registration_state", uid ?? "no-email-provided"),
		{
			idField: "id",
		},
	);

	return {
		...observableStatus,
		data: {
			...observableStatus.data,
		} as TUserRegister | null
	};
}

export function useGetUsersTasks({ daysForCompletion }: { daysForCompletion: number }) {
	const db = useFirestore();

	const fromDaysBefore = new Date();
	fromDaysBefore.setDate(fromDaysBefore.getDate() - daysForCompletion);

	const fromDaysAfter = new Date();
	fromDaysAfter.setDate(fromDaysAfter.getDate() + daysForCompletion);

	const [date] = React.useState({ before: fromDaysBefore, after: fromDaysAfter })

	const usersTasks = useFirestoreCollectionData(
		query(
			collectionGroup(db, 'core_customers_tasks'),
			where('dateForCompletion', '>', date.before),
			where('dateForCompletion', '<', date.after),
		),
		{
			idField: "id",
		}
	)

	const usersDetails = useFirestoreCollectionData(
		query(
			collectionGroup(db, 'core_customers_details'),
		),
		{
			idField: "id",
		}
	)

	const mappedTasks = (usersTasks.data as CustomerTask[])?.reduce((acc, task) => {
		if (task.status === 'done') return acc
		const cid = (usersDetails.data as CustomerDetails[])?.find((user) => user.uid === task.uid)?.cid

		const taskWithCid = { ...task, cid }
		acc[task["id"]!] = taskWithCid

		return acc
	}, {} as Record<string, CustomerTask & { cid?: string | null }>)

	return Object?.values(mappedTasks ?? {})
}