import { ChevronRightIcon } from '@heroicons/react/24/solid'
import { useMemo } from "react";
import { TrashIcon } from "@heroicons/react/24/outline";
import { useDeleteTask, useTasks } from '../../../../repositories/customers-repository';
import { A, Button } from '../../../../components';
import { MDReadOnlyField } from 'components/inputs/markdow_field';
import { moment } from 'helpers'

export default function Tasks({ uid }: { uid: string }) {
    const deleteTask = useDeleteTask()

    const createRoute = useMemo(() => `/customers/${uid}/tasks/create`, [uid])

    const {
        data
    } = useTasks(uid)

    if (!data) return <span>Carregando</span>

    return (
        <>
            <h3 className="text-lg font-bold mb-3">Lista de tarefas</h3>
            {data.length > 0
                ? (
                    <>
                        <div className="pb-3 flex flex-wrap">

                            {data.map((task, index) => (
                                <div key={`${task.title}-${index}`} className="shadow p-3 border border-neutral-200 w-5/12 mr-4 mb-3 grid grid-rows-[auto_1fr_auto] gap-2">
                                    <div className='flex justify-between items-center'>
                                        <p className="text-sm font-bold">{task.title}</p>
                                        <Button compact secondary onClick={(e) => {
                                            e.preventDefault()
                                            deleteTask(uid, task.id!)
                                        }}>
                                            <TrashIcon className="w-3 h-3" />
                                        </Button>
                                    </div>
                                    <MDReadOnlyField
                                        value={task.details}
                                        className='text-xxs max-h-10 overflow-y-auto'
                                        contentEditableClassName='!p-0'
                                        key={task.id}
                                    />
                                    
                                    <p className="text-xxs">
                                        <span className="font-bold">Data prevista: </span>
                                        <span>
                                            {typeof task.dateForCompletion !== 'string'?(
                                                moment(task.dateForCompletion?.toDate?.()).format("DD/MM/YYYY")
                                            ):(moment(task.dateForCompletion).format("DD/MM/YYYY"))}
                                        </span>
                                    </p>
                                    <p className="text-xxs">
                                        <span className="font-bold">Status: </span>
                                        <span>{task.status}</span>
                                    </p>
                                </div>
                            ))}

                        </div>
                        <div className="flex flex-row">
                            <A
                                button
                                buttonSecondary
                                buttonCompact
                                className="mr-3"
                                href={createRoute}>
                                Adicionar nova tarefa
                            </A>
                        </div>
                    </>
                )
                : (
                    <div className="pb-1">
                        <span>Nenhuma tarefa cadastrada, </span>
                        <A highlighted href={createRoute}>
                            cadastrar
                            <ChevronRightIcon className="h-3 w-3 inline" />
                        </A>
                    </div>
                )}

        </>
    )
}