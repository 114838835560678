import useSchedulesView from "./use-schedules-view"
import { moment } from 'helpers'
import { useState } from "react";
import { ScheduleWithExtensions } from "./use-stage";
import SchedulesCalendar from "./schedules-calendar";
import InserAssistantForm from "./insert-assistant-form";
import { CalendarNote } from "../../../components/containers/calendar/types";
import { PageHeading } from "../../../components";

export default function SchedulesView() {

    const [adding, setAdding] = useState(false)

    const {
        schedules,
        stageFromParameters,
        removeItemFromStage,
        clearStage,
        deleteSchedule,
        persistStage,
    } = useSchedulesView()

    const items = schedules as ScheduleWithExtensions[]

    const notes = items.map(schedule => {
        const date: Date = schedule.startAt

        return {
            title: moment(date).format('HH:mm'),
            description:
                schedule.arranged
                    ? schedule.profile?.email
                    : schedule.stagged
                        ? 'Não salvo'
                        : 'Livre',
            highlighted: schedule.stagged,
            date: date,
            data: schedule
        }
    }) as CalendarNote[]

    const onSaveAll = async () => {
        try {
            setAdding(true)
            clearStage()
            await persistStage()
            setAdding(false)
        } catch (error) {
            setAdding(false)
            console.error(error)
        }
    }

    return (
        <>
            <PageHeading
                title="Agendamentos"
                description="Agendas de atendimento e compromissos"
            />
            <div className="container mx-auto py-6 flex flex-row">
                <SchedulesCalendar
                    notes={notes}
                    onNoteClick={(note) => {
                        const schedule = note.data as ScheduleWithExtensions
                        if (schedule.stagged) {
                            removeItemFromStage(note.date)
                        } else if (schedule.public && !schedule.profile && schedule.id) {
                            deleteSchedule(schedule)
                        }
                    }}
                />
                <InserAssistantForm
                    adding={adding}
                    stageFromParameters={stageFromParameters}
                    onSaveAll={onSaveAll}
                />
            </div>
        </>
    )
}

