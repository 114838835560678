import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Loading from "components/containers/loading"
import { useCallback, useEffect, useState } from "react"
import { forceStateGeneration, getCustomersState } from "repositories/admin-repository"
import { A, Button, PageHeading } from "components";

export default function CustomersStateView() {
    const [initializing, setInitializing] = useState<boolean>(true)
    const [customersState, setCustomersState] = useState<any[]>([])

    const load = useCallback(() => {
        getCustomersState().then(data => {
            setInitializing(true)
            setCustomersState(data)
            setInitializing(false)
        }).catch(error => {
            console.error(error)
        })
    }, [])

    const forceUpdate = useCallback((uid: string) => {
        return forceStateGeneration(uid)
    }, [])

    useEffect(() => {
        load()
    }, [])

    return (
        <Loading isLoading={initializing}>
            <PageHeading
                title='Sincronizações'
                description='Sincronizações de dados com os aplicativos dos clientes' />
            <section className="w-full bg-neutral-0 pb-4">
                <div className='container mx-auto flex flex-col pt-6'>
                    <div className='pb-4 flex flex-row'>
                        <Button compact secondary onClick={() => {
                            Promise.all(customersState.map((customer) => forceUpdate(customer.uid)))
                                .then(() => {
                                    alert('Estados serão atualizados em alguns instantes')
                                }).catch(error => {
                                    alert(error)
                                })
                        }}>
                            Gerar de todos os usuários
                        </Button>
                        <Button compact secondary onClick={load} className='ml-2'>
                            Atualizar
                        </Button>
                    </div>
                    <DataTable value={customersState} tableStyle={{ minWidth: '50rem' }}>
                        <Column field='user.displayName' header="Nome de exibição" sortable filter />
                        <Column field='generatedAt' header="Gerado em" />
                        <Column
                            header="Ações"
                            body={(customer) => (
                                <div className="flex flex-row">
                                    <Button compact onClick={() => {
                                        forceUpdate(customer.uid).then(() => {
                                            alert('Estado será atualizado em um instante')
                                        }).catch(error => {
                                            alert(error)
                                        })
                                    }}>
                                        Gerar
                                    </Button>
                                    <A
                                        button
                                        buttonCompact
                                        buttonSecondary
                                        className='max-w-[180px] ml-2'
                                        href={`/customers/${customer.uid}`}>
                                        Perfil
                                    </A>
                                </div>
                            )}
                        />
                    </DataTable>
                </div>
            </section>
        </Loading>
    )
}
