import { DataTable } from "primereact/datatable"
import calculateCCV, { CalculateCCVParams } from "./calculate-ccv"
import { Column } from "primereact/column"
import { formatMoneyK, formatCurrency } from "helpers"
import { Area, AreaChart, CartesianGrid, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts"
import { useMemo, useState } from "react"
import { Form, FormikProvider, useFormik } from "formik"
import { Button, FieldGroup, MoneyField, NumberField } from "components"
import { useLocation } from "react-router-dom"
import useRegistrationBoarding from "features/customers/features/register-boarding/useRegistrationBoarding"

export type CCVResult = {
    startYear: number;
    endYear: number;
    contribution: number;
    retirementYear: number;
    startEquity: number;
    pontetialPassiveIncome: number;
    maximumAccumulatedWealth: number
}

export type CCVProps = {
    readonly?: boolean,
    onNext: (result: CCVResult) => void
} & CalculateCCVParams

export default function CCV(props: CCVProps) {
    const { pathname } = useLocation()
    const { maxStep } = useRegistrationBoarding()
    const [expanded, setExpanded] = useState(false)

    const readonly = props.readonly || false

    const formik = useFormik({
        initialValues: {
            startYear: props.startYear,
            endYear: props.endYear,
            contribution: props.contribution,
            retirementYear: props.retirementYear,
            startEquity: props.startEquity,
            pontetialPassiveIncome: 0,
        },
        onSubmit: (values) => { }
    })

    const data = useMemo(() => {
        const values = formik.values
        const result = calculateCCV({
            anualIncomeRate: props.anualIncomeRate,
            ...values
        })

        formik.setFieldValue('pontetialPassiveIncome',
            result.pontetialPassiveIncome
        )

        return result
    }, [props, formik.values.retirementYear, formik.values.contribution, formik.values.startEquity])

    const buttonText = !pathname.includes("/registration") ?
        'Salvar' : maxStep > 4
            ? 'Salvar'
            : 'Próximo'

    return (
        <div>
            {!readonly && (
                <>
                    <div className=" max-w-screen-md mx-auto">
                        <FormikProvider value={formik}>
                            <Form>
                                <FieldGroup>
                                    <NumberField
                                        label="Idade atual"
                                        key='startYear'
                                        name='startYear'
                                        type="number"
                                        disabled
                                        readOnly />
                                    <NumberField
                                        label="Idade na aposentadoria"
                                        key='retirementYear'
                                        name='retirementYear'
                                        step='1'
                                        type="number" />
                                    <NumberField
                                        label="Expectativa de vida"
                                        key='endYear'
                                        name='endYear'
                                        type="number"
                                        disabled
                                        readOnly />
                                </FieldGroup>
                                <FieldGroup>
                                    <MoneyField
                                        formik={formik}
                                        name="startEquity"
                                        label="Saldo inicial"
                                        step={500}
                                    />
                                    <MoneyField
                                        formik={formik}
                                        name="contribution"
                                        label="Valor do aporte mensal"
                                        step={500}
                                    />
                                </FieldGroup>
                                <FieldGroup>
                                    <MoneyField
                                        label="Renda passiva calculada"
                                        key='pontetialPassiveIncome'
                                        name='pontetialPassiveIncome'
                                        readOnly
                                        disabled
                                        formik={formik}
                                    />
                                </FieldGroup>
                                <div className="flex flex-row">
                                    <Button
                                        secondary
                                        type='button'
                                        onClick={async () => await props.onNext({
                                            ...formik.values,
                                            maximumAccumulatedWealth: data.maximumAccumulatedWealth
                                        })}
                                        className="mt-3 mb-6">
                                        {buttonText}
                                    </Button>
                                </div>
                            </Form>
                        </FormikProvider>
                    </div>
                    <span className=" font-bold text-md">Resultado</span>
                </>
            )}

            <div className="w-full h-[400px] pt-6">
                <ResponsiveContainer width="100%" height="100%">
                    <AreaChart
                        width={500}
                        height={300}
                        data={data.entries}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis type="number" dataKey="year" domain={['dataMin', 'dataMax']} tickCount={10} includeHidden tickMargin={16} />
                        <YAxis type="number" tickFormatter={(value) => formatMoneyK(value)} tickMargin={16} />
                        <Tooltip formatter={(v) => <span>{formatCurrency(v as number)}</span>} />
                        <Area className="stroke-report-500" type="monotone" dataKey="value" activeDot={{ r: 8 }} />
                        <ReferenceLine
                            x={formik.values.retirementYear}
                            stroke="#1A4480"
                            strokeWidth={2}
                            strokeDasharray={5}
                            label={{
                                value: 'Aposentadoria',
                                position: 'right',
                                offset: 16,
                                className: 'stroke-neutral-100 text-sm fill-neutral-100'
                            }} />
                    </AreaChart>
                </ResponsiveContainer>
            </div>
            {readonly && (
                <div className="pl-8 pt-4 flex flex-row">
                    <span className="text-xs">Renda passiva: <strong>{formatCurrency(data.pontetialPassiveIncome)}</strong></span>
                    <span className="text-xs ml-3">Aporte mensal: <strong>{formatCurrency(props.contribution)}</strong></span>
                    <span className="text-xs ml-3">Taxa considerada: <strong>{props.anualIncomeRate * 100}%</strong> (a.a)</span>
                </div>
            )}
            <div className="p-6">
                <button
                    className="mb-3 underline text-report-700"
                    onClick={() => setExpanded(!expanded)}>
                    {expanded ? 'Esconder detalhes' : 'Mostrar evolução a cada ano'}
                </button>
                {expanded && (
                    <DataTable value={data.entries}>
                        <Column header='Ano' field="year" />
                        <Column header='Patrimônio (valor real)' field="value" body={(entry) => <span>{formatCurrency(entry.value || 0)}</span>} />
                        <Column header='Movimentação e Rendimento' field="movement" body={(entry) => <span>{formatCurrency(entry.movement || 0)}</span>} />
                    </DataTable>
                )}
            </div>
        </div >
    )
}