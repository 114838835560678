import RegisterBoardingCVV from "../steps/register-boarding-cvv"
import Loading from "components/containers/loading"
import useRegistrationBoarding from "../useRegistrationBoarding"

const RegisterBoardingCvvView = () => {

  const { loaded } = useRegistrationBoarding()

  return (
    <>
      <Loading isLoading={!loaded}>
        <div className="container mx-auto pt-8">
          <RegisterBoardingCVV />
        </div>
      </Loading>
    </>
  )
}

export default RegisterBoardingCvvView