export type CalculateCCVParams = {
    startEquity: number
    anualIncomeRate: number
    startYear: number
    endYear: number
    contribution: number
    retirementYear: number
}

type CCVItem = {
    year: number
    value: number
    movement: number
}

/***
 * Calculate CCV
 * @param startEquity Recursos Consolidados Investidos
 * @param anualIncomeRate Taxa de Juro Real Correspondente ao Perfil de Investidor (Conservador, Moderado, etc) (ex: 0.02)
 * @param startYear Idade atual do cliente
 * @param endYear Expectativa de vida
 * @param contribution Valor Meta de poupança mensal
 * @param retirementYear Idade em que pretende se aposentar ou fazer a transição
 */
export default function calculateCCV({
    startEquity = 0.0,
    anualIncomeRate = 0.06,
    startYear = 30,
    endYear = 90,
    contribution = 1000,
    retirementYear = 60
}: CalculateCCVParams) {

    let accumulative = startEquity
    const monthIncomeRate = (1 + anualIncomeRate) ** (1 / 12) - 1

    const result = [] as CCVItem[]

    // accumulation
    for (let current = startYear; retirementYear >= current; current++) {

        const balance = FV(
            monthIncomeRate,
            12,
            contribution * -1,
            accumulative * -1,
        )

        result.push({
            year: current,
            value: accumulative,
            movement: balance - accumulative
        })

        if (retirementYear >= current + 1)
            accumulative = balance
    }

    const totalMonthsContributed = (retirementYear - startYear) * 12
    const totalMonthsBetweenTransitionAndLifeExpectance = (endYear - retirementYear) * 12

    const pontetialPassiveIncome = PMT(
        monthIncomeRate,
        totalMonthsBetweenTransitionAndLifeExpectance, // calcular quantidade de meses
        accumulative * -1,
        0
    )

    // deaccumulation
    let deaccumulation = accumulative

    for (let current = retirementYear + 1; endYear >= current; current++) {

        const balance = FV(
            monthIncomeRate,
            12,
            pontetialPassiveIncome,
            deaccumulation * -1,
        )

        result.push({
            year: current,
            value: balance < 0 ? 0 : balance,
            movement: balance - deaccumulation
        })

        deaccumulation = balance
    }

    return {
        entries: result,
        maximumAccumulatedWealth: accumulative,
        pontetialPassiveIncome,
        totalMonthsContributed,
        totalMonthsBetweenTransitionAndLifeExpectance
    }
}

function FV(rate: number, nper: number, pmt: number, pv: number, type: number = 0) {
    var pow = Math.pow(1 + rate, nper),
        fv;
    pv = pv || 0;
    type = type || 0;
    if (rate) {
        fv = (pmt * (1 + rate * type) * (1 - pow) / rate) - pv * pow;
    } else {
        fv = -1 * (pv + pmt * nper);
    }
    return fv;
}

/*
* ir   - interest rate per month
* np   - number of periods (months)
* pv   - present value
* fv   - future value
* type - when the payments are due:
*        0: end of the period, e.g. end of month (default)
*        1: beginning of period
*/
function PMT(ir: number, np: number, pv: number, fv: number = 0, type: number = 0) {
    var pmt, pvif;

    fv || (fv = 0);
    type || (type = 0);

    if (ir === 0)
        return -(pv + fv) / np;

    pvif = Math.pow(1 + ir, np);
    pmt = - ir * (pv * pvif + fv) / (pvif - 1);

    if (type === 1)
        pmt /= (1 + ir);

    return pmt;
}