import { Outlet } from "react-router-dom";
import { SessionContext } from "../providers/session";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AppHeader from "./app-header";
import { getAuth, signOut } from "firebase/auth";
import { Button } from "components";

function PrivateRoutes() {
    const session = useContext(SessionContext)
    const navigate = useNavigate()

    const handleLogout = async () => {
        const auth = getAuth()
        await signOut(auth)
        navigate('/login')
    }

    useEffect(() => {
        if (session.initialized && !session.logged) {
            navigate('/login')
        }
    }, [session.initialized, session.logged])

    if (!session.initialized || !session.logged)
        return <span>Carregando...</span>

    if (session.initialized
        && session.logged
        && !session.isAdmin
        && !session.isConsultant) {
        return (
            <div className="container mx-auto w-full max-w-lg flex flex-col pt-10">
                <span className="text-lg pb-6">Você não tem permissão de acesso a esses sistema</span>
                <Button compact secondary onClick={handleLogout}>Clique aqui para sair e voltar para a tela de login</Button>
            </div >
        )
    }

    return (
        <>
            <AppHeader />
            <Outlet />
        </>
    );
}

export default PrivateRoutes;
