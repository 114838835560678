import { AllocationStrategy, InvestorProfile } from "@healthmoney/domain";

export const allocationStrategyToText = {
    [AllocationStrategy.Accumulation]: 'Acumulação',
    [AllocationStrategy.Growth]: 'Crescimento',
    [AllocationStrategy.Preservation]: 'Preservação',
}

export const investorProfileToText = {
    [InvestorProfile.Agressive]: 'Agressivo',
    [InvestorProfile.Bold]: 'Arrojado',
    [InvestorProfile.Conservative]: 'Conservador',
    [InvestorProfile.Moderate]: 'Moderado',
    [InvestorProfile.Ultraaggressive]: 'Ultra-agressivo',
}