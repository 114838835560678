import { useState } from 'react'
import { Formik, Form, FieldArray, FieldArrayRenderProps } from 'formik'
import { Yup, moment } from "helpers";
import { InvestorProfile, Scalars } from '@healthmoney/domain'
import { Button, FieldGroup, PercentageField, SelectFieldOptions, TextField } from '../../../components';
import { useCategories } from 'repositories/allocations-repository';
import { investorProfileToText } from 'helpers/constants';
import { type InputNumberValueChangeEvent } from 'primereact/inputnumber';
import DateField from 'components/inputs/date_field';

export type TBaseValues = {
    categoryId: Scalars['String'];
    contributionDate: Scalars['DateTime'];
    description: Scalars['String'];
    name: Scalars['String'];
    recomendationDate: Scalars['DateTime'];
}

export type TAllocationValues = TBaseValues & {
    investorProfile: { type: InvestorProfile, target: number }[]
}

const AllocationEntrySchema = Yup.object().shape({
    name: Yup.string().required(),
    description: Yup.string(),
    recomendationDate: Yup.date().required(),
    contributionDate: Yup.date().required(),
    investorProfile: Yup.array().of(
        Yup.object().shape({
            type: Yup.string().required(),
            target: Yup.number().default(0).min(0).max(100).test("length", "menos", val => val <= 100).required()
        })
    ).min(5).max(5).required(),
    categoryId: Yup.string().required().test("category", "Selecione uma \"Classe de produto\" válida", val => val !== "default"),
});


type AllocationEntryFormProps = {
    initialValues?: TAllocationValues,
    onSubmit: (entry: TAllocationValues) => Promise<void>
}

export default function AllocationEntryForm({
    initialValues = {
        name: '',
        description: '',
        recomendationDate: moment().toDate(),
        contributionDate: '',
        investorProfile: [
            {
                type: InvestorProfile.Conservative,
                target: 0
            },
            {
                type: InvestorProfile.Moderate,
                target: 0
            },
            {
                type: InvestorProfile.Bold,
                target: 0
            },
            {
                type: InvestorProfile.Agressive,
                target: 0
            },
            {
                type: InvestorProfile.Ultraaggressive,
                target: 0
            }
        ],
        categoryId: '',
    },
    onSubmit,
}: AllocationEntryFormProps) {
    const categories = useCategories()
    const [error, setError] = useState<any>(null)

    const handleSubmit = async (values: TAllocationValues, { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }) => {
        try {
            setSubmitting(true)
            await onSubmit(values)
        } catch (error) {
            setError(error)
        } finally {
            setSubmitting(false)
        }
    }
    const categoriesOptions = [{ name: "--", id: "default" }, ...categories.data]

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={AllocationEntrySchema}
            onSubmit={handleSubmit}>
            {(formikProps) => (
                <Form>
                    <p className='pb-4 text-md'>Informações</p>
                    <FieldGroup>
                        <TextField
                            id='name'
                            label='Ativo'
                            name='name'
                            key='name'
                            type='text'
                        />
                        <SelectFieldOptions
                            label='Classe de produto'
                            name='categoryId'
                            options={categoriesOptions.map((category => ({
                                text: category.name as string,
                                value: category.id as string
                            })))}
                        />

                    </FieldGroup>
                    <TextField
                        id='description'
                        label='Descrição'
                        name='description'
                        key='description'
                        type='text'
                    />
                    <FieldGroup>
                        <DateField
                            id='recomendationDate'
                            label='Mês de recomendação'
                            name='recomendationDate'
                            key='recomendationDate'
                            formik={formikProps}
                            placeholder='mm/aa'
                            dateFormat='mm/yy'
                            view='month'
                        />
                        <DateField
                            id='contributionDate'
                            label='Aporte até dia'
                            name='contributionDate'
                            key='contributionDate'
                            formik={formikProps}
                            dateFormat='dd/mm/yy'
                        />
                    </FieldGroup>

                    <FieldGroup>
                        <div className='w-1/2'>
                            <FieldArray
                                name="investorProfile"
                                render={(arr: FieldArrayRenderProps) => {
                                    const investorProfile = (initialValues as TAllocationValues).investorProfile
                                    const investorProfileValues = arr.form.values.investorProfile

                                    return (
                                        <div>
                                            {investorProfile.map((profile: { type: InvestorProfile, target: number }, index: number) => (
                                                <PercentageField
                                                    id={`investorProfile.${investorProfile[index].type}`}
                                                    key={`investorProfile.${investorProfile[index].type}`}
                                                    name={`investorProfile.${investorProfile[index].type}`}
                                                    label={investorProfileToText[investorProfile[index].type]}
                                                    onChange={(e: InputNumberValueChangeEvent) => {
                                                        arr.replace(index, { type: investorProfile[index].type, target: Number(e.value) })
                                                    }}
                                                    value={investorProfileValues[index].target}
                                                    formik={formikProps}
                                                />
                                            ))}
                                        </div>
                                    )
                                }}
                            />
                        </div>
                    </FieldGroup >

                    {error && (
                        <div className='py-4 font-bold'>
                            {error?.message}
                        </div>
                    )
                    }

                    <div className='pt-3'>
                        <Button type='submit' className='btn-primary' disabled={formikProps.isSubmitting}>Salvar</Button>
                    </div>
                </Form >
            )
            }
        </Formik >
    )
}