import classNames from "classnames";
import useCalendarDates from "./use-calendar-dates";
import { CalendarHeaderItem } from "./calendar-header-item";
import { CalendarRow } from "./calendar-row";
import { CalendarProps } from "./types";

export default function Calendar({
    month, year, notes = [], onNoteClick,
}: CalendarProps) {

    const dates = useCalendarDates(year, month)

    return (
        <div className={classNames('max-w-3xl')}>
            <div className={classNames(
                'flex flex-row'
            )}>
                <CalendarHeaderItem dayOfTheWeek={0} />
                <CalendarHeaderItem dayOfTheWeek={1} />
                <CalendarHeaderItem dayOfTheWeek={2} />
                <CalendarHeaderItem dayOfTheWeek={3} />
                <CalendarHeaderItem dayOfTheWeek={4} />
                <CalendarHeaderItem dayOfTheWeek={5} />
                <CalendarHeaderItem dayOfTheWeek={6} />
            </div>
            <CalendarRow month={month} year={year} dates={dates.slice(0, 7)} notes={notes} onNoteClick={onNoteClick} />
            <CalendarRow month={month} year={year} dates={dates.slice(7, 14)} notes={notes} onNoteClick={onNoteClick} />
            <CalendarRow month={month} year={year} dates={dates.slice(14, 21)} notes={notes} onNoteClick={onNoteClick} />
            <CalendarRow month={month} year={year} dates={dates.slice(21, 28)} notes={notes} onNoteClick={onNoteClick} />
            <CalendarRow month={month} year={year} dates={dates.slice(28, 35)} notes={notes} onNoteClick={onNoteClick} />
            <CalendarRow month={month} year={year} dates={dates.slice(35, 42)} notes={notes} onNoteClick={onNoteClick} />
        </div>
    )
}