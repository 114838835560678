import { useNavigate, useParams } from "react-router-dom";
import { PageHeading } from "../../../../components";
import DetailsForm from "./details-form";
import useDetails from "./use-details";

export default function CustomerDetailsView() {
    const params = useParams()
    const navigate = useNavigate()
    const { initial, update, error } = useDetails(params.uid!)

    if (!initial) {
        return null;
    }

    return (
        <>
            <PageHeading
                title={initial.displayName || initial.fullName || ''}
                description='Informações básicas do cliente'
                breadcrumb={{
                    homeLink: '/',
                    items: [
                        { text: 'Perfil do cliente', href: `/customers/${params.uid}` },
                        { text: 'Editando detalhes do cliente' }
                    ]
                }}
            />
            <div className='container mx-auto'>
                {error && <span>{error.message || 'Erro ao carregar dados'}</span>}
                <DetailsForm
                    initialValues={initial}
                    onSubmit={async (values) => {
                        await update(values)
                        navigate(`/customers/${params.uid}`)
                    }}
                />
            </div>
        </>
    )
}