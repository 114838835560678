import React, { useMemo } from 'react'
import { Formik, Form } from 'formik'
import { Yup } from "helpers";

import { AllocationProfile, InvestorProfile } from '@healthmoney/domain'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useCategories } from '../../../../repositories/allocations-repository';
import { useNavigate } from 'react-router-dom';
import { Button, FieldGroup, NumberField, SelectFieldOptions, TextField } from '../../../../components';
import { allocationStrategyToText, investorProfileToText } from '../../../../helpers/constants';

const AllocationProfileSchema = Yup.object().shape({
    name: Yup.string().required(),
    investorProfile: Yup.string().required(),
    anualIncomeRate: Yup.number().required()
});

type AllocationProfileFormProps = {
    initialValues?: AllocationProfile,
    onSubmit: (profile: AllocationProfile) => Promise<void>
}

export default function AllocationProfileForm({
    initialValues = {
        name: '',
        investorProfile: InvestorProfile.Conservative,
        anualIncomeRate: 2.5,
        items: []
    },
    onSubmit
}: AllocationProfileFormProps) {
    const categories = useCategories()
    const navigate = useNavigate()
    const [error, setError] = React.useState<any>(null)

    const initialValuesExtended = useMemo(() => {
        if (categories.data.length === 0) {
            return null
        }

        return {
            ...initialValues,
            items: categories.data
                .reduce((acc, category) => {
                    const current = initialValues.items.find(item => item.modelId === category.id)
                    acc[category.id] = current ? current.target : 0
                    return acc
                }, {} as Record<string, number>)
        }
    }, [initialValues, categories.data])

    if (initialValuesExtended === null)
        return <span>Carregando...</span>

    return (
        <Formik
            initialValues={initialValuesExtended}
            validationSchema={AllocationProfileSchema}
            onSubmit={async (values, { setSubmitting }) => {
                try {
                    setSubmitting(true)
                    await onSubmit({
                        ...values,
                        items: Object.keys(values.items).map((key) => {
                            return {
                                modelId: key,
                                target: values.items[key]
                            }
                        })
                    })
                    navigate('/admin/investor-profiles')
                } catch (error) {
                    console.error(error)
                    setError(error)
                } finally {
                    setSubmitting(false)
                }
            }}>
            {({ isSubmitting }) => (
                <Form>
                    <p className='pb-4 text-md'>Informações</p>
                    <FieldGroup>
                        <TextField
                            id='name'
                            label='Nome'
                            name='name'
                            key='name'
                            type='text'
                        />
                        <SelectFieldOptions
                            label='Perfil de investidor'
                            name='investorProfile'
                            options={[
                                { text: investorProfileToText[InvestorProfile.Conservative], value: InvestorProfile.Conservative },
                                { text: investorProfileToText[InvestorProfile.Moderate], value: InvestorProfile.Moderate },
                                { text: investorProfileToText[InvestorProfile.Bold], value: InvestorProfile.Bold },
                                { text: investorProfileToText[InvestorProfile.Agressive], value: InvestorProfile.Agressive },
                                { text: investorProfileToText[InvestorProfile.Ultraaggressive], value: InvestorProfile.Ultraaggressive },
                            ]}
                        />
                        <TextField
                            id='anualIncomeRate'
                            label='Taxa de rendimento anual'
                            name='anualIncomeRate'
                            key='anualIncomeRate'
                            type='number'
                            min={0}
                            max={10}
                            step={0.01}
                        />
                    </FieldGroup>
                    <div className='py-4'>
                        <DataTable
                            size='small'
                            value={categories.data.map(category => ({
                                ...category,
                                displayStrategy: allocationStrategyToText[category.allocationStrategy]
                            }))}
                            tableStyle={{ minWidth: '50rem' }}>
                            <Column field="name" header="Nome" sortable></Column>
                            <Column field="displayStrategy" header="Estratégia" sortable></Column>
                            <Column
                                field="value"
                                sortable
                                header="Proporção"
                                body={(category) => (
                                    <NumberField
                                        key={`items.${category.id}`}
                                        id={`items.${category.id}`}
                                        name={`items.${category.id}`}
                                        type='number'
                                        max='100'
                                        min='0'
                                        step={0.01}
                                    />
                                )}
                            />
                        </DataTable>
                    </div>
                    {error && (
                        <div className='py-4 font-bold'>
                            {error.message}
                        </div>
                    )}
                    <div>
                        <Button type='submit'>Salvar</Button>
                    </div>
                </Form>
            )}
        </Formik>
    )
}
