import { Button } from "components";
import WalletProfileChart from "components/containers/wallet-chart/wallet-profile-chart";
import { useCategories } from "repositories/allocations-repository";
import { useLocation, useNavigate } from "react-router-dom";
import useRegistrationBoarding from "../useRegistrationBoarding";
import { setRegisterMaxStep } from "repositories/customers-repository";

export default function RegisterBoardingWallet() {

    const { profile, uid, maxStep } = useRegistrationBoarding()
    const { pathname } = useLocation()

    const categories = useCategories()
    const navigate = useNavigate()
    const setMaxStep = setRegisterMaxStep()

    const buttonText = !pathname.includes("/registration") ?
        'Salvar' : maxStep > 5
            ? 'Salvar'
            : 'Próximo'

    const onNext = async () => {
        await setMaxStep(uid!, maxStep > 6 ? maxStep : 6)
        navigate(`/registration/${ uid }/products`)
    }

    return (
        <>
            <WalletProfileChart
                profile={profile!}
                categories={categories.data}
            />
            <Button className="mt-4" onClick={onNext}>
                {buttonText}
            </Button>
        </>
    )
}