import { CustomerRetirementPlan, Product } from "@healthmoney/domain"

type CalculateRecomendedProductProps = {
    // registration: RegistrationRegister
    retirementPlan: CustomerRetirementPlan
    products: Product[]
}

export default function calculateRecomendedProduct({
    // registration,
    retirementPlan,
    products
}: CalculateRecomendedProductProps): Product[] {
    const result: Product[] = []
    const equity = retirementPlan?.startEquity || 0

    products.forEach(product => {
        if (!product.maxSelectionValue && equity >= product?.minSelectionValue) {
            result.push(product)
        }
        else if (equity >= product.minSelectionValue && equity < product.maxSelectionValue!) {
            result.push(product)
        }
    })

    return result
}
