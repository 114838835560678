import { setRegisterDeleteProject } from "repositories/customers-repository"
import RegisterBoardingProjects from "../steps/register-boarding-projects"
import Loading from "components/containers/loading"
import { formatCurrency } from "helpers"
import { Button } from "components"
import useRegistrationBoarding from "../useRegistrationBoarding"
import { moment } from "helpers"

const RegisterBoardingProjectsView = () => {

  const { user, uid, loaded } = useRegistrationBoarding()

  const removeProject = setRegisterDeleteProject(uid!)

  return (
    <>
      <Loading isLoading={!loaded}>
        <div className="container mx-auto max-w-screen-lg py-8">
          <RegisterBoardingProjects />
          {user?.projects?.length! > 0 ? (
            <>
              {(user?.projects)?.map((project, index) => (
                <div key={index} className="bg-neutral-0 shadow-lg p-6 flex flex-row mb-4">
                  <div className="flex-1">
                    <p className="mb-2 text-md leading-compact font-bold">{project.title}</p>
                    <p className="mb-5">{project.description}.</p>
                    <div className="flex flex-row">
                      <div className="mr-6">
                        <p>Data prevista</p>
                        <p className="font-bold">{moment(project.date.toDate()).format("DD/MM/YYYY")}</p>

                      </div>
                      <div>
                        <p>Valor estimado</p>
                        <p className="font-bold">{formatCurrency(project.value)}</p>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <Button
                      secondary
                      onClick={async () => {

                        user?.projects?.splice(index, 1)

                        await removeProject(user.projects || [])
                      }}>
                      Excluir
                    </Button>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <p>Você ainda não tem projetos cadastrados...</p>
          )}
        </div>
      </Loading>
    </>
  )
}

export default RegisterBoardingProjectsView