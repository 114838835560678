import { useNavigate, useParams } from "react-router-dom";
import { Button, FieldGroup, PageHeading, SelectField, SelectFieldOptions, TextField } from "../../../../components";
import { useEffect, useState } from "react";
import { CustomerAccount, CustomerAccountStatus, CustomerDetails } from "@healthmoney/domain";
import { getCustomerDetails, getCustomerAccount, useSetCustomerAccount } from "repositories/customers-repository";
import { Form, Formik } from "formik";

export default function CustomerAccountView() {
    const params = useParams()
    const navigate = useNavigate()
    const [details, setDetails] = useState<CustomerDetails | null | undefined>(undefined)
    const [account, setAccount] = useState<CustomerAccount | null>(null)
    const setCustomerAccount = useSetCustomerAccount()

    const handleSubmit = async (account: CustomerAccount) => {
        await setCustomerAccount({
            ...account,
            uid: details!.uid,
            cid: details!.cid
        })
        navigate(`/customers/${params.uid}`)
    }

    useEffect(() => {
        const handle = async () => {
            const [
                details, account
            ] = await Promise.all([
                getCustomerDetails(params.uid!),
                getCustomerAccount(params.uid!)
            ])
            setDetails(details)
            setAccount(account)
        }
        handle()
    }, [params.uid])

    if (!details)
        return <span>Carregando...</span>

    return (
        <>
            <PageHeading
                title={details!.displayName || details!.fullName || ''}
                description='Gerenciamento da conta do cliente'
                breadcrumb={{
                    homeLink: '/',
                    items: [
                        { text: 'Perfil do cliente', href: `/customers/${params.uid}` },
                        { text: 'Conta' }
                    ]
                }}
            />
            <div className='container mx-auto'>
                <Formik
                    initialValues={{
                        product: '',
                        accountStatus: CustomerAccountStatus.Active,
                        ...account
                    }}
                    onSubmit={handleSubmit}>
                    {(formikProps) => (
                        <Form>
                            <p className='text-sm mt-6 pb-4 font-bold'>Situação da conta</p>
                            <FieldGroup>
                                <SelectFieldOptions
                                    label="Produto"
                                    key='product'
                                    name='product'
                                    options={[
                                        { text: 'Nenhum', value: '' },
                                        { text: 'Money Start', value: 'money-start' },
                                        { text: 'Money Select', value: 'money-select' },
                                        { text: 'Money Private', value: 'money-private' },
                                        { text: 'Money Family Office', value: 'money-family-office' }
                                    ]}
                                />
                                <SelectFieldOptions
                                    label="Situação"
                                    key='accountStatus'
                                    name='accountStatus'
                                    options={[
                                        { text: 'Ativo', value: CustomerAccountStatus.Active },
                                        { text: 'Bloqueado', value: CustomerAccountStatus.Blocked },
                                        { text: 'Cancelado', value: CustomerAccountStatus.Canceled },
                                        // { text: 'Convertido', value: CustomerAccountStatus.Converted },
                                        // { text: 'Criado', value: CustomerAccountStatus.Created },
                                        // { text: 'Inativo', value: CustomerAccountStatus.Inactive },
                                    ]}
                                />
                            </FieldGroup>
                            <FieldGroup>
                                <TextField
                                    label="Ativo até"
                                    key='activeUntil'
                                    name='activeUntil'
                                    type='date' />
                            </FieldGroup>
                            <div className="py-6">
                                <Button type='submit' className='btn-primary' disabled={formikProps.isSubmitting}>
                                    Salvar
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    )
}