import { AllocationCategory } from "@healthmoney/domain";
import { Button } from "components";
import { formatCurrency } from "helpers";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useMemo } from "react";
import { moment } from 'helpers'

type CustomAllocationsEntriesProps = {
    categories: AllocationCategory[]
    entries: any[],
    handleDelete: (entry: any) => Promise<void>
}

export default function CustomAllocationsEntries({
    categories = [],
    entries = [],
    handleDelete
}: CustomAllocationsEntriesProps) {

    const withCategoryText = useMemo(() => {
        return entries.map(entry => {
            const search = categories.filter(c => c.id === entry.categoryId)
            return {
                ...entry,
                categoryText: search.length > 0 ? search[0].name : '--',
                contributionDate: moment(entry.contributionDate.toDate()).format('DD/MM/YYYY'),
                recomendationDate: moment(entry.recomendationDate.toDate()).format('MM/YYYY'),
            }
        })
    }, [categories, entries])

    return (
        <DataTable value={withCategoryText} tableStyle={{ minWidth: '50rem' }}>
            <Column field="name" header="Nome" sortable></Column>
            <Column field="actionType" header="Operação" sortable body={(item) => (
                item.actionType === 'buy' ? 'Compra' : 'Venda'
            )}></Column>
            <Column field="target" header="Alvo" sortable body={(a) => formatCurrency(a.target, a.currencyType)}></Column>
            <Column field="recomendationDate" header="Mês da indicação" sortable></Column>
            <Column field="contributionDate" header="Data da compra" sortable></Column>
            <Column field="categoryText" header="Categoria" sortable></Column>

            <Column
                body={(item) => (
                    <div>
                        <Button secondary compact onClick={() => handleDelete(item)}>
                            Excluir
                        </Button>
                    </div>
                )} />
        </DataTable>
    )
}