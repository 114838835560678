import { FieldGroup, TextField, Button, MoneyField } from 'components'
import AppModal from 'components/containers/app-modal'
import { Form, Formik } from 'formik'
import { useState } from 'react'
import { useLocation } from 'react-router-dom';
import { Yup } from "helpers";

import useRegistrationBoarding from '../register-boarding/useRegistrationBoarding';
import DateField from 'components/inputs/date_field';
import { moment } from 'helpers';

const ProjectSchema = Yup.object().shape({
  date: Yup.date().required(),
  title: Yup.string().required(),
  description: Yup.string().required(),
  value: Yup.number().required(),
});

type ProjectFormProps = {
  initialValues?: {
    date: string,
    term: 'short',
    title: string,
    description: string,
    value: number
  },
  handleSubmit: (values: any) => Promise<void>,
  goNext: () => void
}

const ProjectForm = ({ initialValues = {
  date: '',
  term: 'short',
  title: '',
  description: '',
  value: 0
},
  handleSubmit,
  goNext
}: ProjectFormProps) => {

  const { maxStep } = useRegistrationBoarding()
  const { pathname } = useLocation()

  const [opened, setOpened] = useState(false)


  const [error, setError] = useState<any>(null)

  const buttonText = !pathname.includes("/registration") ?
    'Salvar' : maxStep > 3
      ? 'Salvar'
      : 'Próximo'

  return (
    <>
      <AppModal
        isOpen={opened}
        onClose={() => setOpened(false)}
        contentLabel="Novo projeto">
        <Formik
          initialValues={initialValues}
          validationSchema={ProjectSchema}
          validateOnMount
          onSubmit={async (values, { setSubmitting }) => {
            try {
              setSubmitting(true)

              const data ={
                term: values.term,
                title: values.title,
                description: values.description,
                value: values.value,
                date: moment(values.date).toDate()
              }

              await handleSubmit(data)
            } catch (error) {
              console.error(error)
              setError(error)
            } finally {
              setSubmitting(false)
              setOpened(false)
            }
          }}
        >
          {(formikProps) => (
            <Form>
              <FieldGroup>
                <TextField
                  key='title'
                  name='title'
                  label="Título do projeto"
                />
              </FieldGroup>
              <FieldGroup>
                <TextField
                  key='description'
                  name='description'
                  label="Descrição do projeto"
                />
              </FieldGroup>
              <FieldGroup>
                <DateField
                  formik={formikProps}
                  name='date'
                  label="Data prevista"
                />
                <MoneyField
                  key='value'
                  name='value'
                  label="Valor estimado"
                  formik={formikProps}
                />

              </FieldGroup>
              {error && (
                <div className='py-4 font-bold'>
                  {error.message}
                </div>
              )}
              <div className="flex flex-row pt-6">
                <Button
                  type="button"
                  secondary
                  className="mr-3"
                  onClick={() => setOpened(false)}>
                  Voltar
                </Button>
                <Button
                  type="submit"
                >
                  Adicionar
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </AppModal>
      <div className="flex flex-row pb-4">
        <Button
          secondary
          className='mr-3'
          onClick={() => setOpened(!opened)}>
          Cadastrar um novo projeto
        </Button>
        <Button
          onClick={goNext}>
          {buttonText}
        </Button>
      </div>
    </>
  )
}

export default ProjectForm