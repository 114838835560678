import { A, Button, PageHeading } from 'components'
import Loading from 'components/containers/loading'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { useAuth } from 'reactfire'
import { fullDeleteUser } from 'repositories/admin-repository'
import { useCustomersDetails } from 'repositories/customers-repository'

const VisualizeAllCustomersView = () => {

    const auth = useAuth()
    const users = useCustomersDetails()

    return (
        <Loading isLoading={users.status === "loading"}>
            <PageHeading
                title='Clientes'
                description='Lista de clientes cadastrados' />
            <section className="w-full bg-neutral-0 pb-4">
                <div className='container mx-auto flex flex-col pt-6 gap-4'>
                    <DataTable value={users?.data} tableStyle={{ minWidth: '50rem' }}>
                        <Column field='fullName' header="Nome de exibição" sortable filter />
                        <Column field='email' header="Nome de exibição" sortable filter />
                        <Column
                            header="Ações"
                            body={(customer) => (
                                <div className="flex flex-row">
                                    <Button compact onClick={() => {
                                        // eslint-disable-next-line no-restricted-globals
                                        if (confirm('Esse processo irá apagar todos os dados desse usuário, tem certeza que deseja continuar?')) {
                                            fullDeleteUser(auth.currentUser!.email!, customer.uid)
                                        }
                                    }}>
                                        Deletar usuário
                                    </Button>
                                    <A
                                        button
                                        buttonCompact
                                        buttonSecondary
                                        className='max-w-[180px] ml-2'
                                        href={`/customers/${customer.uid}`}>
                                        Perfil
                                    </A>
                                </div>
                            )}
                        />
                    </DataTable>
                </div>
            </section>
        </Loading>
    )
}

export default VisualizeAllCustomersView
