import {
	AllocationCategory,
	CoreConstantsCurrencies,
	CoreConstantsCurrenciesRates,
	CustomerInvestment,
} from "@healthmoney/domain";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { formatCurrency } from "helpers";
import { A } from "components";

type TableExplorerComponentProps = {
	investments: CustomerInvestment[];
	handleSetClassification: (investment: CustomerInvestment, classification: string) => void;
	categories: AllocationCategory[];
	categoryMap: Record<string, AllocationCategory>;
	convertCurrency: (investment: CustomerInvestment, rates: CoreConstantsCurrenciesRates) => number;
	currencies: CoreConstantsCurrencies;
	banksMap: any;
	uid?: string,
};

export default function TableExplorerComponent({
	investments,
	handleSetClassification,
	categories,
	categoryMap,
	convertCurrency,
	currencies,
	banksMap,
	uid,
}: TableExplorerComponentProps) {
	const entries = investments
		.filter((b) => b.balance > 0)
		.map((investment: any) => {
			investment.bank = banksMap[investment.provider.itemId]?.name || "---";
			investment.bankImg = banksMap[investment.provider.itemId]?.imageUrl;
			return investment;
		});
	return (
		<>
			<DataTable value={entries} tableStyle={{ minWidth: "50rem" }}>
				<Column
					field='bank'
					header='Origem'
					sortable
					body={(investment: CustomerInvestment) => (
						investment.provider?.originLogo ? (
							<img src={investment.provider!.originLogo} className='w-6 mr-2 inline-block' alt={investment.provider!.origin || ''} />
						) : (
							<span>{investment.provider?.origin || '---'}</span>
						)
					)}
				/>
				<Column field='code' header='Código' sortable />
				<Column field='name' header='Nome' sortable />
				<Column
					field='classification'
					sortable
					header='Classe'
					body={(investment: CustomerInvestment) => (
						<span>{categories.find(c => c.id === investment.classification)?.name || '--'}</span>
					)}
				/>
				<Column
					field='balance'
					header='Valor'
					sortable
					body={(investment: CustomerInvestment) => {
						const converted = convertCurrency(investment, currencies.rates!);

						return (
							<>
								<span>{formatCurrency(converted, "BRL")}</span>
								{investment.currencyCode && investment.currencyCode !== "BRL" && (
									<>
										<br />
										<span className='text-xxxs text-neutral-500'>
											{formatCurrency(investment.balance, investment.currencyCode!)}
										</span>
									</>
								)}
							</>
						);
					}}
				/>

				{uid && (
					<Column
						header='Ações'
						field="Ações"
						body={(investment: CustomerInvestment) => investment?.provider?.name === 'manual'
							? (
								<A
									button
									buttonCompact
									buttonSecondary
									className='max-w-[150px]'
									href={`/customers/${uid}/investments/${investment.id}`}>
									Editar
								</A>
							)
							: ''}
					/>
				)}
			</DataTable>
		</>
	);
}
