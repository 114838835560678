import React from 'react'
import { Formik, Form } from 'formik'
import { TaskStatus } from '@healthmoney/domain'
import { Yup } from 'helpers'

import { useNavigate, useParams } from 'react-router-dom';
import { useCreateTask } from '../../../../../repositories/customers-repository';
import { Button, FieldGroup, PageHeading, TextField } from '../../../../../components';
import MDField from 'components/inputs/markdow_field';
import moment from 'moment';

const TaskSchema = Yup.object().shape({
    dateForCompletion: Yup.date().required(),
    details: Yup.string().required(),
    tag: Yup.string().required(),
    title: Yup.string().required(),
});


export default function CreateTaskView() {
    const params = useParams()
    const navigate = useNavigate()
    const [error, setError] = React.useState<any>(null)
    const createTask = useCreateTask()

    return (
        <div>
            <PageHeading
                title='Nova tarefa'
                description='Cadastro de nova tarefa a ser realizada pelo cliente'
            />
            <div className='container mx-auto pt-6'>
                <Formik
                    initialValues={{
                        title: '',
                        details: '',
                        tag: '',
                        dateForCompletion: '',
                    }}
                    validationSchema={TaskSchema}
                    onSubmit={async (values, { setSubmitting }) => {
                        try {
                            setSubmitting(true)
                            await createTask({
                                uid: params.uid,
                                requestedDate: moment().toDate(),
                                dateForCompletion: moment(values.dateForCompletion).toDate(),
                                status: TaskStatus.Pending,
                                title: values.title,
                                details: values.details,
                                tag: values.tag
                            })
                            navigate(`/customers/${params.uid}`)
                        } catch (error) {
                            console.error(error)
                            setError(error)
                        } finally {
                            setSubmitting(false)
                        }
                    }}>
                    {(formikProps) => (
                        <Form>
                            <FieldGroup>
                                <TextField
                                    name='title'
                                    label='Titulo'
                                    type='text'
                                />
                                <TextField
                                    name='tag'
                                    label='Tag'
                                    type='text'
                                />
                            </FieldGroup>
                            <FieldGroup>
                                <MDField
                                    label='Detalhes'
                                    name='details'
                                    formik={formikProps}
                                />
                            </FieldGroup>
                            <FieldGroup>
                                <TextField
                                    name='dateForCompletion'
                                    label='Data para finalização'
                                    type='date'
                                />
                            </FieldGroup>
                            {error && (
                                <div className='py-3'>
                                    {error}
                                </div>
                            )}
                            <div className='pt-4'>
                                <Button disabled={formikProps.isSubmitting} type='submit'>
                                    {formikProps.isSubmitting ? 'Cadastrando...' : 'Cadastrar'}
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}