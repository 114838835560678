import { Stepper, Breadcrumb } from 'components'
import type { BreadcrumbProps } from './breadcrumb'
import type { StepperProps } from './stepper'
import classNames from 'classnames'

type AllocationsProps = {
    title: string,
    description?: string,
    breadcrumb?: BreadcrumbProps
    stepper?: StepperProps,
    shorter?: boolean,
    content?: React.ReactElement,
    processing?: boolean
}

export default function PageHeading({
    title,
    description,
    breadcrumb,
    stepper,
    shorter = false,
    content,
    processing
}: AllocationsProps) {
    return (
        <div className="w-full bg-neutral-50 py-7">

            <div className="container mx-auto">
                {breadcrumb && (
                    <div className='pb-5'>
                        <Breadcrumb {...breadcrumb} />
                    </div>
                )}
                {stepper && (
                    <div className='pb-7'>
                        <Stepper {...stepper} />
                    </div>
                )}
                <div className={classNames(
                    shorter ? 'container mx-auto max-w-screen-lg' : ''
                )}>
                    {processing &&
                        <h1 className="font-bold text-lg text-primary-500 leading-far pb-3">Este usuário já possui uma solicitação de pagamento pendente</h1>
                    }
                    <h2 className="font-bold text-lg text-neutral-900 leading-far pb-3">{title}</h2>
                    {!content && <p>{description}</p>}
                    {content}
                </div>
            </div>
        </div>
    )
}