import { ErrorMessage, FormikProps } from 'formik';
import FieldGroup from './field_block'
import { InputHTMLAttributes, useState } from 'react';
import { InputNumber, type InputNumberValueChangeEvent } from 'primereact/inputnumber';
import classNames from 'classnames';


interface Props extends InputHTMLAttributes<HTMLInputElement> {
    label?: string
    name: string,
    formik: FormikProps<any>
    onChange: (e: any) => void
    value: number
}

export default function PercentageField({ label, name, formik, onChange, value: targetValue, ...rest }: Props) {
    const [value, setValue] = useState<number>(targetValue)

    return (
        <FieldGroup>
            <div className='flex flex-col flex-1 relative'>
                <label
                    className='text-xxs'
                    htmlFor={name}>{label}</label>
                <InputNumber
                    {...rest as any}
                    maxFractionDigits={2}
                    step={0.01}
                    min={0}
                    max={100}
                    value={value}
                    prefix='% '
                    placeholder='%'
                    onValueChange={(e: InputNumberValueChangeEvent) => {
                        setValue(e?.value ?? 0)
                        onChange(e)
                    }}
                    inputId={name}
                    className={classNames(
                        'appearance-none border-b w-full py-1 border-b-neutral-800',
                        'focus:outline-none focus:shadow-outline text-xs focus-visible:outline-none focus-visible:border-none',
                    )}
                    inputStyle={{ border: 'none', padding: 0, outline: '2px solid transparent', boxShadow: 'none' }}
                    inputClassName='appearance-none border-b w-full py-1 border-b-neutral-800 focus:outline-none focus:shadow-outline text-xs focus-visible:outline-none focus-visible:border-none focus:shadow-none'
                />
            </div>
            <ErrorMessage
                name={name!}
                component="span"
                className='text-danger-500 font-bold py-1' />
        </FieldGroup>
    )
}
