import { useState } from 'react'
import { Formik, Form } from 'formik'
import { CustomerDetails } from '@healthmoney/domain'
import { Yup } from "helpers";
import { validatePhone, validateCPF, validateCep, validateEmail } from 'validations-br';
import CepObserver from './cep-observer'
import { Button, FieldGroup, SelectField, SelectFieldOptions, TextField } from '../../../../components';
import { TUserPrid } from '../register-boarding/register-boarding-provider';
import { moment } from 'helpers'
import { useLocation } from 'react-router-dom';
import useRegistrationBoarding from '../register-boarding/useRegistrationBoarding';
import DateField from 'components/inputs/date_field';

const CustomerDetailsSchema = Yup.object().shape({
    address1: Yup.string().required(),
    address2: Yup.string().optional(),
    address3: Yup.string().required(),
    birthdate: Yup.date().required(),
    children: Yup.string().required(),
    city: Yup.string().required(),
    cpf: Yup.string().required().test('is-cpf', 'CPF inválido', validateCPF),
    email: Yup.string().required().test('is-email', 'E-mail inválido', validateEmail),
    fullName: Yup.string().required(),
    insurance: Yup.string().required(),
    marriageRegime: Yup.string().required().default(""),
    maritialStatus: Yup.string().when("marriageRegime", ([marriageRegime], schema) => {
        if (marriageRegime === 'casado') return schema.required('O "Regime de casamento" é obrigatório quando o estado civil é "casado"')
        return schema
    }),
    number: Yup.string().required(),
    phone: Yup.string().required().test('is-phone', 'Telefone inválido', validatePhone),
    postalCode: Yup.string().required().test('is-cep', 'CEP inválido', validateCep),
    state: Yup.string().required(),
})

type ComponentProps = {
    initialValues?: CustomerDetails | TUserPrid,
    onSubmit: (details: CustomerDetails) => Promise<void>,
}

const INITIAL_VALUES = {
    address1: '',
    address2: '',
    address3: '',
    birthdate: '',
    children: '',
    city: '',
    cpf: '',
    email: '',
    fullName: '',
    insurance: '',
    maritialStatus: '',
    marriageRegime: '',
    number: '',
    phone: '',
    postalCode: '',
    state: '',
} as const

export default function DetailsForm({
    initialValues,
    onSubmit,
}: ComponentProps) {
    const [error, setError] = useState<any>(null)
    const { pathname } = useLocation()
    const { maxStep } = useRegistrationBoarding()

    const buttonText = !pathname.includes("/registration") ?
        'Salvar' : maxStep > 0
            ? 'Salvar'
            : 'Próximo'

    return (
        <Formik
            initialValues={{
                ...INITIAL_VALUES,
                ...initialValues,
                birthdate: initialValues?.birthdate ?
                    moment((initialValues.birthdate as any)?.toDate())?.format("YYYY-MM-DD") : ''
            }}
            validationSchema={CustomerDetailsSchema}
            onSubmit={async (values, { setSubmitting }) => {
                try {
                    setSubmitting(true)
                    await onSubmit({
                        ...values,
                        birthdate: moment(values.birthdate).toDate(),
                    })
                } catch (error) {
                    console.error(error)
                    setError(error)
                } finally {
                    setSubmitting(false)
                }
            }}>
            {(formikProps) => (
                <Form>
                    <CepObserver />
                    <p className='text-sm mt-6 pb-4 font-bold'>Dados pessoais</p>
                    <FieldGroup>
                        <TextField
                            name='fullName'
                            label='Nome completo'
                            type='text'
                        />
                        <DateField
                            formik={formikProps}
                            name='birthdate'
                            label='Data de nascimento'
                        />
                    </FieldGroup>
                    <FieldGroup>
                        <TextField
                            name='email'
                            label='E-mail'
                            type='email'
                        />
                        <TextField
                            name='cpf'
                            label='CPF'
                            type='text'
                        />
                    </FieldGroup>
                    <FieldGroup>
                        <TextField
                            name='phone'
                            label='Celular'
                            type='text'
                        />
                        <SelectFieldOptions
                            label='Estado civil'
                            name='marriageRegime'
                            options={[
                                { text: 'Selecione um valor', value: '' },
                                { text: 'Casado (a)', value: 'casado' },
                                { text: 'União estável de fato', value: 'uniao-estavel-de-fato' },
                                { text: 'União estável formalizada', value: 'uniao-estavel-formalizada' },
                                { text: 'Viúvo (a)', value: 'viuvo' },
                                { text: 'Solteiro (a)', value: 'solteiro' },
                                { text: 'Divorciado (a)', value: 'divorciado' },
                            ]}
                        />
                    </FieldGroup>
                    <FieldGroup>
                        <SelectField
                            label='Filhos'
                            name='children'
                            values={[
                                '',
                                'Nenhum',
                                '01',
                                '02',
                                '03',
                                '04',
                                '05',
                                '06'
                            ]}
                        />
                        <SelectField
                            label='Possui apólice de seguro?'
                            name='insurance'
                            values={[
                                '',
                                'Sim',
                                'Não',
                            ]}
                        />
                    </FieldGroup>
                    {formikProps.values.marriageRegime === "casado" ? (
                        <FieldGroup>
                            <SelectField
                                label='Regime de casamento'
                                name='maritialStatus'
                                values={[
                                    '',
                                    'Comunhão total',
                                    'Comunhão parcial',
                                    'Separação total',
                                    'Separação obrigatória',
                                    'Participação final nos aquestos'
                                ]}
                            />
                        </FieldGroup>
                    ) : null}
                    <p className='text-sm mt-7 pb-4 font-bold'>Endereço</p>
                    <FieldGroup>
                        <TextField
                            name='postalCode'
                            label='CEP'
                            type='text'
                        />
                        <TextField
                            name='address1'
                            label='Endereço'
                            type='text'
                        />
                    </FieldGroup>
                    <FieldGroup>
                        <TextField
                            name='number'
                            label='Número'
                            type='text'
                        />
                        <TextField
                            name='address2'
                            label='Complemento'
                            type='text'
                        />
                    </FieldGroup>
                    <FieldGroup>
                        <TextField
                            name='address3'
                            label='Bairro'
                            type='text'
                        />
                    </FieldGroup>
                    {error && (
                        <div className='py-4 font-bold'>
                            {error.message}
                        </div>
                    )}
                    <div className='py-6'>
                        <Button
                            disabled={formikProps.isSubmitting || !formikProps.isValid}
                            type="submit">
                            {buttonText}
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    )
}