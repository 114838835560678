import { useNavigate, useParams } from "react-router-dom";
import { PageHeading } from "../../../../components";
import IncomeForm from "./income-form";
import { useEffect, useState } from "react";
import { CustomerDetails, CustomerIncome } from "@healthmoney/domain";
import { getCustomerDetails, getCustomerIncome, useSetCustomerIncome } from "repositories/customers-repository";
import { useAllocationProfiles } from "repositories/allocations-repository";

export default function CustomerIncomeView() {
    const params = useParams()
    const navigate = useNavigate()
    const setCustomerIncome = useSetCustomerIncome()
    const profiles = useAllocationProfiles()

    const [details, setDetails] = useState<CustomerDetails | null | undefined>(undefined)
    const [income, setIncome] = useState<CustomerIncome | null>(null)

    const handleSubmit = async (income: CustomerIncome) => {
        await setCustomerIncome({
            ...income,
            uid: details!.uid,
            cid: details!.cid
        })
        navigate(`/customers/${params.uid}`)
    }


    useEffect(() => {
        const handle = async () => {
            const [
                details, income
            ] = await Promise.all([
                getCustomerDetails(params.uid!),
                getCustomerIncome(params.uid!)
            ])
            setDetails(details)
            setIncome(income)
        }
        handle()
    }, [params.uid])

    if (!details)
        return <span>Carregando...</span>

    return (
        <>
            <PageHeading
                title={details!.displayName || details!.fullName || ''}
                description='Informações financeiras do cliente'
                breadcrumb={{
                    homeLink: '/',
                    items: [
                        { text: 'Perfil do cliente', href: `/customers/${params.uid}` },
                        { text: 'Editando detalhes do cliente' }
                    ]
                }}
            />
            <div className='container mx-auto'>
                <IncomeForm
                    initialValues={income!}
                    showInvestorProfile
                    allocationProfiles={profiles.data || null}
                    onSubmit={handleSubmit}
                />
            </div>
        </>
    )
}