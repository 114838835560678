import React from 'react'
import { Formik, Form } from 'formik'
import { Yup } from "helpers";

import { useNavigate, useParams } from 'react-router-dom';
import { useCreateProject } from '../../../../../repositories/customers-repository';
import { Button, FieldGroup, MoneyField, PageHeading, SelectField, TextField } from '../../../../../components';
import { moment } from 'helpers'

const TaskSchema = Yup.object().shape({
    title: Yup.string().required(),
    description: Yup.string().required(),
    term: Yup.string().required().test('valid-term', (val, { createError, path }) => val === '----' ? createError({
        message: `Selecione um termo válido`,
        path
    })
        : true),
    date: Yup.date().required(),
    value: Yup.number().required().min(0.1),
});

export default function CreateProjectView() {
    const params = useParams()
    const navigate = useNavigate()
    const [error, setError] = React.useState<any>(null)
    const createProject = useCreateProject()

    return (
        <div>
            <PageHeading
                title='Nova tarefa'
                description='Cadastro de nova tarefa a ser realizada pelo cliente'
            />
            <div className='container mx-auto pt-6'>
                <Formik
                    initialValues={{
                        title: '',
                        description: '',
                        term: '',
                        date: '',
                        value: 0,
                    }}
                    validationSchema={TaskSchema}
                    onSubmit={async (values, { setSubmitting }) => {
                        try {
                            setSubmitting(true)
                            await createProject({
                                id: '',
                                date: moment(values.date).toDate(),
                                term: values.term,
                                title: values.title,
                                description: values.description,
                                uid: params.uid,
                                value: values.value
                            })
                            navigate(`/customers/${params.uid}`)
                        } catch (error) {
                            console.error(error)
                            setError(error)
                        } finally {
                            setSubmitting(false)
                        }
                    }}>
                    {(formikProps) => (
                        <Form>
                            <FieldGroup>
                                <TextField
                                    name='title'
                                    label='Titulo'
                                    type='text'
                                />
                                <SelectField
                                    name='term'
                                    label='Período'
                                    values={[
                                        '----',
                                        'short',
                                        'long'
                                    ]}
                                />
                            </FieldGroup>
                            <FieldGroup>
                                <TextField
                                    name='description'
                                    label='Descrição'
                                    type='text'
                                />
                            </FieldGroup>
                            <FieldGroup>
                                <MoneyField
                                    formik={formikProps}
                                    name='value'
                                    label='Valor estimado'
                                />
                                <TextField
                                    name='date'
                                    label='Data prevista'
                                    type='date'
                                />
                            </FieldGroup>
                            {error && (
                                <div className='py-3'>
                                    {error}
                                </div>
                            )}
                            <div className='pt-4'>
                                <Button disabled={formikProps.isSubmitting} type='submit'>
                                    {formikProps.isSubmitting ? 'Cadastrando...' : 'Cadastrar'}
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}