import CustomAllocationEntryForm from 'features/allocations/containers/custom-allocation-entry-form'
import { moment } from 'helpers'
import { SessionContext } from 'providers/session'
import { useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useCreateExclusiveIndication } from 'repositories/allocations-repository'
import { CustomAllocationEntryForm as TCustomAllocationEntryForm, CustomAllocationEntry, AllocationEntryValueType } from '@healthmoney/domain';
import { PageHeading } from 'components'

const AddCustomAllocationEntryView = () => {

    const params = useParams()
    const session = useContext(SessionContext)
    const createAllocation = useCreateExclusiveIndication(params.uid!)
    const navigate = useNavigate()

    const handleSubmit = async (entry: TCustomAllocationEntryForm) => {
        const data = {
            ...entry,
            uid: params.uid!,
            cid: session.email!,
            description: entry.description === '' ? null : entry.description,
            contributionDate: moment(entry.contributionDate).toISOString(),
            recomendationDate: moment(entry.recomendationDate).toISOString(),
            valueType: AllocationEntryValueType.Absolute
        } as CustomAllocationEntry

        createAllocation(data)
        navigate(`/customers/${params.uid!}/allocations`)
    }

    return (
        <>
            <PageHeading
                title={'Criando recomendação exclusiva'}
                description='Cadastre uma nova recomendação exclusiva para o cliente'
                breadcrumb={{
                    homeLink: '/',
                    items: [
                        { text: 'Perfil do cliente', href: `/customers/${params.uid!}` },
                        { text: 'Alocações', href: `/customers/${params.uid!}/allocations` },
                        { text: "Criando recomendação" }
                    ]
                }}
            />
            <div className='bg-neutral-0 w-full'>
                <div className='container mx-auto py-8'>
                    <CustomAllocationEntryForm
                        onSubmit={handleSubmit}
                    />
                </div>
            </div >
        </>
    )
}

export default AddCustomAllocationEntryView