import { AllocationCategory, AllocationProfile } from "@healthmoney/domain";
import { useEffect, useState } from "react";
import { useCategories, useGetAllocationProfile } from "../../../../repositories/allocations-repository";
import { useCustomerIncome, useInvestmentsSummary } from "../../../../repositories/customers-repository";
import WalletProfileChart from "components/containers/wallet-chart/wallet-profile-chart";
import WalletSummaryChart from "../../../../components/containers/wallet-chart/wallet-summary-chart";

export default function WalletComparison({ uid }: { uid: string }) {
    const [profile, setProfile] = useState<AllocationProfile | null>(null)
    const categories = useCategories()
    const income = useCustomerIncome(uid)
    const summary = useInvestmentsSummary(uid)
    const getAllocationProfile = useGetAllocationProfile()

    useEffect(() => {
        if (!income.data?.investorProfile) return
        getAllocationProfile(income.data!.investorProfile!).then(setProfile)
    }, [income.data])

    if (categories.status === 'loading' || income.status === 'loading')
        return <span>Carregando</span>

    if (income.data == null || income.data.investorProfile == null || profile == null)
        return <span>Usuário não tem uma perfil de carteira atribuido.</span>

    return (
        <div className="flex flex-row">
            <div className="flex-1 w-12 mr-4">
                <h3 className="text-lg font-bold mb-3">Carteira atual</h3>
                <WalletSummaryChart
                    profile={profile}
                    categories={categories.data as AllocationCategory[]}
                    summary={summary.data}
                />
            </div>
            <div className="flex-1 w-12 ml-4">
                <h3 className="text-lg font-bold mb-3">Carteira sugerida</h3>
                <WalletProfileChart
                    profile={profile}
                    categories={categories.data as AllocationCategory[]}
                />
            </div>
        </div>
    )
}