import { CustomerState } from '@healthmoney/domain'
import { collection, getDocs, getFirestore, updateDoc, orderBy, query, startAfter, doc, addDoc } from 'firebase/firestore'

export async function getCustomersState(startDocument: any = null): Promise<CustomerState[]> {
    const firestore = getFirestore()

    const reference = collection(firestore, 'app_customer_state')

    let q;

    if (!startDocument) {
        q = query(
            reference,
            orderBy('user.displayName'),
            // limit(2)
        )
    } else {
        q = query(
            reference,
            orderBy('user.displayName'),
            startAfter(startDocument),
            // limit(2)
        )
    }

    const documents = await getDocs(q)

    return documents.docs.map(doc => {
        const data = doc.data()
        return {
            ...data,
            generatedAt: data.generatedAt
                ? new Date(data.generatedAt.seconds * 1000).toLocaleString('pt-BR')
                : "",
        }
    }) as CustomerState[]
}

export async function forceStateGeneration(uid: string) {
    const firestore = getFirestore()

    const reference = doc(firestore, 'core_customers', uid, 'core_customers_details', 'default')

    await updateDoc(reference, { lastForceTriggered: new Date() });
}

async function _executePrivilegedOperation(uid: string, operation: string, params: any) {
    const firestore = getFirestore()

    const reference = collection(firestore, 'privileged_actions')

    await addDoc(reference, { createdBy: uid, operation, params });
}

export async function fullDeleteUser(adminId: string, uid: string) {
    return _executePrivilegedOperation(adminId, 'full-delete-user', { uid })
}

export async function sendPushNotification(uid: string, title: string, message: string) {
    const firestore = getFirestore()

    const reference = collection(firestore, 'comms')

    return addDoc(reference, {
        uid,
        title,
        message,
        processed: false,
        delivered: false,
        error: null,
        messageId: null,
        createdAt: new Date(),
        deliveredAt: null
    });
}