import { useRef, ElementRef, useState } from "react"

export default function useIsDragging() {
    const draggableRef = useRef<ElementRef<'div'>>(null)
    const [isDragging, setIsDragging] = useState(false)
    const [dragStart, setDragStart] = useState(0)

    const handleMouseMove = (e: React.MouseEvent) => {
        e.preventDefault()
        if (!isDragging || !draggableRef.current) return
        if (dragStart > e.clientX) draggableRef.current.scrollLeft += (dragStart - e.clientX)
        if (dragStart < e.clientX) draggableRef.current.scrollLeft -= (e.clientX - dragStart)
        setDragStart(e.clientX)
    }

    const handleMouseDown = (e: React.MouseEvent) => {
        setIsDragging(true)
        if (isDragging) return
        setDragStart(e.clientX)
    }

    const handleMouseUp = () => {
        setIsDragging(false)
        if (!isDragging) return
    }

    const handleMouseLeave = () => {
        setIsDragging(false)
        if (!isDragging) return
    }

    const isOverflowing = (draggableRef.current?.clientWidth! < draggableRef.current?.scrollWidth!) ?? false

    return { ref: draggableRef, handleMouseMove, handleMouseDown, handleMouseUp, handleMouseLeave, isDragging, isOverflowing }
}