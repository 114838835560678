import { Field, ErrorMessage } from 'formik';
import FieldGroup from './field_block'

type SelectFieldGroupOptionsProps = {
    name: string,
    label?: string,
    multiple?: boolean,
    children: React.ReactNode
    value: string | null,
    formik: any
}

export default function SelectFieldGroupOptions({
    name,
    label,
    children,
    multiple = false,
    value,
    formik
}: SelectFieldGroupOptionsProps) {
    return (
        <FieldGroup>
            <div className='flex flex-col flex-1'>
                <label
                    className='text-xxs'
                    htmlFor={name}>{label}</label>
                <Field
                    id={name}
                    name={name}
                    component="select"
                    multiple={multiple}
                    className='appearance-none border-b w-full py-1 text-neutral-800 focus:outline-none focus:shadow-outline text-xs'
                    value={value}
                    onChange={(value: any) => {
                        const [type, subtype] = value.target.value.split("-")
                        formik.setFieldValue("type", type);
                        formik.setFieldValue("subtype", subtype);
                    }}
                >
                    {children}
                </Field>
                <ErrorMessage name={name} component="span" className='text-danger-500 font-bold py-1' />
            </div>
        </FieldGroup>
    )
}
