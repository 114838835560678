import { PageHeading } from "components";
import AllocationEntryForm, { TAllocationValues } from "../containers/allocation-entry-form";
import { useCreateAllocationEntry } from "repositories/allocations-repository";
import { useNavigate } from "react-router-dom";
import { AllocationEntry, AllocationEntryActionType, AllocationEntryValueType, InvestorProfile, Scalars } from "@healthmoney/domain";
import { moment } from "helpers";

export type TValues = {
    categoryId: Scalars['String'];
    contributionDate: Scalars['DateTime'];
    description: Scalars['String'];
    name: Scalars['String'];
    recomendationDate: Scalars['DateTime'];
    investorProfile: { type: InvestorProfile, target: number }[]
}

export default function AddAllocationEntryView() {
    const navigate = useNavigate()
    const createAllocationEntry = useCreateAllocationEntry()

    const handleSubmit = async (entry: TAllocationValues) => {
        for (let profile of entry.investorProfile) {
            if (profile.target === 0) continue;
            const data = {
                ...entry,
                investorProfile: profile.type,
                target: parseFloat(profile.target.toString()),
                description: entry.description === '' ? null : entry.description,
                contributionDate: moment(entry.contributionDate).toISOString(),
                recomendationDate: moment(entry.recomendationDate).toISOString(),
                actionType: AllocationEntryActionType.Buy,
                valueType: AllocationEntryValueType.Percentage
            }

            await createAllocationEntry(data as AllocationEntry)
        }
        navigate('/admin/global-asset-allocation')
    }

    return (
        <>
            <PageHeading
                title='Nova recomendação'
                description='Cadastre uma nova recomendação de aporte'
                breadcrumb={{
                    homeLink: '/',
                    items: [
                        { text: 'Recomendações', href: '/admin/global-asset-allocation' },
                        { text: 'Nova recomendação' }
                    ]
                }} />
            <div className='bg-neutral-0 w-full'>
                <div className='container mx-auto py-8'>
                    <AllocationEntryForm
                        onSubmit={handleSubmit}
                    />
                </div>
            </div >
        </>
    )
}