import { ErrorMessage, FormikProps } from 'formik'
import FieldGroup from './field_block'
import classNames from 'classnames'
import { MDXEditor, MDXEditorMethods } from '@mdxeditor/editor'
import { UndoRedo, BoldItalicUnderlineToggles, CreateLink, linkPlugin, linkDialogPlugin, toolbarPlugin, markdownShortcutPlugin } from '@mdxeditor/editor'
import { ElementRef, useEffect, useRef } from 'react'

import '@mdxeditor/editor/style.css'

interface MDFieldProps {
    label?: string
    step?: number,
    name: string,
    formik: FormikProps<any>
}

const MDField = ({ formik, name, label }: MDFieldProps) => {

    const mdRef = useRef<MDXEditorMethods>(null)
    const field = formik.getFieldProps(name)

    return (
        <FieldGroup>
            <div className='flex flex-col flex-1 relative'>
                <label
                    className='text-xxs'
                    htmlFor={name}
                    onClick={() => mdRef.current?.focus()}
                >
                    {label}
                </label>
                <MDXEditor
                    ref={mdRef}
                    onChange={(value) => formik.setFieldValue(name, value)}
                    markdown={field.value}
                    plugins={[
                        linkPlugin(),
                        linkDialogPlugin(),
                        markdownShortcutPlugin({
                            bold: '**',
                            italic: '*',
                        }),
                        toolbarPlugin({
                            toolbarContents: () => (
                                <div className='flex items-center w-full'>
                                    <BoldItalicUnderlineToggles />
                                    <CreateLink />
                                    <div className='flex items-center ml-auto w-fit'>
                                        <UndoRedo />
                                    </div>
                                </div>
                            )
                        })
                    ]}
                    className={classNames(
                        '[&_.mdxeditor-toolbar]:rounded-t-sm [&_.mdxeditor-toolbar]:rounded-b-none [&_.mdxeditor-toolbar]:bg-neutral-200',
                        '[&_.mdxeditor-root-contenteditable]:border [&_.mdxeditor-root-contenteditable]:border-neutral-200',
                        '[&_.mdxeditor-root-contenteditable]:rounded-b-sm [&_.mdxeditor-root-contenteditable]:rounded-t-none',
                        '[&_.mdxeditor-root-contenteditable]:border-t-2 [&_.mdxeditor-root-contenteditable]:border-t-neutral-400'
                    )}
                />
            </div>
            <ErrorMessage
                name={name}
                component="span"
                className='text-danger-500 font-bold py-1' />
        </FieldGroup>
    )
}

type MDReadOnlyFieldProps = {
    value?: string | null,
    className?: React.ComponentProps<'div'>['className'];
    contentEditableClassName?: React.ComponentProps<'div'>['className'];
}

const MDReadOnlyField = ({ value, className, contentEditableClassName }: MDReadOnlyFieldProps) => {

    const ref = useRef<ElementRef<'div'>>(null)

    useEffect(() => {
        if (!ref.current) return
        const anchorTags = ref.current.querySelectorAll('a')
        anchorTags.forEach((anchor) => {
            anchor.setAttribute('target', '_blank')
            anchor.setAttribute('rel', 'noreferrer noopener')
            anchor.classList.add('font-bold')
        })
    }, [])

    return (
        <div
            ref={ref}
        >
            <MDXEditor
                markdown={value ?? ""}
                plugins={[linkPlugin({})]}
                readOnly
                className={className}
                contentEditableClassName={contentEditableClassName}
            />
        </div>
    )
}

export { MDReadOnlyField }

export default MDField
