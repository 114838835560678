import classNames from "classnames";
import { DropdownContext } from "./dropdown-menu";
import { useContext } from "react";
import { A } from "components";
import { ChevronDownIcon } from "@heroicons/react/24/outline";

type DropdownMenuItemProps = {
    title: string,
    items: {
        title: string,
        link: string
    }[]
}

export default function DropdownMenuItem({
    title,
    items = []
}: DropdownMenuItemProps) {
    const state = useContext(DropdownContext)

    const handleClick = () => {
        state.setItem(title === state.item ? null : title)
    }

    return (
        <div className="relative">
            <button
                className="overflow-hidden p-2 mr-2 flex flex-row justify-center items-center"
                onClick={handleClick}>
                {title} <ChevronDownIcon className="w-3 h-3 ml-2 stroke-neutral-900" />
            </button>
            <div
                className={classNames(
                    "absolute mt-2 py-4 bg-neutral-0 rounded-lg shadow z-30 flex flex-col w-max",
                    state.item === title ? 'block' : 'hidden'
                )}>
                {items.map((item, index) => (
                    <A key={index} href={item.link}
                        className="block py-2 px-4 hover:bg-neutral-200"
                        onClick={() => state.setItem(null)}>
                        {item.title}
                    </A>
                ))}
            </div>
        </div >
    )
}