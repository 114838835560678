import { Button } from "components";
import SuitabilityBanner from "../../income/components/suitability-banner";
import { useState } from "react";
import { setRegisterMaxStep, setRegisterSuitability } from "repositories/customers-repository";
import { InvestorProfile } from "@healthmoney/domain";
import { useLocation, useNavigate } from "react-router-dom";
import useRegistrationBoarding from "../useRegistrationBoarding";
import calculateInvestorProfileByScore from "../calculate-investor-profile-by-score";

export default function RegisterBoardingSuitability() {
    const navigate = useNavigate()

    const { user, uid, maxStep, profiles } = useRegistrationBoarding()
    const { pathname } = useLocation()

    const setSuitability = setRegisterSuitability(uid!)
    const setMaxStep = setRegisterMaxStep()

    const initialProfile = calculateInvestorProfileByScore(user?.suitability.score ?? 0)
    const [newProfile, setNewProfile] = useState<InvestorProfile | null>(user?.suitability?.investorProfile ?? initialProfile)

    const handleSubmit = async () => {
        const investorProfile = newProfile || user?.suitability?.investorProfile || InvestorProfile.Conservative
        const allocationProfileId = profiles?.find(p => p.investorProfile === investorProfile) || profiles![0]

        await setSuitability({ ...user?.suitability, investorProfile, allocationProfileId: allocationProfileId!.id! })
        await setMaxStep(user?.details?.email!, maxStep > 3 ? maxStep : 3)

        navigate(`/registration/${ uid }/projects`)
    }

    const handleProfileChange = (profile: string) => setNewProfile(profile as InvestorProfile)

    const buttonText = !pathname.includes("/registration") ?
        'Salvar' : maxStep > 2
            ? 'Salvar'
            : 'Próximo'

    return (
        <div className="container mx-auto max-w-screen-lg pt-8">
            <SuitabilityBanner
                profile={newProfile || user?.suitability?.investorProfile || InvestorProfile.Conservative}
                onProfileChanged={handleProfileChange}
                editable />
            <Button
                className='mt-9'
                onClick={handleSubmit}>
                {buttonText}
            </Button>
        </div>
    )
}