import LoginView from "./views/login-view";
import ProfileView from "./views/profile-view";

export const accountRoutes = [
    {
        path: '/login',
        element: <LoginView />,
    },
]

export const accountInternalRoutes = [
    {
        path: '/profile',
        element: <ProfileView />,
    },
]