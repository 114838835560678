import { useContext } from 'react';
import useStage from './use-stage';
import { SessionContext } from '../../../providers/session';
import { useBulkInsertSchedules, useDeleteSchedule, useSchedules } from '../../../repositories/schedules-repository';

export default function useSchedulesView() {
    const session = useContext(SessionContext)

    const {
        data
    } = useSchedules()

    const bulkInsertSchedules = useBulkInsertSchedules()
    const deleteSchedule = useDeleteSchedule()

    const {
        stage,
        stageFromParameters,
        removeItemFromStage,
        clearStage
    } = useStage(data)

    const schedules = [...data, ...stage]

    const persistStage = () => {
        return bulkInsertSchedules(
            stage.map(item => ({
                arranged: false,
                cid: session.email!,
                duration: 45,
                public: true,
                startAt: item.startAt
            }))
        )
    }

    return {
        schedules,
        stageFromParameters,
        removeItemFromStage,
        clearStage,
        deleteSchedule,
        persistStage
    }
}
