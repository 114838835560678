import { useState } from 'react'
import { Formik, Form } from 'formik'
import { CustomAllocationEntryForm as TCustomAllocationEntryForm, CustomAllocationCurrencyType } from '@healthmoney/domain';
import { Yup, moment } from 'helpers'
import { AllocationEntryActionType, Scalars } from '@healthmoney/domain'
import { Button, FieldGroup, MoneyField, SelectFieldOptions, TextField } from '../../../components';
import { useCategories } from 'repositories/allocations-repository';
import { TBaseValues } from './allocation-entry-form';
import DateField from 'components/inputs/date_field';

export type TCustomAllocationValues = TBaseValues & {
    target: Scalars['Float'];
    actionType: string
}

const AllocationEntrySchema = Yup.object().shape({
    name: Yup.string().required(),
    description: Yup.string(),
    recomendationDate: Yup.date().required(),
    contributionDate: Yup.date().required(),
    target: Yup.number().required().min(0.1),
    categoryId: Yup.string().required()
        .test("category", "Selecione uma categoria válida", val => val !== "default"),
    actionType: Yup.string().required(),
    currencyType: Yup.string().required().default("brl")
        .test("currency", "Selecione uma moeda válida", val => ["brl", "usd"].includes(val))
});

type CustomAllocationEntryFormProps = {
    initialValues?: TCustomAllocationEntryForm,
    onSubmit: (entry: TCustomAllocationEntryForm) => Promise<void>
}

export default function CustomAllocationEntryForm({
    initialValues = {
        name: '',
        description: '',
        recomendationDate: moment().toDate(),
        contributionDate: '',
        target: 0,
        categoryId: '',
        actionType: AllocationEntryActionType.Buy,
        currencyType: CustomAllocationCurrencyType.Brl,
    },
    onSubmit,
}: CustomAllocationEntryFormProps) {
    const categories = useCategories()
    const [error, setError] = useState<any>(null)

    const handleSubmit = async (values: TCustomAllocationEntryForm, { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }) => {
        try {
            setSubmitting(true)
            await onSubmit(values)
        } catch (error) {
            setError(error)
        } finally {
            setSubmitting(false)
        }
    }

    const categoriesOptions = [{ name: "--", id: "default" }, ...categories.data]
    const currencyTypeOprions = [{ text: "BRL", value: "brl" }, { text: "USD", value: "usd" }]

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={AllocationEntrySchema}
            onSubmit={handleSubmit}>
            {(formikProps) => (
                <Form>
                    <p className='pb-4 text-md'>Informações</p>
                    <FieldGroup>
                        <TextField
                            id='name'
                            label='Ativo'
                            name='name'
                            key='name'
                            type='text'
                        />
                        <SelectFieldOptions
                            label='Classe de produto'
                            name='categoryId'
                            options={categoriesOptions.map((category => ({
                                text: category.name as string,
                                value: category.id as string
                            })))}
                        />

                    </FieldGroup>
                    <TextField
                        id='description'
                        label='Descrição'
                        name='description'
                        key='description'
                        type='text'
                    />
                    <FieldGroup>
                        <DateField
                            id='recomendationDate'
                            label='Mês de recomendação'
                            name='recomendationDate'
                            key='recomendationDate'
                            formik={formikProps}
                            placeholder='mm/aa'
                            dateFormat='mm/yy'
                            view='month'
                        />
                        <DateField
                            id='contributionDate'
                            label='Aporte até dia'
                            name='contributionDate'
                            key='contributionDate'
                            formik={formikProps}
                            placeholder='dd/mm/aa'
                            dateFormat='dd/mm/yy'
                        />
                    </FieldGroup>
                    <FieldGroup>
                        <SelectFieldOptions
                            label='Moeda'
                            name='currencyType'
                            options={currencyTypeOprions}
                        />
                        <MoneyField
                            id='target'
                            label='Valor da operação'
                            name='target'
                            key='target'
                            step={0.1}
                            formik={formikProps}
                            currency={formikProps.getFieldProps('currencyType').value}
                        />
                    </FieldGroup>
                    <FieldGroup>
                        <SelectFieldOptions
                            label='Operação'
                            name='actionType'
                            options={[
                                { text: 'Compra', value: AllocationEntryActionType.Buy },
                                { text: 'Venda', value: AllocationEntryActionType.Sell }
                            ]}
                        />
                    </FieldGroup>
                    {error && (
                        <div className='py-4 font-bold'>
                            {error?.message}
                        </div>
                    )}

                    <div className='pt-3'>
                        <Button type='submit' className='btn-primary' disabled={formikProps.isSubmitting}>Salvar</Button>
                    </div>
                </Form>
            )
            }
        </Formik >
    )
}