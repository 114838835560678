import { Outlet } from "react-router-dom";
import FirebaseProvider from "../providers/firebase-provider";
import { SessionProvider } from "../providers/session";

export async function rootLoader() {
  return {}
}

function Root() {
  return (
    <FirebaseProvider>
      <SessionProvider>
        <Outlet />
      </SessionProvider>
    </FirebaseProvider>
  );
}

export default Root;
