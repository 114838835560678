import { useContext } from 'react'
import { Heading, Text, Button } from '../../../components'
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth'
import { SessionContext } from '../../../providers/session';
import { useNavigate, useLocation } from "react-router-dom";

export default function LoginView() {
    // const router = useRouter();
    // const pathname = usePathname();
    const session = useContext(SessionContext)
    const navigate = useNavigate()
    const location = useLocation()

    if (session.initialized
        && session.logged
        && location.pathname === '/login') {
        navigate('/')
        return <span>Redirecionando</span>
    }

    if (!session.initialized)
        return <span>Verificando autenticação...</span>

    const onGoogleLogin = async () => {
        const auth = getAuth();
        const provider = new GoogleAuthProvider();
        await signInWithPopup(auth, provider)
    }

    return (
        <div className='w-full h-full bg-neutral-0 flex flex-col justify-center'>
            <div className='p-6 rounded-xl shadow-md w-2/6 bg-neutral-50 mx-auto flex flex-col items-center'>
                <img src='/logo-preto.png'
                    alt='Logo da empresa'
                    className='py-4 w-2/5' />
                <div className='w-full'>
                    <Heading className='py-2'>Acesso do Consultor</Heading>
                    <Text className='pb-6'>Essa plataforma é de uso exclusivo dos consultores da HealthMoney, só é possível o acesso utilizando um e-mail do domínio da empresa.</Text>
                    <Button type='button' className='pb-4' onClick={onGoogleLogin}>
                        Entrar com Google
                    </Button>
                </div>
            </div>
        </div >
    )
}