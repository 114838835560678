import { Button, PageHeading } from "components"
import { useNavigate, useParams } from "react-router-dom"
import AddInvestmentForm, { EntriesFormType } from "./containers/add-investment-form"
import { useDeleteInvestment, useInvestment, useSetInvestment, useUpdateInvestment } from "repositories/customers-repository"
import Loading from "components/containers/loading"

const EditCustomerInvestmentView = () => {

    const params = useParams()
    const updateInvestment = useUpdateInvestment(params.uid!, params.iid!)
    const investmentProps = useInvestment(params.uid!, params.iid!)
    const addInvestment = useSetInvestment(params.uid!)
    const deleteInvestment = useDeleteInvestment(params.uid!, params.iid!)
    const navigate = useNavigate()

    const handleSubmit = async (investment: EntriesFormType) => {
        if (investment.balance === investmentProps.data.balance) {
            await updateInvestment(investment)
        } else {
            await addInvestment(investment)
            await updateInvestment({ ...investmentProps.data, archived: true })
        }
        navigate(`/customers/${params.uid}/investments`)

    }

    const handleDelete = async () => {
        await deleteInvestment()
        navigate(`/customers/${params.uid}/investments`)
    }

    const initialValues = {
        ...investmentProps?.data,
        provider: {
            ...investmentProps?.data?.provider,
            origin: investmentProps?.data?.provider?.origin ?? ''
        },
        rateType: investmentProps?.data?.rateType ?? ''
    }

    return (
        <>
            <PageHeading
                title="Editando investimento"
                description={`Edite um investimento`}
                breadcrumb={{
                    homeLink: '/',
                    items: [
                        { text: 'Perfil do cliente', href: `/customers/${params.uid}` },
                        { text: 'Investimentos', href: `/customers/${params.uid}/investments` },
                        { text: 'Editando um investimento' }
                    ]
                }}
            />
            <div className='bg-neutral-0 w-full'>
                <div className='container mx-auto py-8'>
                    <Loading isLoading={investmentProps.status !== "success"}>

                        <AddInvestmentForm
                            initialValues={initialValues as EntriesFormType}
                            onSubmit={handleSubmit}
                            handleDelete={handleDelete}
                        />
                    </Loading>
                </div>
            </div >
        </>
    )
}

export default EditCustomerInvestmentView
