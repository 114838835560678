import { HTMLAttributes } from "react"
import classNames from 'classnames'

export default function Heading(props: HTMLAttributes<any>) {
    const spanClass = classNames('block text-lg', props.className)

    return (
        <span {...props} className={spanClass}>
            {props.children}
        </span>
    )
}