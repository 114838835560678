import { FieldGroup, TextField, Button, SelectFieldOptions, MoneyField } from 'components';
import Loading from 'components/containers/loading';
import SelectFieldGroupOptions from 'components/inputs/select_field_group_options'; // ajustar import
import { Formik, Form, } from 'formik'
import { useState } from 'react';
import { useCategories } from 'repositories/allocations-repository';
import { useCoreConstantsCurrencies } from 'repositories/customers-repository';
import { Yup } from 'helpers'


const AddInvestmentFormSchema = Yup.object().shape({
    name: Yup.string().required(),
    classification: Yup.string().required().test("Invalid classification", 'Must be a valid classification', classification => classification !== "--"),
    balance: Yup.number().required().min(1),
    currencyCode: Yup.string().required().test('Invalid currency', 'Must be a valid currency', currency => currency !== "--"),
    code: Yup.string().required(),
    type: Yup.string().required()
        .test('Invalid Type', 'Must be a valid type', type => type !== "--"),
    provider: Yup.object().shape({
        origin: Yup.string().required()
    }),
    rateType: Yup.string()
});

export type EntriesFormType = {
    name: string,
    classification: string,
    balance: number,
    currencyCode: string,
    code: string,
    type: string,
    subtype: string,
    provider: {
        origin: string
    },
    rateType: string | null
}
type AddInvestmentFormProps = {
    initialValues?: EntriesFormType,
    onSubmit: (investment: EntriesFormType) => Promise<void>
    handleDelete?: () => Promise<void>
}

const investmentTypes = {
    FIXED_INCOME: ["CRI",
        "CRA",
        "LCI",
        "LCA",
        "LC",
        "TREASURY",
        "DEBENTURES",
        "CDB"
    ],
    SECURITY: [
        "RETIREMENT",
        "PGBL",
        "VGBL"
    ],
    MUTUAL_FUND: [
        "INVESTMENT_FUND",
        "STOCK_FUND",
        "MULTIMARKET_FUND",
        "EXCHANGE_FUND",
        "FIXED_INCOME_FUND",
        "FIP_FUND",
        "OFFSHORE_FUND",
        "ETF_FUND"
    ],
    EQUITY: [
        "STOCK",
        "BDR",
        "REAL_ESTATE_FUND",
        "DERIVATIVES",
        "OPTION"
    ],
    ETF: ["ETF"],
    COE: ["STRUCTURED_NOTE"],
    MUNIS: ["MUNIS"],
    REAL_ESTATE_CROWDFUNDING: ["REAL_ESTATE_CROWDFUNDING"],
    CRYPTO: ["CRYPTO"]
}

const AddInvestmentForm = ({
    initialValues = {
        name: "",
        classification: "",
        balance: 0,
        currencyCode: "",
        code: "",
        type: "--",
        provider: {
            origin: ""
        },
        subtype: "",
        rateType: ""
    },
    onSubmit,
    handleDelete
}: AddInvestmentFormProps) => {
    const categories = useCategories()
    const currencies = useCoreConstantsCurrencies()
    const [error, setError] = useState<any>(null)

    const handleSubmit = async (values: EntriesFormType, { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }) => {
        try {
            setSubmitting(true)
            await onSubmit(values)
        } catch (error) {
            setError(error)
        } finally {
            setSubmitting(false)
        }
    }

    const rates = ['--', ...(Object.keys((currencies?.data?.rates as string[]) || {}))?.sort()]
    const categoriesOptions = [{ name: "--", id: "default" }, ...categories.data]

    return (
        <>
            <Loading isLoading={currencies.status !== "success"}>
                <Formik
                    initialValues={initialValues}
                    validationSchema={AddInvestmentFormSchema}
                    onSubmit={handleSubmit}>
                    {(formikProps) => (
                        <Form>
                            <p className='pb-4 text-md'>Informações</p>
                            <FieldGroup>
                                <TextField
                                    id='name'
                                    label='Nome'
                                    name='name'
                                    key='name'
                                    type='text'
                                />
                                <SelectFieldOptions
                                    label='Classe de produto'
                                    name='classification'
                                    options={categoriesOptions.map(((category) => ({
                                        text: category.name as string,
                                        value: category.id as string
                                    })))}
                                />

                            </FieldGroup>
                            <FieldGroup>
                                <MoneyField
                                    id='balance'
                                    label='Balance'
                                    name='balance'
                                    key='balance'
                                    formik={formikProps}
                                />

                                <SelectFieldOptions
                                    label='Moeda'
                                    name='currencyCode'
                                    options={rates?.map((type: string) => ({
                                        text: type,
                                        value: type
                                    }))}
                                />
                            </FieldGroup>
                            <FieldGroup>
                                <TextField
                                    id='code'
                                    label='Código'
                                    name='code'
                                    key='code'
                                    type='text'
                                />
                                <SelectFieldGroupOptions
                                    label='Tipo'
                                    name='type'
                                    value={formikProps.values.type && formikProps.values.subtype ? `${formikProps.values.type}-${formikProps.values.subtype}` : '--'}
                                    formik={formikProps}
                                >
                                    <option disabled value={'--'}>--</option>
                                    {Object.entries(investmentTypes).map(([type, subtypes]: any, index) => (
                                        <optgroup label={type} key={index}>
                                            {subtypes.map((subtype: string) => (
                                                <option
                                                    key={subtype}
                                                    value={`${type}-${subtype}`}
                                                >
                                                    {subtype}
                                                </option>
                                            ))}
                                        </optgroup>
                                    ))}
                                </SelectFieldGroupOptions>
                            </FieldGroup>
                            <FieldGroup>
                                <TextField
                                    id='provider.origin'
                                    label='Provedor'
                                    name='provider.origin'
                                    key='provider.origin'
                                    type='text'
                                />
                                <TextField
                                    id='rateType'
                                    label='Rate'
                                    name='rateType'
                                    key='rateType'
                                    type='text'
                                />
                            </FieldGroup>

                            {error && (
                                <div className='py-4 font-bold'>
                                    {error?.message}
                                </div>
                            )}

                            <div className='pt-3 flex items-end gap-3'>
                                <Button type='submit' className='btn-primary' disabled={formikProps.isSubmitting}>Salvar</Button>
                                {handleDelete && (
                                    <Button
                                        secondary
                                        className='btn-primary'
                                        onClick={handleDelete}
                                    >
                                        Excluir
                                    </Button>
                                )}
                            </div>
                        </Form >
                    )}
                </Formik >
            </Loading >


        </>
    )
}

export default AddInvestmentForm
