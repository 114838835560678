import { AllocationCategory, AllocationProfile } from "@healthmoney/domain";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { Button } from "components";
import WalletProfileChart from "components/containers/wallet-chart/wallet-profile-chart";
import { useMemo, useState } from "react";

type AssetAllocationProps = {
    categories: AllocationCategory[]
    profiles: AllocationProfile[]
}

export default function AssetAllocation({
    categories,
    profiles,
}: AssetAllocationProps) {
    const [page, setPage] = useState(1)

    const {
        first,
        second
    } = useMemo(() => {
        const pageSize = 2;
        const startIndex = (page - 1) * pageSize;
        const endIndex = startIndex + 1;
        const first = profiles[startIndex]
        const second = profiles[endIndex]
        return {
            first, second
        }
    }, [page, profiles])

    return (
        <>
            <h2 className='font-bold text-md leading-compact'>Perfis de distribuição</h2>
            <div className='flex flex-row py-4'>
                <div className='flex-1 mr-5'>
                    {first && (
                        <WalletProfileChart
                            key={first.id}
                            categories={categories}
                            profile={first}
                            titleLink={`/admin/investor-profiles/${first.id}`}
                        />
                    )}
                </div>
                <div className='flex-1'>
                    {second && (
                        <WalletProfileChart
                            key={second.id}
                            categories={categories}
                            profile={second}
                            titleLink={`/admin/investor-profiles/${second.id}`}
                        />
                    )}
                </div>
            </div>
            <div className='pt-3 flex flex-row items-center'>
                {/* <A
                    href='#'
                    button
                    className='mr-2'>
                    Cadastrar novo perfil
                </A> */}
                <Button
                    secondary
                    className='mr-2'
                    disabled={page === 1}
                    onClick={() => {
                        setPage(page - 1)
                    }}>
                    <ChevronLeftIcon className='w-3 h-3 mr-1' />
                    Anterior
                </Button>
                <Button
                    secondary
                    disabled={page > (profiles.length / 2)}
                    onClick={() => {
                        setPage(page + 1)
                    }}>
                    Próximo
                    <ChevronRightIcon className='w-3 h-3 ml-' />
                </Button>
            </div>
        </>
    )
}