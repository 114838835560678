import { useParams } from "react-router-dom"
import CustomerBoard from "./customer-board"
import Projects from "./projects"
import Tasks from "./tasks"
import WalletComparison from "./wallet-comparison"
import { Breadcrumb } from "components"
import RetirementPlan from "./retirement-plan"

export default function CustomerDashboardView() {

    const params = useParams()

    return (
        <>
            <div className="bg-sand-50 pb-8 pt-7">
                <div className="container mx-auto">
                    <Breadcrumb
                        homeLink="/"
                        items={[
                            { text: 'Perfil do cliente' }
                        ]} />
                    <CustomerBoard uid={params.uid!} />
                </div>
            </div>
            <div className="bg-neutral 0 py-8">
                <div className="container mx-auto">
                    <Tasks uid={params.uid!} />
                </div>
            </div>
            <RetirementPlan uid={params.uid!} />
            <div className="bg-sand-50 py-8">
                <div className="container mx-auto">
                    <WalletComparison uid={params.uid!} />
                </div>
            </div>
            <div className="bg-neutral 0 py-8">
                <div className="container mx-auto">
                    <Projects uid={params.uid!} />
                </div>
            </div>
        </>

    )
}