import { useParams } from "react-router-dom"
import { PageHeading } from "../../../../components"
import { useAllocationProfile, useUpdateAllocationProfile } from "../../../../repositories/allocations-repository"
import AllocationProfileForm from "./allocation-profile-form"
import { AllocationProfile } from "@healthmoney/domain"

export default function EditAllocationProfileView() {
    const params = useParams()
    const profile = useAllocationProfile(params.id as string)
    const updateProfile = useUpdateAllocationProfile()

    if (!profile.data)
        return <span>Carregando...</span>

    return (
        <div>
            <PageHeading
                title="Novo perfil de investimento"
                description="Balanceie os valores para cada classe de produto"
            />
            <div>
                <div className="container mx-auto py-8">
                    <AllocationProfileForm
                        initialValues={profile.data as AllocationProfile}
                        onSubmit={(newValues) => updateProfile({
                            ...profile.data,
                            ...newValues
                        })} />
                </div>
            </div>
        </div>
    )
}