import PageHeading from "../../../components/containers/page-heading";
import Loading from "components/containers/loading";
import { useCategories, useInvestmentsStandards, useSetClassification } from "repositories/allocations-repository";
import { AllocationCategory, AllocationInvestment } from "@healthmoney/domain";
import { useMemo } from "react";
import AllocationsInvestmentsComponent from "./allocations-investments-component";

export default function AllocationsInvestmentsView() {
	const categories = useCategories();
	const investments = useInvestmentsStandards();
	const setClassification = useSetClassification()

	const categoryMap = useMemo(() => {
		return (categories.data || []).reduce((acc, p) => {
			acc[p.id] = p;
			return acc;
		}, {} as Record<string, AllocationCategory>);
	}, [categories.data]);

	const handleSetClassification = (investment: AllocationInvestment, classification: string) => {
		setClassification(investment.id!, classification).catch(alert)
	};

	return (
		<>
			<Loading isLoading={categories.status !== 'success' || investments.status !== 'success'}>
				<PageHeading
					title='Classificações'
					description='Visualize todos as classificações de investimentos'
				/>
				<div className='container mx-auto py-8'>
					<AllocationsInvestmentsComponent
						investments={investments.data || []}
						handleSetClassification={handleSetClassification}
						categories={categories.data || []}
						categoryMap={categoryMap}
						banksMap={[]}
					/>
				</div>
			</Loading>
		</>
	);
}
