import { Variants } from "framer-motion"

export const variants = {
  hidden: {
    opacity: 0,
    height: 0
  },
  visible: {
    opacity: 1,
    height: 'auto',
    transition: {
      opacity: {
        delay: 0.25,
        duration: 0.5,
      },
      height: {
        delay: 0.20,
        duration: 0.4
      }
    }
  },
} as Variants

export const priorityActions = {
  'exclusive-allocation': 1,
  'over-allocated': 2,
  'unclassified-investment': 3,
  'task-next-to-end-date': 4,
  'default': Infinity
} as const