import { useState } from 'react'
import { moment } from 'helpers'

import { Schedule } from '@healthmoney/domain'
import { expandDatesUntil } from '../../../helpers'

const weekDayText = {
    'Domingo': 0,
    'Segunda': 1,
    'Terça': 2,
    'Quarta': 3,
    'Quinta': 4,
    'Sexta': 5,
    'Sábado': 6,
}

type WeekDayKey = keyof typeof weekDayText

type StageFromParametersProps = {
    start: string,
    end: string,
    weekDays: string[],
    hour: string,
    minute: string,
}

type ScheduleExtensionProps = {
    stagged?: boolean
}

export type ScheduleWithExtensions = Schedule & ScheduleExtensionProps

export default function useStage(schedules: Schedule[]) {
    const [stage, setStage] = useState<ScheduleWithExtensions[]>([])

    const stageFromParameters = (parameters: StageFromParametersProps) => {
        const start = moment(parameters.start)
            .hour(parseInt(parameters.hour))
            .minute(parseInt(parameters.minute))

        const end = moment(parameters.end)
            .hour(23)
            .minute(59)

        const dates = expandDatesUntil(start, end)

        const newStage = dates
            .filter((date) => {
                const selectedWeekDays: number[] = parameters.weekDays
                    .map(w => weekDayText[w as WeekDayKey])
                return selectedWeekDays.includes(date.weekday())
            })
            .map((date): ScheduleWithExtensions => {
                return {
                    stagged: true,
                    arranged: false,
                    cid: '',
                    public: true,
                    startAt: date.toDate(),
                    duration: 45
                }
            })
            .filter((stagged) => {
                // we will compare with the schedules and the stage to prevent duplicates
                const found = [
                    ...schedules,
                    ...stage
                ].find((schedule) => {
                    const staggedStartAt = moment(stagged.startAt)
                    const scheduleStartAt = moment(schedule.startAt)
                    const isEqual = staggedStartAt.isSame(scheduleStartAt)
                    const isBetween = staggedStartAt.isBetween(
                        scheduleStartAt,
                        scheduleStartAt.clone().add(schedule.duration, 'minute')
                    )
                    return isEqual || isBetween
                })

                return !found
            })

        setStage([
            ...stage,
            ...newStage
        ])
    }

    const removeItemFromStage = (date: Date) => {
        const newStage = stage
            .filter(s => (s as ScheduleWithExtensions).stagged)
            .filter(s => !moment(s.startAt).isSame(moment(date)))

        setStage([
            ...newStage
        ])
    }

    const clearStage = () => {
        setStage([])
    }

    return {
        stage,
        stageFromParameters,
        removeItemFromStage,
        clearStage,
    }
}