import { Button, PageHeading, SelectFieldOptions, TextField } from 'components'
import Loading from 'components/containers/loading'
import { Form, Formik } from 'formik'
import { Yup } from 'helpers'
import { sendPushNotification } from 'repositories/admin-repository'
import { useCustomersDetails } from 'repositories/customers-repository'

const NotificationsSchema = Yup.object().shape({
    uid: Yup.string().required(),
    title: Yup.string().required(),
    message: Yup.string().required()
});

const Notifications = () => {

    const users = useCustomersDetails()

    return (
        <Loading isLoading={false}>
            <PageHeading
                title={'Enviar Notificação'}
                description='Ambiente para envio de notificações para clientes'
            />
            <Formik
                initialValues={{
                    uid: '',
                    title: '',
                    message: '',
                }}
                validationSchema={NotificationsSchema}
                onSubmit={(values, { resetForm, setSubmitting }) => {
                    setSubmitting(true)

                    sendPushNotification(
                        values.uid,
                        values.title,
                        values.message
                    ).then(() => {
                        alert('Push enviado, se o usuário estiver aceitado, ele receberá a notificação')
                        resetForm()
                        setSubmitting(false)
                    }).catch((e) => {
                        alert(e)
                        setSubmitting(false)
                    })
                }}>
                {({ isSubmitting, isValid }) => (
                    <Form>
                        <div className='container mx-auto w-full flex flex-col py-8'>
                            <TextField
                                id='title'
                                label='Título'
                                name='title'
                                key='title'
                                type='text'
                            />
                            <TextField
                                id='message'
                                label='Mensagem'
                                name='message'
                                key='message'
                                type='text'
                            />
                            <SelectFieldOptions
                                label='Usuário'
                                name='uid'
                                options={[
                                    {
                                        text: 'Selecionar usuário',
                                        value: ''
                                    },
                                    ...users.data.map((user => ({
                                        text: user.fullName!,
                                        value: user.uid!
                                    })))
                                ]}
                            />
                            <div className=' w-m-sm pt-4'>
                                <Button type='submit' disabled={isSubmitting || !isValid}>Enviar</Button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </Loading>
    )
}

export default Notifications