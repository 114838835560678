import { InvestorProfile } from "@healthmoney/domain";

// Mínimo: 0 ponto
// Máximo: 47,5 pontos
// 0 a 5 pontos: conservador
// 6 a 20 pontos: moderado
// 20 a 30 pontos: arrojado
// 30 a 40 pontos: agressivo
// 40 a 47,5 pontos: ultra-agressivo

export default function calculateInvestorProfileByScore(score: number): InvestorProfile {
    if (score <= 5) {
        return InvestorProfile.Conservative
    }
    else if (score <= 20) {
        return InvestorProfile.Moderate
    }
    else if (score <= 30) {
        return InvestorProfile.Bold
    }
    else if (score <= 40) {
        return InvestorProfile.Agressive
    } else {
        return InvestorProfile.Ultraaggressive
    }
}