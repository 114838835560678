import { moment } from 'helpers'
import { useState } from "react"

export default function useCalendar() {
    const [pivot, setPivot] = useState(moment())

    const nextMonth = () => {
        setPivot(
            pivot.clone().add(1, 'month')
        )
    }

    const previousMonth = () => {
        setPivot(
            pivot.clone().add(-1, 'month')
        )
    }

    const toToday = () => {
        setPivot(moment())
    }

    return {
        display: pivot.format('MMMM') + ' ' + pivot.year(),
        year: pivot.year(),
        month: pivot.month(),
        nextMonth,
        toToday,
        previousMonth,
    }
}