import RegisterBoardingDetails from '../steps/register-boarding-details'
import Loading from 'components/containers/loading'
import useRegistrationBoarding from '../useRegistrationBoarding'

const RegisterBoardingDetailsView = () => {

  const { loaded } = useRegistrationBoarding()

  return (
    <>
      <Loading isLoading={!loaded}>
        <RegisterBoardingDetails />
      </Loading>
    </>
  )
}

export default RegisterBoardingDetailsView