import { AllocationCategory, AllocationEntry } from "@healthmoney/domain";
import { Button } from "components";
import { investorProfileToText } from "helpers/constants";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useMemo } from "react";
import { useDeleteAllocationEntry } from "repositories/allocations-repository";
import { moment } from "helpers";

type AllocationsEntriesProps = {
    categories: AllocationCategory[]
    entries: AllocationEntry[]
    selectedInvestoProfile: string
    hideAdminActions?: boolean
}

export default function AllocationsEntries({
    categories = [],
    entries = [],
    selectedInvestoProfile,
    hideAdminActions = false
}: AllocationsEntriesProps) {

    const deleteEntry = useDeleteAllocationEntry()

    const handleDelete = async (entry: AllocationEntry) => {
        await deleteEntry(entry)
    }

    const withCategoryText = useMemo(() => {
        return entries.filter(entry =>
            selectedInvestoProfile === "" ? true : entry.investorProfile === selectedInvestoProfile
        ).map(entry => {
            const search = categories.filter(c => c.id === entry.categoryId)
            return {
                ...entry,
                categoryText: search.length > 0 ? search[0].name : '--',
                investorProfileText: investorProfileToText[entry.investorProfile],
                contributionDate: moment(entry.contributionDate.toDate()).format("DD/MM/YYYY"),
                recomendationDate: moment(entry.recomendationDate.toDate()).format("MM/YYYY"),
            }
        })
    }, [categories, entries, selectedInvestoProfile])

    return (
        <DataTable value={withCategoryText} tableStyle={{ minWidth: '50rem' }}>
            <Column field="name" header="Nome" sortable></Column>
            <Column field="target" header="Alvo" sortable body={(a) => a.target + '%'}></Column>
            <Column field="recomendationDate" header="Mês da indicação" sortable></Column>
            <Column field="contributionDate" header="Data da compra" sortable></Column>
            <Column field="investorProfileText" header="Perfil alvo" sortable></Column>
            <Column field="categoryText" header="Categoria" sortable></Column>
            {!hideAdminActions && (
                <Column
                    body={(item) => (
                        <div>
                            <Button secondary compact onClick={() => handleDelete(item)}>
                                Excluir
                            </Button>
                        </div>
                    )} />
            )}
        </DataTable>
    )
}