import { Button } from "../../../components"
import Calendar from "../../../components/containers/calendar/calendar"
import { CalendarNote } from "../../../components/containers/calendar/types"
import useCalendar from "./use-calendar"

export default function SchedulesCalendar({
    notes,
    onNoteClick,
}: {
    notes: CalendarNote[],
    onNoteClick: (note: CalendarNote) => void
}) {
    const {
        display,
        year,
        month,
        nextMonth,
        toToday,
        previousMonth,
    } = useCalendar()

    return (
        <div className="flex-1 flex flex-col">
            <div className="flex flex-row items-center py-2">
                <p className="ml-1">{display}</p>
                <div className="flex-1"></div>
                <Button secondary onClick={previousMonth}>Anterior</Button>
                <Button secondary className="ml-1" onClick={toToday}>Hoje</Button>
                <Button secondary className="ml-1" onClick={nextMonth}>Proximo</Button>
            </div>
            <Calendar
                year={year}
                month={month}
                notes={notes}
                onNoteClick={onNoteClick}
            />
        </div>
    )
}