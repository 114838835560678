import { Product } from '@healthmoney/domain'
import { collection, doc, getDoc, getDocs, getFirestore, query, where } from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import { useFunctions } from 'reactfire'

export async function getProducts() {
    const db = getFirestore()

    const reference = collection(db,
        'core_subscriptions',
        'default',
        'core_subscriptions_products'
    )

    const snapshot = await getDocs(reference)

    return snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })) as Product[]
}


export async function getProduct(uid: string) {
    const db = getFirestore()

    const productRef = doc(db, 'stripe_products', uid)

    const pricesRef = collection(db, 'stripe_products', uid, 'prices')

    const pricesQuery = query(
        pricesRef,
        where('active', '==', true)
    )

    const snapshotProduct = await getDoc(productRef)
    const snapshotPrices = await getDocs(pricesQuery)

    return {
        product: snapshotProduct.data(),
        prices: snapshotPrices.docs.map(doc => ({ ...doc.data(), id: doc.id }))
    }
}

export type RequestSubscriptionProps = {
    uid: string
    cid: string
    product: string
}

export function useRequestSubscription() {
    const functions = useFunctions()

    const handle = httpsCallable(functions, 'requestSubscription')

    return (productRequest: RequestSubscriptionProps) => {
        return handle(productRequest)
    }
}