import { FirebaseAppProvider, AuthProvider, FirestoreProvider, FunctionsProvider, useFirebaseApp } from 'reactfire';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import { useEffect } from 'react';

const firebaseConfig = {
    apiKey: "AIzaSyBBIpaGlBQdXjqB6gqdNmq9TFzcdh2TKE8",
    authDomain: "health-money.firebaseapp.com",
    databaseURL: "https://health-money.firebaseio.com",
    projectId: "health-money",
    storageBucket: "health-money.appspot.com",
    messagingSenderId: "645720965506",
    appId: "1:645720965506:web:ddba3ddb9cca57cfff4f9b",
    measurementId: "G-6Q1PG9H73Y"
}

function FirebaseProvider({ children }: { children: React.ReactNode }) {
    const app = useFirebaseApp()
    const auth = getAuth(app)
    const firestore = getFirestore(app)
    const functions = getFunctions(app)

    useEffect(() => {
        if (process.env.NODE_ENV !== 'production' && 
            process.env.REACT_APP_FORCE_PRODUCTION !== 'true') {
            connectAuthEmulator(auth, 'http://localhost:9099')
            connectFirestoreEmulator(firestore, 'localhost', 8080)
            connectFunctionsEmulator(functions, 'localhost', 5001)
        }

    }, [])

    return (
        <AuthProvider sdk={auth}>
            <FirestoreProvider sdk={firestore}>
                <FunctionsProvider sdk={functions}>
                    {children}
                </FunctionsProvider>
            </FirestoreProvider>
        </AuthProvider>
    );
}

function withFirebaseApp(Component: any) {
    return (props: { children: React.ReactNode }) => (
        <FirebaseAppProvider firebaseConfig={firebaseConfig}>
            <Component {...props} />
        </FirebaseAppProvider>
    )
}

export default withFirebaseApp(FirebaseProvider);