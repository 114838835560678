import { ErrorMessage, FormikProps } from 'formik';
import FieldGroup from './field_block'
import classNames from 'classnames';
import { Calendar, type CalendarProps } from 'primereact/calendar';
import { addLocale } from 'primereact/api';

addLocale('br', {
    firstDayOfWeek: 0,
    dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
    dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb', 'Dom'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S', 'D'],
    monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
    monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    today: 'Hoje',
    clear: 'Limpar',
});

interface DateFieldProps extends CalendarProps {
    label?: string
    disabled?: boolean
    name: string,
    formik: FormikProps<any>
}

function DateField(props: DateFieldProps) {

    const { label, formik, name } = props

    const date = formik.getFieldProps(name)

    return (
        <FieldGroup>
            <div className='flex flex-col flex-1'>
                <label
                    className='text-xxs'
                    htmlFor={name}>{label}</label>
                <Calendar
                    {...props}
                    inputId={name}
                    showButtonBar
                    locale='br'
                    showIcon
                    value={date.value}
                    dateFormat={props.dateFormat ?? "dd/mm/yy"}
                    placeholder={props.placeholder ?? 'dd/mm/aaaa'}
                    onChange={(e) =>
                        formik.setFieldValue(name, e.value)
                    }
                    className={classNames(
                        'appearance-none border-b w-full py-1 border-b-neutral-800',
                        'focus:outline-none focus:shadow-outline text-xs focus-visible:outline-none focus-visible:border-none',
                        '[&_.p-button.p-button-icon-only]:p-0 [&_.p-button.p-button-icon-only]:w-fit',
                        '[&_.p-button.p-button-icon-only]:bg-neutral-0 [&_.p-button.p-button-icon-only]:border-none',
                        '[&_.p-button.p-button-icon-only]:hover:bg-neutral-0',
                        '[&_.p-icon]:stroke-neutral-900'
                    )}
                    inputStyle={{
                        border: 'none', padding: 0, outline: '2px solid transparent',
                        boxShadow: 'none', opacity: 1, background: 'transparent'
                    }}
                    inputClassName='appearance-none border-b w-full py-1 border-b-neutral-800 focus:outline-none focus:shadow-outline text-xs focus-visible:outline-none focus-visible:border-none focus:shadow-none'
                />
            </div>
            <ErrorMessage
                name={name!}
                component="span"
                className='text-danger-500 font-bold py-1' />
        </FieldGroup>
    )
}

export default DateField