import Loading from "components/containers/loading"
import useRegistrationBoarding from "../useRegistrationBoarding"
import RegisterBoardingComplete from "../steps/register-boarding-complete"

const RegisterBoardingCompleteView = () => {

  const { loaded } = useRegistrationBoarding()

  return (
    <>
      <Loading isLoading={!loaded}>
        <RegisterBoardingComplete />
      </Loading>
    </>
  )
}

export default RegisterBoardingCompleteView