import IncomeForm from "../../income/income-form";
import { CustomerIncome } from "@healthmoney/domain";
import { setRegisterIncome, setRegisterMaxStep } from "repositories/customers-repository";
import { useNavigate } from "react-router-dom";
import useRegistrationBoarding from "../useRegistrationBoarding";

export default function RegisterBoardingIncome() {

    const { user, uid, maxStep } = useRegistrationBoarding()

    const navigate = useNavigate()
    const setIncome = setRegisterIncome(uid!)
    const setMaxStep = setRegisterMaxStep()


    const handleSubmit = async (income: CustomerIncome) => {

        const data: CustomerIncome = {
            incomeTaxReturnType: income.incomeTaxReturnType,
            occupationStatus: income.occupationStatus,
            occupationType: income.occupationType,
            occupation: income.occupation,
            montlySavingDay: income.montlySavingDay,
            montlySaving: income.montlySaving,
            expectedRetirementAge: income.expectedRetirementAge,
            desiredMontlyCompensation: income.desiredMontlyCompensation,
            compensation: income.compensation,
        }

        await setIncome(data)
        await setMaxStep(user?.details?.email!, maxStep > 2 ? maxStep : 2)

        navigate(`/registration/${ uid }/suitability`)
    }

    return (
        <div className="container mx-auto max-w-screen-lg">
            <IncomeForm
                initialValues={user?.income}
                onSubmit={handleSubmit}
            />
        </div>
    )
}