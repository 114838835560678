import AllocationsEntries from 'features/allocations/containers/allocation-entries'
import { A, PageHeading } from 'components'
import { useAllocationEntries, useCategories, useAllocationProfiles } from 'repositories/allocations-repository'
import Loading from 'components/containers/loading'
import { useMemo, useState } from 'react'
import { investorProfileToText } from "helpers/constants";
import { Checkbox } from 'primereact/checkbox';
import { moment } from 'helpers'

const AllocationsRecommendation = () => {
    const entries = useAllocationEntries()
    const categories = useCategories()
    const profiles = useAllocationProfiles()
    const [selectedInvestoProfile, setSelectInvestoProfile] = useState<string>('')
    const [showExpired, setShowExpired] = useState<boolean>(false)

    const notExpired = useMemo(() => {
        const today = moment();
        return entries.data?.filter(entry =>
            moment(entry.contributionDate.toDate()).add(1, 'day') > today
        )
    }, [entries.data])

    return (
        <>
            <Loading isLoading={profiles.status !== 'success'}>
                <PageHeading
                    title={'Recomendações Globais'}
                    description='Guia de alocação para clientes Start e Select'
                />
                <div className='container mx-auto w-full flex flex-col py-8'>
                    <div className='flex flex-row gap-4 items-center mb-4'>
                        <A href='/admin/global-asset-allocation/create'
                            button
                            buttonCompact
                            buttonSecondary
                        >
                            Cadastrar nova recomendação
                        </A>
                        <div>
                            <select
                                onChange={e => setSelectInvestoProfile(e.target.value)}
                                className='border rounded-md px-2 py-1'
                            >
                                <option value=""> -- </option>
                                {Object.entries(investorProfileToText).map(([key, val]) => (
                                    <option value={key} key={key}>{val}</option>
                                ))}
                            </select>
                        </div>
                        <div className='flex items-center gap-2'>
                            <div className="flex align-items-center gap-2">
                                <label htmlFor="expirados" className='select-none'>Mostrar expirados</label>
                                <Checkbox
                                    inputId="expirados"
                                    name="expirados"
                                    onChange={(e) => setShowExpired(e.target.checked!)}
                                    checked={showExpired} />
                            </div>

                        </div>
                    </div>
                    <AllocationsEntries
                        categories={categories.data}
                        entries={showExpired ? entries.data : notExpired}
                        selectedInvestoProfile={selectedInvestoProfile}
                    />
                </div>
            </Loading>
        </>
    )
}

export default AllocationsRecommendation
