import classNames from "classnames";
import { CalendarNote } from "./types";
import { Link } from "react-router-dom";
import { moment } from 'helpers'

export function CalendarItem({
    month,
    year,
    date,
    notes,
    onNoteClick,
}: {
    month: number,
    year: number,
    date: Date,
    notes: CalendarNote[],
    onNoteClick: (note: CalendarNote) => void
}) {
    return (
        <div className={classNames(
            'flex flex-col flex-1 w-6 border m-1 border-neutral-200 p-2'
        )}>
            <span className={classNames(
                "text-xs",
                date.getMonth() === month && date.getFullYear() === year ? ' ' : 'text-neutral-300',
                moment().isSame(moment(date), 'day') ? 'text-primary-500 font-bold' : ''
            )}>{date.getDate()}</span>
            {notes.map((note, i) => (
                <Link
                    key={i}
                    to={note.link! || '#'}
                    onClick={(e) => {
                        if (onNoteClick != null) {
                            e.preventDefault()
                            onNoteClick(note)
                        }
                    }}
                    className={classNames(
                        "my-1 p-1",
                        note.highlighted ? 'bg-primary-100' : 'bg-neutral-200'
                    )}>
                    <p className=" text-xs">{note.title}</p>
                    <p className=" text-xxs">{note.description}</p>
                </Link>
            ))}
        </div>
    )
}