import { HomeIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import classNames from "classnames";
import { A } from 'components';

export type BreadcrumbItem = {
    text: string
    href?: string
}

export type BreadcrumbProps = {
    homeLink: string,
    items: BreadcrumbItem[]
}

export default function Breadcrumb(props: BreadcrumbProps) {
    return (
        <div className="flex flex-row items-center">
            <div className={classNames(
                "text-neutral-900 text-xs",
            )}>
                <A href={props.homeLink}>
                    <HomeIcon className='w-4 h-4 stroke-neutral-700 stroke-2' />
                </A>
            </div>
            {props.items.map((item, index) => (
                <div key={index} className="flex flex-row items-center pl-2">
                    <ChevronRightIcon className='w-3 h-3' />
                    <div className={classNames(
                        "text-xs ml-2 font-bold",
                        index === props.items.length - 1 ? 'text-neutral-900' : 'text-neutral-700',
                        item.href ? 'underline' : ''
                    )}>
                        {item.href
                            ? (
                                <A href={item.href}>{item.text}</A>
                            )
                            : (
                                <span>{item.text}</span>
                            )
                        }
                    </div>
                </div>
            ))}
        </div>
    )
}