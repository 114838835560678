import { ConsultantPublic } from "@healthmoney/domain";
import { Button, FieldGroup, PageHeading, TextField } from "components";
import { Form, Formik } from "formik";
import { SessionContext } from "providers/session";
import { useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { sendProfileImage, useConsultantPublic, useSetConsultantPublic } from "repositories/consultant-repository";
import { validatePhone } from "validations-br";
import { Yup } from "helpers";
import parsePhoneNumber from "libphonenumber-js";

const ConsultantPublicSchema = Yup.object().shape({
	description: Yup.string().required(),
	displayName: Yup.string().required(),
	fullName: Yup.string().required(),
	phone: Yup.string().required().test("is-phone", "Telefone inválido", validatePhone),
});

type ConsultantPublicPartial = Pick<ConsultantPublic, "description" | "displayName" | "fullName" | "phone">;

export default function ProfileView() {
	const navigate = useNavigate();
	const session = useContext(SessionContext);
	const [imageProfile, setImageProfile] = useState<File | null>(null);
	const consultantPublic = useConsultantPublic(session.email!);
	const setConsultantPublic = useSetConsultantPublic();
	const tempAvatarUrl = useMemo(() => (imageProfile ? URL.createObjectURL(imageProfile!) : null), [imageProfile]);

	const handleImageProfileChange = (event: any) => {
		setImageProfile(event.currentTarget.files[0] as File);
	};

	const handleSubmitForm = async (values: ConsultantPublicPartial) => {
		let newPath = null;
		if (imageProfile !== null) {
			newPath = await sendProfileImage(session.email!, imageProfile!);
		}

		values.phone = parsePhoneNumber(values.phone!, "BR")?.number;

		const newConsultantPublic = {
			...consultantPublic.data,
			...values,
			cid: session.email,
			email: session.email,
			imageProfile: newPath || consultantPublic.data?.imageProfile || null,
		} as ConsultantPublic;

		await setConsultantPublic(newConsultantPublic);

		navigate("/");
	};

	if (consultantPublic.status !== "success") return <span>Carregando...</span>;

	const initalValues = {
		description: '',
		displayName: '',
		fullName: '',
		...consultantPublic?.data || {},
		phone: consultantPublic.data
			? parsePhoneNumber(consultantPublic.data?.phone!, "BR")?.nationalNumber ?? ""
			: "",
	};

	return (
		<>
			<PageHeading title='Perfil do consultor' description='Altere as informações públicas de seu perfil' />
			<div className='container mx-auto my-6'>
				<span className='text-md font-bold'>Informações</span>
				<Formik
					initialValues={initalValues as ConsultantPublicPartial}
					validationSchema={ConsultantPublicSchema}
					onSubmit={handleSubmitForm}>
					{(formikProps) => (
						<Form>
							<div className='flex flex-row pt-3'>
								<div className='flex-1'>
									<FieldGroup>
										<TextField key='displayName' name='displayName' label='Nome de exibição' type='text' />
										<TextField key='fullName' name='fullName' label='Nome completo' type='text' />
									</FieldGroup>
									<FieldGroup>
										<TextField key={"phone"} name='phone' label='Celular' type='text' />
									</FieldGroup>
									<FieldGroup>
										<TextField label='Descrição' as='textarea' key='description' name='description' />
									</FieldGroup>
									<FieldGroup>
										<input
											key='imageProfile'
											name='imageProfile'
											type='file'
											accept='.jpg'
											onChange={handleImageProfileChange}
										/>
									</FieldGroup>
								</div>
								{consultantPublic.data && (
									<div>
										<img
											className='w-12 p-4'
											src={tempAvatarUrl ? tempAvatarUrl : consultantPublic.data!.imageProfile!}
											alt='Avatar do consultor'
										/>
									</div>
								)}
							</div>
							<div className='flex flex-row py-4'>
								<Button type='submit' disabled={formikProps.isSubmitting}>
									Salvar
								</Button>
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</>
	);
}
