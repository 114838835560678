import { CustomerProject } from "@healthmoney/domain";
import { useNavigate } from "react-router-dom";
import ProjectForm from "../../projects/project-form";
import { setRegisterAddProject, setRegisterMaxStep } from "repositories/customers-repository";
import { useContext } from "react";
import { SessionContext } from "providers/session";
import useRegistrationBoarding from "../useRegistrationBoarding";
import { Timestamp } from 'firebase/firestore'


export default function RegisterBoardingProjects() {
    const { user, uid, maxStep } = useRegistrationBoarding()

    const session = useContext(SessionContext)
    const navigate = useNavigate()
    const addProject = setRegisterAddProject(uid!)
    const setMaxStep = setRegisterMaxStep()

    const handleSubmit = async (values: CustomerProject) => {

        const data = {
            cid: session.email,
            date: Timestamp.fromDate(values.date),
            description: values.description,
            term: values.term,
            title: values.title,
            uid,
            value: values.value,
        } as CustomerProject

        await addProject([...user?.projects ?? [], data])
    }

    const goNext = async () => {
        await setMaxStep(user?.details?.email!, maxStep > 4 ? maxStep : 4)
        navigate(`/registration/${uid}/cvv`)
    }


    return (
        <>
            <ProjectForm
                handleSubmit={handleSubmit}
                goNext={goNext}
            />
        </>

    )
}