import { ChevronRightIcon } from "@heroicons/react/24/solid";
import { TrashIcon } from "@heroicons/react/24/outline";
import { moment } from 'helpers'
import { useMemo, useState } from "react";
import { useDeleteProject, useProjects } from "../../../../repositories/customers-repository";
import { A, Button, TabItem } from "../../../../components";
import { formatCurrency } from "helpers";

export default function Projects({ uid }: { uid: string }) {

    const deleteProject = useDeleteProject()

    const createRoute = useMemo(() => `/customers/${uid}/projects/create`, [uid])

    const [index, setIndex] = useState(0)

    const {
        data
    } = useProjects(uid)

    const projects = useMemo(() => data?.filter(p => index === 0
        ? p.term === 'short'
        : p.term === 'long'
    ), [index, data]) || []

    if (!data) return <span>Carregando</span>

    return (
        <>
            <h3 className="text-lg font-bold mb-3">Projetos</h3>

            {data.length > 0
                ? (
                    <>
                        <div className='flex flex-row pb-6'>
                            <TabItem
                                text='Curto prazo'
                                active={index === 0}
                                onClick={() => setIndex(0)} />
                            <TabItem
                                text='Longo prazo'
                                active={index === 1}
                                onClick={() => setIndex(1)} />
                        </div>

                        <div className="pb-3 flex flex-wrap">
                            {projects.map((project, index) => (
                                <div key={index} className="flex flex-col shadow p-3 border border-neutral-200 w-3/12 mr-4 mb-3">
                                    <p className="text-sm font-bold mb-2">{project.title}</p>
                                    <div className="w-full pb-4">
                                        <p className="text-xxs">{project.description}</p>
                                    </div>
                                    <div className="flex flex-row">
                                        <div className="flex-1 flex flex-col">
                                            <p className="text-xxs">Data prevista</p>
                                            <p className="text-xxs font-bold">{moment(project.date.toDate()).format('DD/MM/YYYY')}</p>
                                        </div>
                                        <div className="flex-1 flex flex-col">
                                            <p className="text-xxs">Valor estimado</p>
                                            <p className="text-xxs font-bold">{formatCurrency(project.value)}</p>
                                        </div>
                                    </div>
                                    <div className="flex flex-row pt-3">
                                        {/* <Button compact className="mr-3 w-full">Editar</Button> */}
                                        <Button compact secondary onClick={(e) => {
                                            e.preventDefault()
                                            deleteProject(uid, project.id!)
                                        }}>
                                            <TrashIcon className="w-3 h-3" />
                                        </Button>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className="flex flex-row">
                            <A
                                button
                                buttonSecondary
                                buttonCompact
                                className="mr-3"
                                href={createRoute}>
                                Adicionar novo projeto
                            </A>
                        </div>
                    </>
                )
                : (
                    <div className="pb-1">
                        <span>Nenhum projeto cadastrado, </span>
                        <A highlighted href={createRoute}>
                            cadastrar
                            <ChevronRightIcon className="h-3 w-3 inline" />
                        </A>
                    </div>
                )}
        </>
    )
}