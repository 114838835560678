'use client'

import { useEffect } from 'react'
import { useFormikContext } from 'formik'
import { CustomerDetails } from '@healthmoney/domain';
import cep from 'cep-promise'

export default function CepObserver() {
    const { values, setValues } = useFormikContext<CustomerDetails>();

    useEffect(() => {
        if (!values.postalCode) return;
        const postalCode = values.postalCode.replaceAll('-', '')
        if (postalCode.length === 8) {
            cep(postalCode).then(result => {
                setValues({
                    ...values,
                    address1: result.street,
                    address3: result.neighborhood,
                    city: result.city,
                    state: result.state,
                })
            })

        }
    }, [values.postalCode]);

    return null;
}