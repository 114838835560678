import { PageHeading } from 'components'
import Loading from 'components/containers/loading'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { useConsultants } from 'repositories/consultant-repository'

const VisualizeAllConsultantsView = () => {
    const consultants = useConsultants()

    return (
        <Loading isLoading={consultants.status === "loading"}>
            <PageHeading
                title='Consultores'
                description='Lista de consultores cadastrados' />
            <section className="w-full bg-neutral-0 pb-4">
                <div className='container mx-auto flex flex-col pt-6 gap-4'>
                    <DataTable value={consultants?.data} tableStyle={{ minWidth: '50rem' }}>
                        <Column field='fullName' header="Nome de exibição" sortable filter />
                        <Column field='email' header="Nome de exibição" sortable filter />
                    </DataTable>
                </div>
            </section>
        </Loading>
    )
}

export default VisualizeAllConsultantsView
