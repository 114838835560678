import { AllocationProfile, AllocationCategory, AllocationStrategy } from '@healthmoney/domain'
import { useMemo } from 'react'

export type CategoryTotal = AllocationCategory & { total: number, expected: number }

export default function useWalletChart(
    profile: AllocationProfile,
    categories: AllocationCategory[],
) {
    // Helper to easy find correlation for each category
    const categoryMap = useMemo(() => {
        return categories.reduce((acc: any, c) => {
            acc[c.id] = c
            return acc
        }, {})
    }, [categories])

    // For each category, add extra properties calculating investment values
    const calculatedCategories = useMemo(() => {
        return (categories as CategoryTotal[])
            .map(category => {
                category.expected = profile.items.filter(i => i.modelId === category.id)[0]?.target || 0
                return category
            })
            .filter(c => c.expected > 0)
    }, [categories, profile.items])

    // Helper sun function
    const sunForType = (strategy: AllocationStrategy) => {
        return profile.items
            .filter(p => {
                const model = categoryMap[p.modelId] as AllocationCategory
                return model?.allocationStrategy === strategy
            })
            .map(p => p.target)
            .reduce((p, acc) => p + acc, 0)
    }

    return {
        calculatedCategories,
        preservationTotal: sunForType(AllocationStrategy.Preservation) || 0,
        accumulationTotal: sunForType(AllocationStrategy.Accumulation) || 0,
        growthTotal: sunForType(AllocationStrategy.Growth) || 0
    }
}