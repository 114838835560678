import React, { useContext } from 'react';
import { collectionGroup, collection, where, query } from 'firebase/firestore'
import { useFirestore, useFirestoreCollectionData } from 'reactfire'
import { SessionContext } from '../../../providers/session';
import { useAllocationProfiles, useInvestmentsStandards } from 'repositories/allocations-repository';
import { useGetUsersTasks } from 'repositories/customers-repository';
import { Action } from 'components/containers/actions-board';

export default function useDashboard() {
    const session = useContext(SessionContext)
    const db = useFirestore();

    const customers = useFirestoreCollectionData(
        query(
            collectionGroup(db, 'core_customers_details'),
            where('cid', '==', session.email)
        ), {}
    )

    const registers_app = useFirestoreCollectionData(
        query(
            collection(db, 'registration', 'default', 'registration_registers'),
            where('cid', '==', session.email),
            where('accountCreated', '!=', true)
        )
    )
    const registers_website = useFirestoreCollectionData(
        query(
            collection(db, 'registration', 'default', 'registration_state'),
            where('details.cid', '==', session.email),
            where('locked', '!=', true)
        )
    )

    const summaries = useFirestoreCollectionData(
        query(
            collectionGroup(db, 'core_customers_investments_summary'),
            where('cid', '==', session.email),
        )
    )

    const standards = useInvestmentsStandards()

    const usersTasks = useGetUsersTasks({ daysForCompletion: 2 })

    const tasks = useFirestoreCollectionData(
        query(
            collection(db, 'core_consultants', 'core_consultants_tasks', 'default'),
        )
    )

    const now = React.useMemo(() => new Date(), [])

    const range = React.useMemo(() => {
        const range = new Date()
        range.setDate(range.getDate() + 7)
        return range
    }, [])

    const schedules = useFirestoreCollectionData(
        query(
            collection(db, 'core_consultants', session.email!, 'core_consultants_schedules'),
            where('startAt', '>', now),
            where('startAt', '<', range),
            where('cid', '==', session.email!),
        )
    )

    const nextMeetingsCount = (schedules.data || []).length

    const profiles = useAllocationProfiles()

    return {
        customers: {
            ...customers,
            data: (customers.data || []).map(customer => {
                return {
                    ...customer,
                    createdAt: customer.createdAt.toDate()
                }
            })
        },
        standards: (standards?.data || []).filter(standard => !standard.categoryId),
        tasks: (tasks.data ?? []) as Action[] || [],
        registers: { app: { ...registers_app }, website: { ...registers_website } },
        plTotal: (summaries.data || []).reduce((c, v) => c + v.total, 0),
        nextMeetingsCount,
        profiles,
        usersTasks
    }
}