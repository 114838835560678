import { CustomerAccount, CustomerInvestmentSummary } from "@healthmoney/domain"
import { collectionGroup, query, where } from "firebase/firestore"
import { useFirestore, useFirestoreCollectionData } from "reactfire"

function useInvestmentsSummary() {
    const db = useFirestore()

    const data = useFirestoreCollectionData(
        collectionGroup(db, 'core_customers_investments_summary')
    )

    const summaries = (data.data || []) as CustomerInvestmentSummary[]

    return {
        isLoading: data.status === 'loading',
        summaries,
        fullAssetValue: summaries.reduce((acc, summary) =>
            acc + (summary?.total || 0.0), 0.0
        ),
    }
}

function useAccounts() {
    const db = useFirestore()

    const data = useFirestoreCollectionData(
        query(
            collectionGroup(db, 'core_customers_account'),
            where('accountStatus', '==', 'active')
        ))

    const accounts = (data.data || []) as CustomerAccount[]

    return {
        isLoading: data.status === 'loading',
        accounts,
        accountsTotal: accounts.length,
    }
}


function useAdminDashboard() {
    const summaries = useInvestmentsSummary()
    const accounts = useAccounts()

    return {
        ...summaries,
        ...accounts,
        isLoading: summaries.isLoading || accounts.isLoading,
    }
}

export default useAdminDashboard