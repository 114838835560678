
export const stepsTemplate = {
    'details': {
        title: 'Cadastro',
        description: 'Precisamos saber mais sobre você para oferecer a melhor gestão financeira. Vamos começar?',
        index: 0
    },
    'income': {
        title: 'Renda',
        description: '',
        index: 1

    },
    'suitability': {
        title: 'Perfil',
        description: 'Conforme o preenchimento feito anteriormente o seu perfil investidor é:',
        index: 2

    },
    'projects': {
        title: 'Projetos',
        description: 'Adicione até 5 projetos para os próximos 12 meses.',
        index: 3

    },
    'cvv': {
        title: 'Curva de Ciclo de Vida',
        content: (
            <div className="text-ls leading-far">
                Na Curva de Ciclo de Vida você visualizará o efeito dos juros 
                compostos sobre o seu patrimônio. O simulador facilitará a sua 
                tomada de decisões e te mostrará o caminho para atingir seus 
                objetivos de médio e longo prazos.
            </div>
        ),
        index: 4

    },
    'wallet': {
        title: 'Carteira',
        content: (
            <div className="text-ls leading-far">
                Reforçamos que a carteira de investimentos foi estruturada de 
                acordo com o perfil do investidor. Buscamos constantemente 
                estabelecer a melhor relação risco e retorno potencial.
                <br />
                <br />
                Enviaremos um Guidance mensal para execução da estratégia de alocação!
            </div>
        ),
        index: 5

    },
    'products': {
        title: 'Produtos',
        description: 'Produtos recomendados para você!',
        index: 6

    },
    'complete': {
        title: 'Cadastro finalizado com sucesso.',
        content: (
            <div className="text-ls leading-far">
                <p>Você receberá um e-mail com um link para a plataforma de pagamento da HealthMoney.</p>
                <p>Ao efetuar o pagamento, faremos uma segunda reunião para iniciar seus investimentos!</p>
            </div>
        ),
        index: 7

    }
} as Record<string, {
    title: string,
    description?: string,
    content?: React.ReactElement,
    index: number
}>


export const registerPages = {
    "details": {
        params: {
            maxStep: 0,
        },
        redirect: ''
    },
    "income": {
        params: {
            maxStep: 1
        },
        redirect: `/registration/uid/details`
    },
    "suitability": {
        params: {
            maxStep: 2
        },
        redirect: `/registration/uid/income`
    },
    "projects": {
        params: {
            maxStep: 3
        },
        redirect: `/registration/uid/suitability`
    },
    "cvv": {
        params: {
            maxStep: 4
        },
        redirect: `/registration/uid/projects`
    },
    "wallet": {
        params: {
            maxStep: 5
        },
        redirect: `/registration/uid/cvv`
    },
    "products": {
        params: {
            maxStep: 6
        },
        redirect: `/registration/uid/wallet`
    },
    "complete": {
        params: {
            maxStep: 7,
        },
        redirect: `/registration/uid/products`,
    },
} as Record<string, {
    params: {
        maxStep: number,
        done?: boolean
    },
    redirect: string
}>