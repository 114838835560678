import classNames from "classnames"

export default function WalletSummary({
    title,
    total,
    accentColor,
    items = []
}: {
    title: string,
    total: number
    accentColor: string
    items: any[],
}) {
    return (
        <div className='flex flex-row mb-3'>
            <div className='w-3'>
                <div className={classNames(
                    'h-full rounded w-2', accentColor
                )}></div>
            </div>
            <div className='flex-1'>
                <p className='text-sm font-bold'>
                    <span>{total.toFixed(2)}</span>
                    <span>% - {title}</span>
                </p>
                {items}
            </div>
        </div>
    )
}
