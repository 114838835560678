import { useContext } from 'react';
import { collection, where, query, doc, deleteDoc, addDoc, WithFieldValue, DocumentData, QueryDocumentSnapshot, SnapshotOptions } from 'firebase/firestore'
import { useFirestore, useFirestoreCollectionData } from 'reactfire'
import { Schedule } from '@healthmoney/domain';
import { SessionContext } from '../providers/session';

const scheduleConverter = {
    toFirestore(schedule: WithFieldValue<Schedule>): DocumentData {
        return schedule;
    },
    fromFirestore(
        snapshot: QueryDocumentSnapshot,
        options: SnapshotOptions
    ): Schedule {
        const data = snapshot.data(options)!;
        return {
            ...data,
            startAt: data.startAt?.toDate()
        } as Schedule;
    }
}

function useScheduleCollection() {
    const db = useFirestore()
    const session = useContext(SessionContext)
    return collection(db,
        'core_consultants',
        session.email!,
        'core_consultants_schedules'
    ).withConverter(scheduleConverter)
}

export function useSchedules() {
    const session = useContext(SessionContext)

    const scheduleCollection = useScheduleCollection()

    const observableStatus = useFirestoreCollectionData(
        query(
            scheduleCollection,
            where('cid', '==', session.email!)
        ),
        {
            idField: 'id',
            initialData: []
        }
    )

    return {
        ...observableStatus,
        data: observableStatus.data as Schedule[]
    }
}

export function useBulkInsertSchedules() {
    const scheduleCollection = useScheduleCollection()

    return async (schedules: Schedule[]) => {
        const job = schedules.map((schedule) => addDoc(scheduleCollection, schedule))

        await Promise.all(job)
    }
}

export function useDeleteSchedule() {
    const db = useFirestore()
    const session = useContext(SessionContext)

    return async (schedule: Schedule) => {
        const documentReference = doc(db,
            'core_consultants',
            session.email!,
            'core_consultants_schedules',
            schedule.id!,
        ).withConverter(scheduleConverter)

        await deleteDoc(documentReference)
    }
}
