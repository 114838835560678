import classNames from "classnames";
import A from "components/elements/a"
import { motion } from "framer-motion";
import useIsDragging from "./use-is-draggin";
import { priorityActions, variants, type Action } from "./";

type ActionsBoardProps = {
    actions?: Action[]
}

function ActionsBoard({ actions }: ActionsBoardProps) {

    const { ref, handleMouseDown, handleMouseMove, handleMouseUp, handleMouseLeave, isDragging, isOverflowing } = useIsDragging()

    if (actions?.length === 0) return null

    const sortedActions = actions?.sort((a, b) => priorityActions[a?.type ?? 'default'] ?? 0 - priorityActions[b?.type ?? 'default'])

    return (
        <>
            <motion.section
                variants={variants}
                initial='hidden'
                animate='visible'
                className='w-full bg-neutral-0 pb-4 grid'>
                <div className='container mx-auto pt-6 space-y-4 overflow-hidden'>
                    <h4 className='text-md leading-compact'>Avisos</h4>
                    <div
                        className={classNames("flex items-center gap-4 overflow-x-auto pb-4 px-4", {
                            'cursor-grab': !isDragging && isOverflowing,
                            'cursor-grabbing': isDragging && isOverflowing,
                            'cursor-default': !isOverflowing,
                        })}
                        ref={ref}
                        onMouseMove={handleMouseMove}
                        onMouseDown={handleMouseDown}
                        onMouseUp={handleMouseUp}
                        onMouseLeave={handleMouseLeave}>
                        {sortedActions?.map((action, index) => (
                            <ActionCard key={index} {...action}
                                type={action.type && priorityActions[action.type] ? action.type : "default"}
                            />
                        ))}
                    </div>
                </div>
            </motion.section>
        </>
    )
}

function ActionCard({ title, description, link: { href, text: linkText }, type }: Action) {
    return (
        <>
            <div
                className={
                    classNames(
                        'card shrink-0 rounded-xl text-neutral-50 max-w-[275px] w-full h-[275px] p-4',
                        'grid select-none', {
                        'bg-sand-500': type === 'over-allocated',
                        'bg-primary-700': type === 'unclassified-investment',
                        'bg-danger-700': type === 'exclusive-allocation',
                        'bg-success-700': type === 'task-next-to-end-date',
                        'bg-report-700': type === 'default',
                    }
                    )}
            >
                <div>
                    <h5 className='text-sm font-bold'>{title}</h5>
                    {description && <p className='text-xs'>{description}</p>}
                </div>
                <A href={href} className='max-w-xs hover:underline self-end'>
                    {linkText}
                </A>
            </div>
        </>
    )
}

export default ActionsBoard