"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TaskStatus = exports.StateAllocationGuideItemValueType = exports.StateAllocationGuideItemActionType = exports.RegistrationStatusType = exports.OccupationType = exports.OccupationStatus = exports.InvestorProfile = exports.IncomeTaxReturnType = exports.CustomerAccountStatus = exports.CustomAllocationCurrencyType = exports.AllocationStrategy = exports.AllocationEntryValueType = exports.AllocationEntryActionType = void 0;
var AllocationEntryActionType;
(function (AllocationEntryActionType) {
    AllocationEntryActionType["Buy"] = "buy";
    AllocationEntryActionType["Sell"] = "sell";
})(AllocationEntryActionType = exports.AllocationEntryActionType || (exports.AllocationEntryActionType = {}));
var AllocationEntryValueType;
(function (AllocationEntryValueType) {
    AllocationEntryValueType["Absolute"] = "absolute";
    AllocationEntryValueType["Percentage"] = "percentage";
})(AllocationEntryValueType = exports.AllocationEntryValueType || (exports.AllocationEntryValueType = {}));
var AllocationStrategy;
(function (AllocationStrategy) {
    AllocationStrategy["Accumulation"] = "accumulation";
    AllocationStrategy["Growth"] = "growth";
    AllocationStrategy["Preservation"] = "preservation";
})(AllocationStrategy = exports.AllocationStrategy || (exports.AllocationStrategy = {}));
var CustomAllocationCurrencyType;
(function (CustomAllocationCurrencyType) {
    CustomAllocationCurrencyType["Brl"] = "brl";
    CustomAllocationCurrencyType["Usd"] = "usd";
})(CustomAllocationCurrencyType = exports.CustomAllocationCurrencyType || (exports.CustomAllocationCurrencyType = {}));
var CustomerAccountStatus;
(function (CustomerAccountStatus) {
    CustomerAccountStatus["Active"] = "active";
    CustomerAccountStatus["Blocked"] = "blocked";
    CustomerAccountStatus["Canceled"] = "canceled";
    CustomerAccountStatus["Converted"] = "converted";
    CustomerAccountStatus["Created"] = "created";
    CustomerAccountStatus["Inactive"] = "inactive";
})(CustomerAccountStatus = exports.CustomerAccountStatus || (exports.CustomerAccountStatus = {}));
var IncomeTaxReturnType;
(function (IncomeTaxReturnType) {
    IncomeTaxReturnType["Complete"] = "Complete";
    IncomeTaxReturnType["Simple"] = "Simple";
})(IncomeTaxReturnType = exports.IncomeTaxReturnType || (exports.IncomeTaxReturnType = {}));
var InvestorProfile;
(function (InvestorProfile) {
    InvestorProfile["Agressive"] = "agressive";
    InvestorProfile["Bold"] = "bold";
    InvestorProfile["Conservative"] = "conservative";
    InvestorProfile["Moderate"] = "moderate";
    InvestorProfile["Ultraaggressive"] = "ultraaggressive";
})(InvestorProfile = exports.InvestorProfile || (exports.InvestorProfile = {}));
var OccupationStatus;
(function (OccupationStatus) {
    OccupationStatus["Active"] = "Active";
    OccupationStatus["Inactive"] = "Inactive";
    OccupationStatus["Retired"] = "Retired";
})(OccupationStatus = exports.OccupationStatus || (exports.OccupationStatus = {}));
var OccupationType;
(function (OccupationType) {
    OccupationType["Businessman"] = "Businessman";
    OccupationType["Clt"] = "CLT";
    OccupationType["LegalPerson"] = "LegalPerson";
    OccupationType["Unemployed"] = "Unemployed";
})(OccupationType = exports.OccupationType || (exports.OccupationType = {}));
var RegistrationStatusType;
(function (RegistrationStatusType) {
    RegistrationStatusType["AccountCreated"] = "ACCOUNT_CREATED";
    RegistrationStatusType["Created"] = "CREATED";
    RegistrationStatusType["Error"] = "ERROR";
    RegistrationStatusType["Subscribed"] = "SUBSCRIBED";
    RegistrationStatusType["WaitingPayment"] = "WAITING_PAYMENT";
})(RegistrationStatusType = exports.RegistrationStatusType || (exports.RegistrationStatusType = {}));
var StateAllocationGuideItemActionType;
(function (StateAllocationGuideItemActionType) {
    StateAllocationGuideItemActionType["Buy"] = "buy";
    StateAllocationGuideItemActionType["Sell"] = "sell";
})(StateAllocationGuideItemActionType = exports.StateAllocationGuideItemActionType || (exports.StateAllocationGuideItemActionType = {}));
var StateAllocationGuideItemValueType;
(function (StateAllocationGuideItemValueType) {
    StateAllocationGuideItemValueType["Absolute"] = "absolute";
    StateAllocationGuideItemValueType["Percentage"] = "percentage";
})(StateAllocationGuideItemValueType = exports.StateAllocationGuideItemValueType || (exports.StateAllocationGuideItemValueType = {}));
var TaskStatus;
(function (TaskStatus) {
    TaskStatus["Done"] = "done";
    TaskStatus["Pending"] = "pending";
    TaskStatus["Validating"] = "validating";
})(TaskStatus = exports.TaskStatus || (exports.TaskStatus = {}));
