
import { Form, Formik } from "formik";
import { baseToday, formatDateToInputValue } from "../../../helpers";
import { Button, NumberField, SelectField, TextField } from "../../../components";

export default function InserAssistantForm({
    stageFromParameters,
    onSaveAll,
    adding
}: {
    stageFromParameters: any,
    onSaveAll: () => Promise<void>,
    adding: boolean
}) {

    return (
        <div className="flex-1 pl-4">
            <Formik
                initialValues={{
                    start: formatDateToInputValue(),
                    end: formatDateToInputValue(baseToday(30)),
                    weekDays: [],
                    hour: '8',
                    minute: '0',
                }}
                onSubmit={(values) => {
                    stageFromParameters({
                        start: values.start,
                        end: values.end,
                        weekDays: values.weekDays,
                        hour: values.hour,
                        minute: values.minute,
                    })
                }}>
                {() => (
                    <Form>
                        <div className="flex flex-col">
                            <TextField
                                name='start'
                                label="Começando em"
                                type="date"
                            />
                            <TextField
                                name='end'
                                label="Até a data"
                                type="date"
                            />
                            <SelectField
                                name='weekDays'
                                label='Dias da semana'
                                multiple
                                values={[
                                    'Segunda',
                                    'Terça',
                                    'Quarta',
                                    'Quinta',
                                    'Sexta',
                                    'Sábado',
                                    'Domingo',
                                ]}
                            />
                            <div className="flex flex-row">
                                <NumberField
                                    name='hour'
                                    label="Hora de início"
                                    type="number"
                                    min="0"
                                    max="23"
                                />
                                <NumberField
                                    name='minute'
                                    label="Minuto de início"
                                    type="number"
                                    min="0"
                                    max="59"
                                />
                            </div>
                            <div className="mt-3">
                                <Button secondary type="submit">Adicionar</Button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
            <hr className="mt-5" />
            <div className="mt-3">
                <Button onClick={onSaveAll} disabled={adding}>
                    {adding ? 'Aguarde...' : 'Salvar atualizações'}
                </Button>
            </div>
        </div>
    )
}