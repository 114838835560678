import { AllocationCategory, CoreConstantsCurrenciesRates, CustomerInvestment } from "@healthmoney/domain";
import { useMemo } from "react";
import { useCategories } from "../../../../repositories/allocations-repository";
import { useBanks, useCoreConstantsCurrencies, useInvestments, useSetInvestmentClassification } from "../../../../repositories/customers-repository";
import { useParams } from "react-router-dom";
import { A, PageHeading } from "../../../../components";
import Loading from "components/containers/loading";
import TableExplorerComponent from "./table-explorer-component";

type CoreConstantsCurrenciesRatesKey = keyof CoreConstantsCurrenciesRates

function convertCurrency(investment: CustomerInvestment, rates: CoreConstantsCurrenciesRates): number {
    const currency = investment.currencyCode || 'BRL'
    const tax = (rates[currency as CoreConstantsCurrenciesRatesKey] || 1) as number
    return investment.balance / tax
}

export default function CustomerInvestmentsView() {
    const params = useParams()
    const categories = useCategories()
    const banks = useBanks(params.uid!)
    const investments = useInvestments(params.uid!)
    const currencies = useCoreConstantsCurrencies()
    const setInvestmentClassification = useSetInvestmentClassification(params.uid!)

    const categoryMap = useMemo(() => {
        return (categories.data || []).reduce((acc, p) => {
            acc[p.id] = p
            return acc
        }, {} as Record<string, AllocationCategory>)
    }, [categories.data])

    const banksMap = useMemo(() => {
        return (banks.data || []).reduce((acc, p) => {
            acc[p.id] = p
            return acc
        }, {} as Record<string, AllocationCategory>)
    }, [banks.data])

    const handleSetClassification = (investment: CustomerInvestment, classification: string) => {
        const search = categories.data.filter(c => c.name === classification)
        if (search.length === 0) return
        setInvestmentClassification(investment.id!, search[0].id)
    }
    return (
        <Loading isLoading={currencies.status !== 'success'}>
            <PageHeading
                title="Investimentos"
                description={`Patrimônio líquido acumulado do cliente`}
                breadcrumb={{
                    homeLink: '/',
                    items: [
                        { text: 'Perfil do cliente', href: `/customers/${params.uid}` },
                        { text: 'Editando investimentos do cliente' }
                    ]
                }}
            />
            <div className="container mx-auto py-8">
                <A
                    href={`/customers/${params.uid!}/investments/create`}
                    buttonCompact
                    buttonSecondary
                    button
                    className="max-w-fit mb-4">
                    Cadastrar novo investimento
                </A>
                <TableExplorerComponent
                    investments={investments.data || []}
                    handleSetClassification={handleSetClassification}
                    categories={categories.data || []}
                    categoryMap={categoryMap}
                    convertCurrency={convertCurrency}
                    currencies={currencies.data!}
                    banksMap={banksMap}
                    uid={params.uid}
                />
            </div>
        </Loading>
    )
}