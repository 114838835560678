import { collectionGroup, doc, or, query, setDoc, where } from 'firebase/firestore'
import { useFirestore, useFirestoreCollectionData, useFirestoreDocData } from 'reactfire'
import { ConsultantPublic, CustomerInvestmentSummary } from '@healthmoney/domain';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage'
import { moment } from 'helpers'

export function useConsultantPublic(cid: string) {
    const db = useFirestore()

    const reference = doc(db,
        'core_consultants',
        cid,
        'core_consultants_public',
        'default'
    )

    const result = useFirestoreDocData(
        reference, { idField: 'id' }
    )

    return {
        ...result,
        data: result.data as ConsultantPublic | null
    }
}

export function useSetConsultantPublic() {
    const db = useFirestore()

    return async (consultantPublic: ConsultantPublic) => {
        await setDoc(
            doc(db,
                'core_consultants',
                consultantPublic.cid,
                'core_consultants_public',
                'default'
            ),
            consultantPublic, {
            merge: true
        })
    }
}

export async function sendProfileImage(
    cid: string,
    file: File
): Promise<string> {
    const prefix = moment().format('YYYYMMDDHHmm')
    const path = `consultants/${cid}/avatars/${prefix}.jpg`
    const storage = getStorage();
    const storageRef = ref(storage, path);
    const uploadResult = await uploadBytes(storageRef, file)
    return getDownloadURL(uploadResult.ref)
}

export function useConsultants() {
    const db = useFirestore();

    const reference = collectionGroup(db, "core_consultants_public");

    const result = useFirestoreCollectionData(reference, { idField: "id" });

    return {
        ...result,
        data: result.data
    };
}

export function useOverAllocation() {
    const db = useFirestore();

    const reference = query(
        collectionGroup(db, "core_customers_investments_summary")
        , or(
            where("strategy.growth.isOverAllocated", "==", true),
            where("strategy.accumulation.isOverAllocated", "==", true)
        )
    )

    const userOverAllocated = useFirestoreCollectionData(reference, { idField: "id" });

    return {
        ...userOverAllocated,
        data: userOverAllocated.data as CustomerInvestmentSummary[]
    };

}