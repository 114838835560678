import RegisterBoardingCvvView from "./views/register-boarding-cvv-view";
import RegisterBoardingDetailsView from "./views/register-boarding-details-view";
import RegisterBoardingIncomeView from "./views/register-boarding-income-view";
import RegisterBoardingProductsView from "./views/register-boarding-products-view";
import RegisterBoardingProjectsView from "./views/register-boarding-projects-view";
import RegisterBoardingSuitabilityView from "./views/register-boarding-suitability-view";
import RegisterBoardingWalletView from "./views/register-boarding-wallet-view";
import { Outlet } from "react-router-dom";
import RegisterBoardingProvider from "./register-boarding-provider";
import RegisterPageHeading from "./register-page-heading";
import RegisterBoardingCompleteView from "./views/register-boarding-complete-view";

export const newCustomerRoutes = [
  {
    path: ':uid?/details',
    element: <RegisterBoardingDetailsView />,
  },
  {
    path: ':uid/income',
    element: <RegisterBoardingIncomeView />,
  },
  {
    path: ':uid/suitability',
    element: <RegisterBoardingSuitabilityView />
  },
  {
    path: ':uid/projects',
    element: <RegisterBoardingProjectsView />,
  },
  {
    path: ':uid/cvv',
    element: <RegisterBoardingCvvView />,
  },
  {
    path: ':uid/wallet',
    element: <RegisterBoardingWalletView />,
  },
  {
    path: ':uid/products',
    element: <RegisterBoardingProductsView />,
  },
  {
    path: ':uid/complete',
    element: <RegisterBoardingCompleteView />
  },
]

function RegistrationProvider() {

  return (
    <>
      <RegisterBoardingProvider>
        <>
          <RegisterPageHeading />
          <Outlet />
        </>
      </RegisterBoardingProvider>
    </>
  );
}

const CustomerRegistration = {
  path: "/registration",
  Component: RegistrationProvider,
  children: newCustomerRoutes
}

export default CustomerRegistration