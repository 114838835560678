import Loading from 'components/containers/loading'
import RegisterBoardingIncome from '../steps/register-boarding-income'
import useRegistrationBoarding from '../useRegistrationBoarding'

const RegisterBoardingIncomeView = () => {

  const { loaded } = useRegistrationBoarding()

  return (
    <>
      <Loading isLoading={!loaded}>
        <RegisterBoardingIncome />
      </Loading>
    </>
  )
}

export default RegisterBoardingIncomeView