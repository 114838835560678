import Modal from 'react-modal'
import { XMarkIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'

type AppModalProps = {
    isOpen: boolean
    contentLabel: string
    onClose?: () => void
    children: React.ReactElement
}

export default function AppModal({
    isOpen,
    contentLabel,
    onClose,
    children,
}: AppModalProps) {

    const styles = {
        overlay: {
            backgroundColor: 'rgba(32, 32, 32, 0.8)'
        },
        content: {
            backgroundColor: 'rgba(255, 255, 255, 1)'
        }
    }

    return (
        <Modal
            isOpen={isOpen}
            contentLabel={contentLabel}
            ariaHideApp={false}
            overlayClassName='bg-neutral-900 fixed bottom-0 bottom-0 right-0 top-0 left-0 flex flex-col justify-center items-center z-30'
            className={classNames(
                'w-1/2 bg-neutral-0 shadow-lg px-8  pb-8 z-40 rounded-xl',
                onClose ? 'pt-6' : 'pt-8'
            )} style={styles}>
            <div className='flex flex-col'>
                {onClose && (
                    <button className='ml-auto' onClick={onClose!}>
                        <XMarkIcon className='w-5 h-5' />
                    </button>
                )}
                <p className='text-md leading-compact font-bold mb-3 flex-1'>
                    {contentLabel}
                </p>
            </div>
            {children}
        </Modal >
    )
}