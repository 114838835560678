import { Button } from "components";
import { formatCurrency } from "helpers";
import useRegisterBoardingProducts from "./use-register-boarding-products";

export default function RegisterBoardingProducts() {

    const {
        products,
        requesting,
        handleRequestSubscription,
        error
    } = useRegisterBoardingProducts()

    return (
        <>
            <div className="container mx-auto py-8">
                {error && <p className="text-md leading-far my-3 flex-1 pb-3">{error!.message}</p>}

                <div className="flex flex-row justify-center">
                    {(products || []).map((product, index) => (
                        <div key={index} className="p-2 w-2/5">
                            <div className="p-6 shadow-lg flex flex-col h-full">
                                <p className="font-bold text-md leading-far mb-2">{product.title}</p>
                                {product.monthlyValue > 0
                                    ? (
                                        <p className="text-sand-700 font-bold text-lg leading-near">
                                            {formatCurrency(product.monthlyValue)}
                                        </p>
                                    )
                                    : (
                                        <div>
                                            <p >
                                                <span className="text-sand-700 font-bold text-lg leading-near">
                                                    {product.feePercentage}%{' '}
                                                </span>
                                                <span className="font-bold text-xs leading-near text-sand-700">
                                                    sobre o centro da faixa do milhão
                                                </span>
                                            </p>

                                        </div>
                                    )}
                                <p className="text-xs leading-near mt-2 font-bold">por mês</p>
                                {product.gracePeriod && (
                                    <p className="text-xxs leading-near mt-2">carência de {product.gracePeriod} meses</p>
                                )}
                                <p className="text-md leading-far my-3 flex-1 pb-3">{product.description}</p>
                                <Button
                                    disabled={requesting}
                                    className="mt-3"
                                    onClick={() => handleRequestSubscription(product)}>
                                    Assinar agora
                                </Button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}