import { AllocationProfile, CustomerRetirementPlan } from "@healthmoney/domain"
import { Button } from "components"
import { CalculateCCVParams } from "features/simulators/calculate-ccv"
import CCV, { type CCVResult } from "features/simulators/ccv"
import { moment } from 'helpers'
import { useEffect, useMemo, useState } from "react"
import { useGetAllocationProfile } from "repositories/allocations-repository"
import { useCustomerDetails, useCustomerIncome, useCustomerRetirementPlan, useSetRetirementPlan } from "repositories/customers-repository"

function RetirementPlan({ uid }: { uid: string }) {

    const detailsSnap = useCustomerDetails(uid)
    const incomeSnap = useCustomerIncome(uid)
    const retirementSnap = useCustomerRetirementPlan(uid)
    const [profile, setProfile] = useState<AllocationProfile | null>(null)
    const getAllocationProfile = useGetAllocationProfile()
    const updateRetirementPlan = useSetRetirementPlan()

    const details = detailsSnap.data
    const income = incomeSnap.data
    const retirementPlan = retirementSnap.data

    const [isRetirementPlanOpen, setIsRetirementPlanOpen] = useState(false)

    useEffect(() => {
        if (income?.investorProfile) {
            getAllocationProfile(income?.investorProfile).then(setProfile)
        }
    }, [income?.investorProfile])

    const info = useMemo(() => {
        if (!details || !income || !profile || !profile.anualIncomeRate) {
            return null
        }

        const age =
            moment().diff((details?.birthdate as any).toDate(), 'years', false)

        const props: CalculateCCVParams = {
            startEquity: retirementPlan?.startEquity ?? 0,
            anualIncomeRate: (profile!.anualIncomeRate) / 100,
            startYear: age,
            endYear: 90,
            contribution: retirementPlan?.contribution ?? (income?.montlySaving || 1000),
            retirementYear: retirementPlan?.retirementYear ?? parseInt(income?.expectedRetirementAge!),
        }

        return props
    }, [details, income, profile])

    const toggleIsRetirementPlanOpen = () => {
        setIsRetirementPlanOpen(!isRetirementPlanOpen)
    }

    const onNext = async (result: CCVResult) => {
        toggleIsRetirementPlanOpen();

        if (
            retirementPlan &&
            result.retirementYear === retirementPlan.retirementYear
            && result.contribution === retirementPlan.contribution
            && result.startEquity === retirementPlan.startEquity
        ) return;

        const data: CustomerRetirementPlan = {
            uid,
            id: 'default',
            cid: details.cid!,
            contribution: result.contribution,
            endYear: result.endYear,
            maximumAccumulatedWealth: result.maximumAccumulatedWealth,
            pontetialPassiveIncome: result.pontetialPassiveIncome,
            retirementYear: result.retirementYear,
            startEquity: result.startEquity,
            startYear: result.startYear,
            createdAt: retirementPlan?.createdAt || moment().toDate(),
            updatedAt: moment().toDate(),
        }

        await updateRetirementPlan(data);
    }

    if (!info) return null;

    return (
        <div className="bg-neutral 0 pb-4">
            <div className="container mx-auto">
                <h3 className="text-lg font-bold mb-3">Plano de aposentadoria</h3>
                <Button
                    onClick={toggleIsRetirementPlanOpen}
                    compact
                    secondary
                >
                    {isRetirementPlanOpen ? 'Fechar' : 'Editar'}
                </Button>
                <CCV {...info} onNext={onNext} readonly={!isRetirementPlanOpen} />
            </div>
        </div>
    )
}


export default RetirementPlan