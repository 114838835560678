import classNames from 'classnames';
import { Field } from 'formik';
import { InputHTMLAttributes } from 'react';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    id?: string
    label?: string
    value?: any
    defaultValue?: any
    as?: string
}

export default function FieldBase(props: InputProps) {
    return (
        <div className='flex flex-col flex-1'>
            <label
                className='text-xxs'
                htmlFor={props.name}>{props.label}</label>
            <Field
                {...props}
                className={classNames(
                    'appearance-none border-b w-full py-1',
                    'text-neutral-800 focus:outline-none focus:shadow-outline text-xs'
                )} />
        </div>
    )
}
