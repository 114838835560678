import RegisterBoardingWallet from '../steps/register-boarding-wallet'
import Loading from 'components/containers/loading'
import useRegistrationBoarding from '../useRegistrationBoarding'

const RegisterBoardingWalletView = () => {

  const { loaded } = useRegistrationBoarding()

  return (
    <>
      <Loading isLoading={!loaded}>
        <div className="container mx-auto max-w-screen-lg py-8">
          <RegisterBoardingWallet />
        </div>
      </Loading>
    </>
  )
}

export default RegisterBoardingWalletView