import { Field, ErrorMessage } from 'formik';
import FieldGroup from './field_block'

type TextFieldOptionsOption = {
    text: string,
    value: string,
}

type TextFieldOptionsProps = {
    name: string,
    label?: string,
    options: TextFieldOptionsOption[],
    multiple?: boolean
}

export default function TextFieldOptions({
    name,
    label,
    options,
    multiple = false
}: TextFieldOptionsProps) {
    return (
        <FieldGroup>
            <div className='flex flex-col flex-1'>
                <label
                    className='text-xxs'
                    htmlFor={name}>{label}</label>
                <Field
                    id={name}
                    name={name}
                    component="select"
                    multiple={multiple}
                    className='appearance-none border-b w-full py-1 text-neutral-800 focus:outline-none focus:shadow-outline text-xs'>
                    {options.map(option => <option key={option.value} value={option.value}>{option.text}</option>)}
                </Field>
                <ErrorMessage name={name} component="span" className='text-danger-500 font-bold py-1' />
            </div>
        </FieldGroup>
    )
}