import { ErrorMessage } from 'formik';
import FieldBase from './field_base'
import FieldGroup from './field_block'
import { InputHTMLAttributes } from 'react';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    label?: string
    as?: string
}

export default function TextField(props: Props) {
    return (
        <FieldGroup>
            <FieldBase {...props} />
            <ErrorMessage
                name={props.name!}
                component="span"
                className='text-danger-500 font-bold py-1' />
        </FieldGroup>
    )
}
