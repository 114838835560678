import { AllocationCategory, AllocationProfile, RegistrationRegister } from "@healthmoney/domain";
import { moment } from 'helpers'
import { createContext, useEffect } from "react";
import { useLocation, useNavigate, useParams, } from "react-router-dom";
import { useAllocationProfiles, useCategories } from "repositories/allocations-repository";
import { TUserRegister, useGetUserRegister, usePreRegistration } from "repositories/customers-repository";
import { registerPages } from "./constants";
import _ from "lodash";

export type TUserPrid = {
    fullName?: string,
    birthdate?: string,
    phone?: string,
    montlyValue?: number
    currentInvestiment?: number
    email?: string
}

type TUser = TUserRegister & RegistrationRegister

export const RegisterBoardingContext = createContext<RegisterBoardingState>({
    categories: [],
    loaded: false,
    location: '',
    uid: '',
    maxStep: 0,
    profiles: [],
})

export type RegisterBoardingState = {
    user?: TUser,
    categories: AllocationCategory[],
    profile?: AllocationProfile
    loaded: boolean,
    location: string,
    uid?: string,
    maxStep: number,
    profiles?: AllocationProfile[],
    processing?: boolean
}

export default function RegisterBoardingProvider({ children }: { children: React.ReactElement }) {

    const params = useParams()
    const location = useLocation()
    const userRegister = useGetUserRegister(params.uid)
    const userPrid = usePreRegistration(params.uid ?? "no-email-provied")

    const navigate = useNavigate()

    const profiles = useAllocationProfiles()
    const categories = useCategories()

    const profile =
        profiles.data.find(p => p.id === userRegister.data?.suitability?.allocationProfileId) || profiles.data[0]

    const resolvedLocation = location.pathname.split("/")[location.pathname.split("/").length - 1] as keyof typeof registerPages

    // Verificar se existe registro e ele esta preenchido, caso esteja vazio volta para details
    useEffect(() => {
        if (userRegister.status === "loading" || userPrid.status === 'loading') return

        if ((!userRegister.data || _.isEmpty(userRegister.data))
            && userRegister.status === "success"
            && (!userPrid.data || _.isEmpty(userPrid.data))
            && userPrid.status === "success")
            navigate(`/registration/details`)


    }, [userRegister.status, userPrid.status])

    // Verirficar sempre que a url mudar se os parametros do usuário permitem ele estar na página
    useEffect(() => {
        if (userRegister.status === "loading" || _.isEmpty(userRegister.data)) return

        if (userRegister.data?.locked)
            navigate(`/registration/${params?.uid}/complete`)

        const currMaxStep = userRegister.data?.maxStep || 0

        if (registerPages[resolvedLocation]
            && currMaxStep < registerPages[resolvedLocation].params.maxStep
        ) {
            const url = registerPages[resolvedLocation].redirect.replace("uid", params?.uid ?? "")
            navigate(url)
        }

    }, [location.pathname, userRegister.status])

    const newState: RegisterBoardingState = {
        user: {
            ...userRegister.data,
            details: userRegister.data?.details
                ? {
                    ...userRegister.data?.details
                }
                : {
                    fullName: userPrid.data?.user.name ?? "",
                    birthdate:
                        userPrid.data?.user.birthDate ?
                            moment(userPrid.data?.user.birthDate, "DD-MM-YYYY") : "",
                    phone: userPrid.data?.user.cellPhone ?? "",
                    montlyValue: userPrid.data?.user.montlyValue ?? "",
                    currentInvestiment: userPrid.data?.user.currentInvestiment ?? "",
                    email: userPrid.data?.user.email ?? "",
                },
            suitability: {
                ...userRegister.data?.suitability,
                ...userPrid.data?.suitability
            },
        } as unknown as TUser,
        profiles: profiles.data,
        profile,
        location: resolvedLocation,
        categories: categories.data,
        loaded:
            userRegister.status === "success"
            && userPrid.status === "success"
            && profiles.status === 'success'
            && categories.status === 'success',
        uid: params?.uid,
        maxStep: userRegister.data?.maxStep || 0,
        processing: userRegister.data?.locked
    }

    return (
        <RegisterBoardingContext.Provider value={newState}>
            {children}
        </RegisterBoardingContext.Provider>
    )
}