import React, { useContext, useMemo } from "react";
import { formatCurrency, formatToLongDate } from "../../../helpers";
import { A, TabItem } from "../../../components";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { AllocationInvestment, AllocationProfile, CustomerDetails, CustomerTask, RegistrationRegister } from "@healthmoney/domain";
import Loading from "components/containers/loading";
import { moment } from 'helpers'
import { SessionContext } from "providers/session";
import { TUserRegister } from "repositories/customers-repository";
import calculateInvestorProfileByScore from "features/customers/features/register-boarding/calculate-investor-profile-by-score";
import { useOverAllocation } from "repositories/consultant-repository";
import { ActionsBoard, type Action } from "components/containers/actions-board";
import { _renderOverAllocated, _renderStandards, _renderUsersTasks } from "./actions";
import useDashboard from "./use-dashboard";

type DashboardViewComponentProps = {
	isLoading: boolean;
	customers: CustomerDetails[];
	registers: { app: RegistrationRegister[], website: TUserRegister & RegistrationRegister[] };
	standards: AllocationInvestment[]
	plTotal: number;
	nextMeetingsCount: number;
	profiles: AllocationProfile[];
	tasks: Action[];
	usersTasks: ReturnType<typeof useDashboard>["usersTasks"]
};

export default function DashboardViewComponent({
	isLoading,
	customers,
	registers,
	standards,
	plTotal,
	nextMeetingsCount,
	profiles,
	tasks,
	usersTasks
}: DashboardViewComponentProps) {
	const session = useContext(SessionContext)
	const overAllocated = useOverAllocation()

	const [index, setIndex] = React.useState(0);

	const filteredCustomers = useMemo(() => {
		const cutDate = moment().add(-3, "months");
		return {
			regular: customers.filter((c) => moment(c.createdAt).isBefore(cutDate)),
			new: customers.filter((c) => moment(c.createdAt).isAfter(cutDate)),
		};
	}, [customers]);

	const actions = useMemo(() => {
		if (!session.isAdmin) return []

		const actionsTasks = []

		if (actionsTasks.length > 0) actionsTasks.push(...tasks)
		if (standards.length > 0) actionsTasks.push(_renderStandards(standards))
		if (overAllocated.data?.length > 0) actionsTasks.push(..._renderOverAllocated(overAllocated.data))
		if (usersTasks.length > 0) actionsTasks.push(..._renderUsersTasks(usersTasks.filter(task => task?.cid === session.email)))

		return actionsTasks

	}, [overAllocated, usersTasks, standards])

	return (
		<Loading isLoading={isLoading}>
			<section className='w-full bg-sand-50'>
				<div className='container mx-auto flex flex-col pt-5 pb-16'>
					<div className='flex flex-col md:flex-row'>
						<div className='flex-1 flex flex-col'>
							<h3 className='text-md leading-compact'>Meu dashboard</h3>
							<span className='text-xxs'>{formatToLongDate(new Date())}</span>
						</div>
						<div className='flex-0 flex flex-row items-center pt-4 md:pt-0'>
							<A button buttonCompact href='/registration/details' className='btn-primary ml-2 flex items-center'>
								Novo cadastro de cliente
							</A>
						</div>
					</div>
					<div className='w-full flex flex-col md:flex-row pt-5 pb-6'>
						<div className='card flex flex-col flex-1 mb-1 md:mb-0 md:mr-1 max-w-xl'>
							<span className='text-md leading-compact'>PL Total</span>
							<span className='text-xxl font-bold leading-far'>{formatCurrency(plTotal)}</span>
						</div>
						<div className='card flex flex-col flex-1 mt-1 md:mt-0 md:ml-1 max-w-lg'>
							<span>Próximas reuniões</span>
							<span className='font-bold'>{nextMeetingsCount} janelas de agendamento(s) nos próximos 7 dias</span>
							<A target='_blank' href='https://calendar.google.com/' className='max-w-xs mt-5'>
								Ver minha agenda
							</A>
						</div>
					</div>
				</div>
			</section>
			<ActionsBoard actions={actions} />
			<section className='w-full bg-neutral-0 pb-4'>
				<div className='container mx-auto pt-6 flex gap-4 items-start'>
					<h4 className='text-md leading-compact'>Lista de clientes</h4>
					{/* <div className='max-w-2xl'>
                        <Formik initialValues={{ search: '' }} onSubmit={() => { }}>
                            <TextField name='search' label='Buscar cliente' type='text' />
                        </Formik>
                    </div> */}
				</div>
			</section>
			<section className='w-full bg-neutral-0'>
				<div className='container mx-auto flex flex-col'>
					<div className='flex flex-row'>
						<TabItem
							text='Clientes regulares'
							badge={filteredCustomers.regular.length.toString()}
							active={index === 0}
							onClick={() => setIndex(0)}
						/>
						<TabItem
							text='Novos clientes'
							badge={filteredCustomers.new.length.toString()}
							active={index === 1}
							onClick={() => setIndex(1)}
						/>
						<TabItem
							text='Cadastros'
							badge={`${registers.app.length + registers.website.length}`}
							active={index === 2}
							onClick={() => setIndex(2)}
						/>
					</div>
					<div className='pt-4'>
						{index === 0 && (
							<DataTable value={filteredCustomers.regular} tableStyle={{ minWidth: "50rem" }}>
								<Column field='fullName' header='Nome completo' sortable filter />
								<Column field='phone' header='Telefone' sortable />
								<Column
									header='Ações'
									body={(customer) => (
										<A
											button
											buttonCompact
											buttonSecondary
											className='max-w-[150px]'
											href={`/customers/${customer.uid}`}>
											Visualizar perfil
										</A>
									)}
								/>
							</DataTable>
						)}

						{index === 1 && (
							<DataTable value={filteredCustomers.new} tableStyle={{ minWidth: "50rem" }}>
								<Column field='fullName' header='Nome completo' sortable filter />
								<Column field='phone' header='Telefone' sortable />
								<Column
									header='Ações'
									body={(customer) => (
										<A
											button
											buttonCompact
											buttonSecondary
											className='max-w-[150px]'
											href={`/customers/${customer.uid}`}>
											Visualizar perfil
										</A>
									)}
								/>
							</DataTable>
						)}

						{index === 2 && (
							<>
								<div className="mb-8 space-y-4">
									<h3 className="font-bold text-md">App</h3>
									<DataTable value={registers.app} tableStyle={{ minWidth: "50rem" }}>
										<Column field='user.name' header='Nome' sortable />
										<Column field='user.email' header='E-mail' sortable />
										<Column field='user.cellPhone' header='Celular' sortable />
										<Column
											field='suitability.score'
											header='Suitability'
											sortable
											body={(user: TUserRegister & RegistrationRegister) => scoreAppTemplate(user, profiles)}
										/>
										<Column
											field='user.currentInvestiment'
											header='Investimento'
											sortable
											body={investmentTemplate}
										/>
										<Column
											header='Ações'
											body={(register) => (
												<A
													button
													buttonCompact
													buttonSecondary
													className='max-w-[180px]'
													href={`/registration/${register.user.email}/details`}>
													Continuar cadastro
												</A>
											)}
										/>
									</DataTable>
								</div>
								<div className="mb-8 space-y-4">
									<h3 className="font-bold text-md">Cadastro Manual</h3>
									<DataTable value={registers.website} tableStyle={{ minWidth: "50rem" }}>
										<Column field='details.fullName' header='Nome' sortable />
										<Column field='details.email' header='E-mail' sortable />
										<Column field='details.phone' header='Celular' sortable />
										<Column
											field='createdAt'
											header='Data Cadastro'
											sortable
											body={createdAtTemplate}
										/>
										<Column
											header='Ações'
											rowSpan={2}
											body={(register) => (
												<A
													button
													buttonCompact
													buttonSecondary
													className='max-w-[180px]'
													href={`/registration/${register.details.email}/details`}>
													Continuar cadastro
												</A>
											)}
										/>
									</DataTable>
								</div>
							</>
						)}
					</div>
				</div>
			</section>
		</Loading>
	);
}


const createdAtTemplate = (user: TUserRegister & RegistrationRegister) => moment(user.createdAt?.toDate()).format('DD-MM-YYYY')
const scoreAppTemplate = (user: TUserRegister & RegistrationRegister, profiles: AllocationProfile[]) =>
	profiles.find(p => p.investorProfile === calculateInvestorProfileByScore(user.suitability.score!))?.name ?? profiles[0].name
const investmentTemplate = (user: TUserRegister & RegistrationRegister) => {
	switch (user.user.currentInvestiment) {
		case 1:
			return 'Até R$ 80.000,00'
		case 2:
			return 'Entre R$ 80.000,00 e R$ 300.000,00'
		case 3:
			return 'R$ 300.000,00 ou mais'
		default:
			return 'Até R$ 80.000,00'
	}
}