import { AllocationInvestment, CustomerInvestmentSummary } from "@healthmoney/domain"
import { Action } from "components/containers/actions-board"
import { moment } from "helpers"
import useDashboard from "./use-dashboard"

function _renderStandards(standards: AllocationInvestment[]): Action {
  const singular = standards.length === 1

  return {
    title: 'Classificações pendentes',
    description:
      `Atualmente ${singular ? 'existe' : 'existem'} ${standards.length} ${singular ? 'classificação pendente' : 'classificações pendentes'} de ativos.`,
    link: {
      text: 'Clique aqui para classificar ativos',
      href: '/admin/unclassified-assets'
    },
    type: 'unclassified-investment'
  }
}
function _renderOverAllocated(overAllocated: CustomerInvestmentSummary[]): Action[] {
  return overAllocated?.map(client => ({
    title: `Carteira acima do recomendado`,
    description: `O cliente ${client.fullName} está com alocação acima do recomendado. Ajuste a carteira para evitar riscos.`,
    link: {
      href: `/customers/${client.uid}`,
      text: 'Visualizar perfil'
    },
    type: 'over-allocated'
  }))
}
function _renderUsersTasks(usersTasks: ReturnType<typeof useDashboard>["usersTasks"]): Action[] {
  return usersTasks.map(task => {

    const daysForCompletion = moment(task.dateForCompletion.toDate()).diff(moment().startOf('day'), 'days')

    let description = `Tarefa vencerá dentro de ${String(daysForCompletion).padStart(2, '0')} dias`

    if (daysForCompletion === 0) description = `Tarefa vencerá hoje as ${moment(task.dateForCompletion.toDate()).format("HH:mm")}`

    return ({
      title: 'Gestão de Tarefas e Projetos',
      description: description,
      link: {
        href: `/customers/${task.uid}`,
        text: 'Visualizar tarefas do usuário'
      },
      type: 'task-next-to-end-date'
    })
  })
}

export { _renderOverAllocated, _renderStandards, _renderUsersTasks }
