import { PageHeading } from "components"
import { useNavigate, useParams } from "react-router-dom"
import AddInvestmentForm, { EntriesFormType } from "./containers/add-investment-form"
import { useSetInvestment } from "repositories/customers-repository"
import { SessionContext } from "providers/session"
import { useContext } from "react"

const CreateCustomerInvestmentView = () => {

    const params = useParams()
    const setInvestment = useSetInvestment(params.uid!)
    const session = useContext(SessionContext);
    const navigate = useNavigate()

    const handleSubmit = async (investment: EntriesFormType) => {
        const data = {
            ...investment,
            amount: null,
            amountOriginal: null,
            amountProfit: null,
            amountRate: null,
            archived: false,
            cid: session.email,
            issuer: null,
            issuerCNPJ: null,
            lastMonthRate: null,
            lastTwelveMonthsRate: null,
            number: null,
            provider: {
                name: "manual",
                itemId: null,
                investmentId: null,
                originLogo: null,
                origin: investment.provider.origin
            },
            quantity: null,
            uid: params.uid,
            value: null,
        }
        await setInvestment(data)
        navigate(`/customers/${params.uid}/investments`)
    }

    return (
        <>
            <PageHeading
                title="Cadastrando investimento"
                description={`Cadastre um novo investimento`}
                breadcrumb={{
                    homeLink: '/',
                    items: [
                        { text: 'Perfil do cliente', href: `/customers/${params.uid}` },
                        { text: 'Investimentos', href: `/customers/${params.uid}/investments` },
                        { text: 'Cadastrando um novo investimento' }
                    ]
                }}
            />
            <div className='bg-neutral-0 w-full'>
                <div className='container mx-auto py-8'>
                    <AddInvestmentForm
                        onSubmit={handleSubmit}
                    />
                </div>
            </div >
        </>
    )
}

export default CreateCustomerInvestmentView
