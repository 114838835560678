export default function RegisterBoardingView() {

    return (
        <div>
            {/* <PageHeading
                shorter
                title={stepsTemplate[step].title}
                description={stepsTemplate[step].description}
                content={stepsTemplate[step].content}
                breadcrumb={{ homeLink: '/', items: [{ text: 'Continuar cadastro' }] }}
                stepper={{
                    currentIndex: step,
                    items: [
                        { title: 'Cadastro' },
                        { title: 'Renda' },
                        { title: 'Perfil' },
                        { title: 'Projetos' },
                        { title: 'CCV' },
                        { title: 'Carteira' },
                        { title: 'Produtos' },
                    ]
                }}
            />
            {step === 0 && (
                <RegisterBoardingDetails />
            )}
            {step === 1 && <RegisterBoardingIncome />}
            {step === 2 && <RegisterBoardingSuitability />}
            {step === 3 && <RegisterBoardingProjects />}
            {step === 4 && <RegisterBoardingCVV />}
            {step === 5 && <RegisterBoardingWallet />}
            {step === 6 && <RegisterBoardingProducts />}
            {step === 7 && <RegisterBoardingComplete />} */}
            {/* <PageHeading
                shorter
                title={stepsTemplate[step].title}
                description={stepsTemplate[step].description}
                content={stepsTemplate[step].content}
                breadcrumb={{homeLink: '/', items: [{text: 'Continuar cadastro'}]}}
                stepper={{
                    currentIndex: step,
                    items: [
                        {title: 'Cadastro'},
                        {title: 'Renda'},
                        {title: 'Perfil'},
                        {title: 'Projetos'},
                        {title: 'CCV'},
                        {title: 'Carteira'},
                        {title: 'Produtos'},
                    ]
                }}
            /> */}
            <p>oi</p>
        </div>
    )
}