import { AllocationCategory, AllocationStrategy, CustomerInvestmentSummary } from "@healthmoney/domain"

export default function useWalletSummaryChart(
    categories: AllocationCategory[],
    summary: CustomerInvestmentSummary,
) {
    const strategy = summary?.strategy || {}
    const preservation = strategy.preservation?.proportion || 0
    const accumulation = strategy.accumulation?.proportion || 0
    const growth = strategy.growth?.proportion || 0
    const notAssigned = 100 - (preservation + accumulation + growth)


    const items = (allocationStrategy: AllocationStrategy) => {
        const item = strategy[allocationStrategy]

        if(!item)
            return []

        return categories
            .filter(category => category.allocationStrategy === allocationStrategy)
            .map(category => {
                const summaryCategory = item.categories?.find(item => item?.category === category.id)

                if (summaryCategory?.proportion === 0) {
                    return null
                }

                return { name: category.name, value: summaryCategory?.proportion || 0 }
            })
            .filter(item => item !== null)
    }

    return {
        preservation: {
            total: preservation,
            items: items(AllocationStrategy.Preservation)
        },
        accumulation: {
            total: accumulation,
            items: items(AllocationStrategy.Accumulation)
        },
        growth: {
            total: growth,
            items: items(AllocationStrategy.Growth)
        },
        notAssigned,
        items
    }
}