import { AllocationProfile, AllocationCategory, AllocationStrategy } from '@healthmoney/domain'
import { A } from '../../elements'
import { investorProfileToText } from 'helpers/constants'
import WalletChartComponent from './wallet-chart-component'
import WalletSummary from './wallet-summary'
import useWalletChart from './use-wallet-chart'

interface WalletProfileChartProps {
    profile: AllocationProfile,
    categories: AllocationCategory[],
    showAllCategories?: boolean
    titleLink?: string
}

export default function WalletProfileChart({
    profile,
    categories = [],
    titleLink
}: WalletProfileChartProps) {

    const {
        calculatedCategories,
        preservationTotal,
        accumulationTotal,
        growthTotal,
    } = useWalletChart(profile, categories)

    const renderItems = (category: any) => {
        return (
            <p className='text-xxxs' key={category.name}>
                <span>{category.expected.toFixed(2)}</span>
                <span>% - </span>
                <span>{category.name}</span>
            </p>
        )
    }

    const values = []

    if (preservationTotal > 0)
        values.push({ name: 'Preservação', value: preservationTotal })

    if (accumulationTotal > 0)
        values.push({ name: 'Acumulação', value: accumulationTotal })

    if (growthTotal > 0)
        values.push({ name: 'Crescimento', value: growthTotal })

    return (
        <div className='bg-neutral-0 shadow border border-neutral-200 p-6'>
            <div className='pb-3'>
                {titleLink
                    ? (
                        <A href={titleLink}>
                            <p className='text-sm font-bold'>{profile.name}</p>
                        </A>
                    )
                    : (
                        <p className='text-sm font-bold'>{profile.name}</p>
                    )}
                <p className='text-xxxs text-neutral-400'>{investorProfileToText[profile.investorProfile]}</p>
            </div>
            <div className='flex flex-row'>
                <div className='h-[300px] flex-1 mr-3'>
                    <WalletChartComponent values={values} />
                </div>
                <div className='flex-1 flex flex-col'>
                    {preservationTotal > 0 && (
                        <WalletSummary
                            title='Preservação'
                            total={preservationTotal}
                            accentColor='bg-report-100'
                            items={calculatedCategories
                                .filter(category => category.allocationStrategy === AllocationStrategy.Preservation)
                                .map(renderItems)
                            }
                        />
                    )}
                    {accumulationTotal > 0 && (
                        <WalletSummary
                            title='Acumulação'
                            total={accumulationTotal}
                            accentColor='bg-report-500'
                            items={calculatedCategories
                                .filter(category => category.allocationStrategy === AllocationStrategy.Accumulation)
                                .map(renderItems)
                            }
                        />
                    )}
                    {growthTotal > 0 && (
                        <WalletSummary
                            title='Crescimento'
                            total={growthTotal}
                            accentColor='bg-report-700'
                            items={calculatedCategories
                                .filter(category => category.allocationStrategy === AllocationStrategy.Growth)
                                .map(renderItems)
                            }
                        />
                    )}
                </div>
            </div>
        </div>
    )
}