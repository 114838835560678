import * as Yup from 'yup'
import moment, { type Moment } from "moment";



export function formatCurrency(value: number, currency = 'BRL') {
    return value.toLocaleString('pt-br', { style: 'currency', currency });
}

export function formatMoneyK(value: number) {
    if (value === 0) return '0'
    if (value >= 1000000) return (value / 1000000).toFixed(1) + 'M'
    return (value / 1000).toFixed(0) + 'k'
}

export function formatToLongDate(value: Date) {
    return value.toLocaleDateString('pt-br', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    })
}

export function formatDateToInputValue(value: Date = new Date()): string {
    var local = new Date(value);
    local.setMinutes(value.getMinutes() - value.getTimezoneOffset());
    return local.toJSON().slice(0, 10);
}

export function setTime(date: Date, hour: number, minute: number): Date {
    var result = new Date(date.getTime());
    result.setHours(hour)
    result.setMinutes(minute)
    // date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    return result;
}

export function baseToday(addDaysFromToday: number = 0): Date {
    var today = new Date();
    var newDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + addDaysFromToday)
    newDate.setMinutes(newDate.getMinutes() - newDate.getTimezoneOffset());
    return newDate;
}

export function compareDate(d1: Date, d2: Date) {
    return d1.getDate() === d2.getDate() &&
        d1.getMonth() === d2.getMonth() &&
        d1.getFullYear() === d2.getFullYear()
}

export function expandDatesUntil(
    start: Moment,
    until: Moment,
) {
    const result: Moment[] = []
    const pivot = start.clone()

    while (pivot.isBefore(until)) {
        result.push(pivot.clone())
        pivot.add(1, 'day')
    }

    return result;
}

export function convertInputToDate(date: string) {

    const [year, month, day] = date.split("-")
    return new Date(Number(year), Number(month) - 1, Number(day) || 1)

}

Yup.setLocale({
    mixed: {
        required: 'Este campo é obrigatório',
    },
    string: {
        email: 'Por favor, insira um e-mail válido',
        length: 'Este campo deve ter exatamente ${length} caracteres',
    },
    number: {
        min: 'O valor mínimo é ${min}',
        max: 'O valor máximo é ${max}',
    }
})

export { Yup }
export { moment }
