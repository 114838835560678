import { ErrorMessage, FormikProps } from 'formik'
import FieldGroup from './field_block'
import classNames from 'classnames'
import { InputNumber, type InputNumberProps, type InputNumberValueChangeEvent } from 'primereact/inputnumber';
import { CustomAllocationCurrencyType } from '@healthmoney/domain'

interface MoneyFieldProps extends InputNumberProps {
    label?: string
    step?: number,
    disabled?: boolean
    name: string,
    formik: FormikProps<any>,
    currency?: CustomAllocationCurrencyType
}

const MoneyField = ({ formik, name, label, disabled = false, step = 1, currency, ...props }: MoneyFieldProps) => {

    const target = formik.getFieldProps(name)

    return (
        <FieldGroup>
            <div className='flex flex-col flex-1 relative'>
                <label
                    className='text-xxs'
                    htmlFor={name}>{label}</label>

                <InputNumber
                    {...props}
                    disabled={disabled}
                    step={step || 1}
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    min={props.min || 0}
                    value={target.value}
                    placeholder={currency === 'usd' ? 'U$ 0,00' : 'R$ 0,00'}
                    locale={props.locale ?? 'pt-BR'}
                    mode="currency"
                    currency={currency?.toUpperCase() ?? 'BRL'}
                    onValueChange={(e: InputNumberValueChangeEvent) =>
                        formik.setFieldValue(name, e.value)
                    }
                    inputId={name}
                    className={classNames(
                        'appearance-none border-b w-full py-1 border-b-neutral-800',
                        'focus:outline-none focus:shadow-outline text-xs focus-visible:outline-none focus-visible:border-none',
                        {
                            'bg-[rgba(239,239,239,0.3)]': disabled
                        }
                    )}
                    inputStyle={{
                        border: 'none', padding: 0, outline: '2px solid transparent',
                        boxShadow: 'none', opacity: 1, background: disabled ? 'transparent' : ''
                    }}
                    inputClassName='appearance-none border-b w-full py-1 border-b-neutral-800 focus:outline-none focus:shadow-outline text-xs focus-visible:outline-none focus-visible:border-none focus:shadow-none'
                />
            </div>
            <ErrorMessage
                name={name!}
                component="span"
                className='text-danger-500 font-bold py-1' />
        </FieldGroup>
    )
}

export default MoneyField

// 