import { A } from "components";
import { createContext, useState } from "react";

type DropdownMenuProps = {
    children: JSX.Element[]
}

type DropdownMenuValue = string | null

type DropdownMenuState = {
    item: DropdownMenuValue,
    setItem: (item: DropdownMenuValue) => void
}

export const DropdownContext = createContext<DropdownMenuState>({ item: null, setItem: () => { } })

export default function DropdownMenu({
    children
}: DropdownMenuProps) {
    const [item, setItem] = useState<DropdownMenuValue>(null)

    return (
        <div className="bg-neutral-0 py-4">
            <div className='container mx-auto flex flex-col'>
                <DropdownContext.Provider value={{
                    item,
                    setItem: (item: DropdownMenuValue) => setItem(item)
                }}>
                    {item && (
                        <div
                            className="fixed w-full h-full z-20 top-0 left-0"
                            onClick={() => setItem(null)}>
                        </div>
                    )}
                    <div className='flex flex-row'>
                        {children}
                    </div>
                </DropdownContext.Provider>
            </div>
        </div>
    )
}

export function DropdownItem({
    title, link
}: {
    title: string,
    link: string
}) {
    return (
        <A href={link} className="block overflow-hidden p-2 mr-2"> {title} </A>
    )
}