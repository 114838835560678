import Loading from 'components/containers/loading'
import RegisterBoardingSuitability from '../steps/register-boarding-suitability'
import useRegistrationBoarding from '../useRegistrationBoarding'

const RegisterBoardingSuitabilityView = () => {
  const { loaded } = useRegistrationBoarding()

  return (
    <>
      <Loading isLoading={!loaded}>
        <RegisterBoardingSuitability />

      </Loading>
    </>
  )
}

export default RegisterBoardingSuitabilityView