import { CustomerDetails } from "@healthmoney/domain";
import {  setRegisterCreatedAt, setRegisterDetails, setRegisterMaxStep, useSetRegisterProcessing } from "repositories/customers-repository";
import { useContext } from "react";
import { SessionContext } from "providers/session";
import DetailsForm from "../../details/details-form";
import { useNavigate } from "react-router-dom";
import useRegistrationBoarding from "../useRegistrationBoarding";
import { moment } from 'helpers'

type ErrorProps = {
    details: {
        message: string
    }
    message: string
}

export default function RegisterBoardingDetails() {
    const session = useContext(SessionContext)
    const { user, maxStep } = useRegistrationBoarding()

    const navigate = useNavigate()

    const setDetails = setRegisterDetails()
    const setCreatedAt = setRegisterCreatedAt()
    const setMaxStep = setRegisterMaxStep()
    const setProcessing = useSetRegisterProcessing()

    const handleSubmit = async (details: CustomerDetails) => {
        try {

            details = { ...details, email: details.email?.toLocaleLowerCase() }

            const data: CustomerDetails = {
                address1: details.address1,
                address2: details.address2,
                birthdate: moment(details.birthdate).toDate(),
                children: details.children,
                city: details.city,
                cpf: details.cpf,
                email: details.email,
                fullName: details.fullName,
                insurance: details.insurance,
                marriageRegime: details.marriageRegime ?? "",
                maritialStatus: details.marriageRegime === "casado" ? details?.maritialStatus : "",
                number: details.number,
                phone: details.phone,
                postalCode: details.postalCode,
                state: details.state,
                cid: session.email,
            }
            await Promise.all([
                setDetails({
                    ...data,
                    cid: session.email
                }),
                details.email ? '' : setCreatedAt(details.email!),
                setMaxStep(details.email!, maxStep ? maxStep : 1),
                setProcessing(details.email!, false)
            ])

            navigate(`/registration/${ details.email }/income`)
        } catch (error) {
            const errorDetails = error as ErrorProps
            throw new Error(errorDetails?.details?.message || errorDetails.message)
        }
    }
    return (
        <div className="container mx-auto max-w-screen-lg">
            <DetailsForm
                initialValues={{
                    ...user?.details,
                }}
                onSubmit={handleSubmit}
            />
        </div>

    )
}