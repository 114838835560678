import classNames from "classnames";
import { CalendarItem } from "./calendar-item";
import { CalendarNote } from "./types";
import { compareDate } from "./helpers";

export function CalendarRow({
    month,
    year,
    dates,
    notes,
    onNoteClick,
}: {
    month: number,
    year: number,
    dates: Date[],
    notes: CalendarNote[],
    onNoteClick: (note: CalendarNote) => void
}) {
    return (
        <div className={classNames(
            'flex flex-row'
        )}>
            {dates.map((date, i) => (
                <CalendarItem
                    key={i}
                    month={month}
                    year={year}
                    date={date}
                    notes={notes.filter(n => compareDate(n.date, date))}
                    onNoteClick={onNoteClick}
                />
            ))}
        </div>
    )
}