import PageHeading from '../../../components/containers/page-heading'
import { useAllocationProfiles, useCategories } from '../../../repositories/allocations-repository'
import AssetAllocation from '../containers/asset-allocation'

import Loading from 'components/containers/loading'

export default function AllocationsDashboardView() {
    const categories = useCategories()
    const profiles = useAllocationProfiles()

    return (
        <Loading isLoading={profiles.status !== 'success'}>
            <PageHeading
                title='Perfil de Investidor'
                description='Visualize a distribuição de cada perfil de investidor e o histórico de alocações.' />
            <div className='bg-neutral-0 w-full'>
                <div className='container mx-auto py-8'>
                    <AssetAllocation
                        categories={categories.data}
                        profiles={profiles.data} />
                </div>
            </div>
        </Loading >
    )
}
