import { useMemo } from "react";

const fixTimezone = (year: number, month: number) => {
    const firstDayOfTheMonth = new Date(year, month, 1);
    var userTimezoneOffset = firstDayOfTheMonth.getTimezoneOffset() * 60000;
    return new Date(firstDayOfTheMonth.getTime() + userTimezoneOffset);
}

export default function useCalendarDates(year: number, month: number) {

    const dates = useMemo(() => {
        let result = []

        const firstDayOfTheMonth = fixTimezone(year, month)
        const beforeDate = new Date(firstDayOfTheMonth.getTime())

        for (let i = 0; i < firstDayOfTheMonth.getDay(); i++) {
            beforeDate.setDate(beforeDate.getDate() - 1)
            result.push(new Date(beforeDate.getTime()))
        }

        const lastDaysCount = result.length
        result.reverse()
        result.push(new Date(firstDayOfTheMonth.getTime()))
        let pivot = new Date(firstDayOfTheMonth.getTime())

        for (let i = 0; i < 42 - lastDaysCount; i++) {
            pivot.setDate(pivot.getDate() + 1)
            result.push(new Date(pivot.getTime()))
        }

        return result;
    }, [year, month])

    return dates;
}