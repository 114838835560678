import { AllocationCategory, AllocationEntry, AllocationInvestment, AllocationProfile, CustomAllocationEntry, ExclusiveAllocationEntry, } from '@healthmoney/domain'
import { collection, doc, addDoc, getDoc, updateDoc, deleteDoc, query, orderBy, Timestamp } from 'firebase/firestore'
import { useFirestore, useFirestoreCollectionData, useFirestoreDocData } from 'reactfire'

export function useCategories() {
    const db = useFirestore()

    const observableStatus = useFirestoreCollectionData(
        query(
            collection(db,
                'core_allocations',
                'default',
                'core_allocations_categories',
            ),
            orderBy('name', 'asc')
        )
    )

    return {
        ...observableStatus,
        data: (observableStatus.data || []) as AllocationCategory[]
    }
}

export function useGetAllocationProfile() {
    const db = useFirestore()

    return async (id: string) => {
        const profileReference = doc(db,
            'core_allocations',
            'default',
            'core_allocations_profiles',
            id
        )

        const observableStatus = await getDoc(
            profileReference
        )

        return observableStatus.data() as AllocationProfile
    }
}

export function useAllocationProfiles() {
    const db = useFirestore()

    const observableStatus = useFirestoreCollectionData(
        query(
            collection(db,
                'core_allocations',
                'default',
                'core_allocations_profiles'
            ),
            orderBy('position', 'asc')
        )
    )

    return {
        ...observableStatus,
        data: (observableStatus.data || []) as AllocationProfile[]
    }
}

export function useAllocationProfile(id: string) {
    const db = useFirestore()

    const profileReference = doc(db,
        'core_allocations',
        'default',
        'core_allocations_profiles',
        id
    )

    const observableStatus = useFirestoreDocData(
        profileReference
    )

    return observableStatus
}

export function useExclusiveIndications(uid: string) {
    const db = useFirestore()

    const reference = collection(db,
        'core_customers',
        uid,
        'core_customers_indications'
    )

    const observableStatus = useFirestoreCollectionData(
        reference, {
        idField: 'id'
    })

    return observableStatus
}

export function useCreateExclusiveIndication(uid: string) {
    const db = useFirestore()

    return async (allocation: CustomAllocationEntry) => {
        const reference = collection(db,
            'core_customers',
            uid,
            'core_customers_indications'
        )

        await addDoc(reference, {
            ...allocation,
            contributionDate: Timestamp.fromDate(new Date(allocation.contributionDate)),
            recomendationDate: Timestamp.fromDate(new Date(allocation.recomendationDate))
        })
    }
}

export function useCreateAllocationProfile() {
    const db = useFirestore()

    return async (allocationProfile: AllocationProfile) => {
        const reference = collection(db,
            'core_allocations',
            'default',
            'core_allocations_profiles',
        )

        await addDoc(reference, allocationProfile)
    }
}

export function useUpdateAllocationProfile() {
    const db = useFirestore()

    return async (allocationProfile: AllocationProfile) => {
        const reference = doc(db,
            'core_allocations',
            'default',
            'core_allocations_profiles',
            allocationProfile.id!
        )

        await updateDoc(reference, allocationProfile)
    }
}

// Investments

export function useInvestmentsStandards() {
    const db = useFirestore()

    const observableStatus = useFirestoreCollectionData(
        query(
            collection(db,
                'core_allocations',
                'default',
                'core_allocations_investments',
            ),
            orderBy('name', 'asc')
        )
    )

    return {
        ...observableStatus,
        data: (observableStatus.data || []) as AllocationInvestment[]
    }
}

export function useSetClassification() {
    const db = useFirestore()

    return async (standardId: string, categoryId: string) => {
        const reference = doc(db,
            'core_allocations',
            'default',
            'core_allocations_investments',
            standardId
        )

        await updateDoc(reference, { categoryId })
    }
}


// Allocation Entry

export function useAllocationEntries() {
    const db = useFirestore()

    const reference = collection(db,
        'core_allocations',
        'default',
        'core_allocations_indications'
    )

    const observableStatus = useFirestoreCollectionData(
        reference, {
        idField: 'id'
    }
    )

    return {
        ...observableStatus,
        data: (observableStatus.data || []) as AllocationEntry[]
    }
}

export function useCreateAllocationEntry() {
    const db = useFirestore()

    return async (allocationEntry: AllocationEntry) => {
        const reference = collection(db,
            'core_allocations',
            'default',
            'core_allocations_indications',
        )

        await addDoc(reference, {
            ...allocationEntry,
            contributionDate: Timestamp.fromDate(new Date(allocationEntry.contributionDate)),
            recomendationDate: Timestamp.fromDate(new Date(allocationEntry.recomendationDate)),
            createdAt: Timestamp.now()
        })
    }
}

export function useUpdateAllocationEntry() {
    const db = useFirestore()

    return async (allocationEntry: AllocationEntry) => {
        const reference = doc(db,
            'core_allocations',
            'default',
            'core_allocations_indications',
            allocationEntry.id!
        )

        await updateDoc(reference, allocationEntry)
    }
}

export function useDeleteAllocationEntry() {
    const db = useFirestore()

    return async (allocationEntry: AllocationEntry) => {
        const reference = doc(db,
            'core_allocations',
            'default',
            'core_allocations_indications',
            allocationEntry.id!
        )

        await deleteDoc(reference)
    }
}

export function useDeleteCustomAllocationEntry(uid: string) {
    const db = useFirestore()

    return async (allocationEntry: ExclusiveAllocationEntry) => {
        const reference = doc(db,
            'core_customers',
            uid,
            'core_customers_indications',
            allocationEntry.id!
        )

        await deleteDoc(reference)
    }
}