import classNames from "classnames";
import { Link, LinkProps } from "react-router-dom";
import { LinkHTMLAttributes } from "react";
import { getButtonClasses } from "../inputs/button";

interface LinkExtensionProps extends LinkHTMLAttributes<HTMLAnchorElement> {
    highlighted?: boolean,
    button?: boolean
    buttonSecondary?: boolean
    buttonCompact?: boolean
    target?: string
}

export default function A(props: LinkExtensionProps) {
    const {
        highlighted,
        button,
        buttonSecondary,
        buttonCompact,
        ...others
    } = props

    const styles = button
        ? getButtonClasses(props.className, {
            secondary: buttonSecondary,
            compact: buttonCompact
        })
        : classNames(
            props.className,
            highlighted ? 'text-primary-700' : ''
        )

    return (
        <Link
            {...others as LinkProps}
            to={props.href!}
            className={styles}
        />
    )
}