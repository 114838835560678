import { ButtonHTMLAttributes } from "react"
import classNames from 'classnames'

interface ButtonProps extends ButtonHTMLAttributes<any> {
    secondary?: boolean,
    compact?: boolean
}

export function getButtonClasses(otherClasses: string = '', {
    secondary,
    compact,
}: ButtonProps): string {
    return classNames(
        'rounded border text-xs font-medium flex justify-center items-center leading-near',
        secondary
            ? 'bg-neutral-0 border-neutral-900 hover:bg-neutral-300 active:bg-neutral-100 disabled:bg-neutral-100 disabled:border-neutral-100'
            : 'bg-primary-500 border-primary-900 hover:bg-primary-100 hover:border-primary-500 active:bg-primary-700 disabled:bg-neutral-400 disabled:border-neutral-400',
        compact ? 'py-2 px-3' : 'py-3 px-4',
        otherClasses,
    )
}

export default function Button(props: ButtonProps) {
    const { secondary, compact, ...others } = props
    return (
        <button {...others}
            className={getButtonClasses(props.className, {
                secondary, compact
            })}>
            {props.children}
        </button>
    )
}