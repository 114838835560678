import CustomerAccountView from "./features/account/customer-account-view";
import AddCustomAllocationEntryView from "../allocations/views/add-custom-allocation-entry-view";
import CustomAllocationsDashboardView from "../allocations/views/custom-allocations-dashboard-view";
import CustomerDashboardView from "./features/dashboard/customer-dashboard-view";
import CustomerDetailsView from "./features/details/customer-details-view";
import CustomerIncomeView from "./features/income/customer-income-view";
import CreateCustomerInvestmentView from "./features/investments/create-customer-investment-view";
import CustomerInvestmentsView from "./features/investments/customer-investments-view";
import EditCustomerInvestmentView from "./features/investments/edit-customer-investment-view";
import CreateProjectView from "./features/projects/create/create-project-view";
import RegisterBoardingProvider from "./features/register-boarding/register-boarding-provider";
import RegisterBoardingView from "./features/register-boarding/register-boarding-view";
import CreateTaskView from "./features/tasks/create/create-task-view";

export const customersRoutes = [
    {
        path: '/customers/:uid',
        element: <CustomerDashboardView />,
    },
    {
        path: '/customers/:uid/details',
        element: <CustomerDetailsView />,
    },
    {
        path: '/customers/:uid/allocations',
        element: <CustomAllocationsDashboardView />,
    },
    {
        path: '/customers/:uid/allocations/create',
        element: <AddCustomAllocationEntryView />,
    },
    {
        path: '/customers/:uid/income',
        element: <CustomerIncomeView />,
    },
    {
        path: '/customers/:uid/account',
        element: <CustomerAccountView />,
    },
    {
        path: '/customers/:uid/investments',
        element: <CustomerInvestmentsView />,
    },
    {
        path: '/customers/:uid/investments/:iid',
        element: <EditCustomerInvestmentView />,
    },
    {
        path: '/customers/:uid/investments/create',
        element: <CreateCustomerInvestmentView />,
    },
    {
        path: '/customers/:uid/tasks/create',
        element: <CreateTaskView />,
    },
    {
        path: '/customers/:uid/projects/create',
        element: <CreateProjectView />,
    },
    {
        path: '/customers/boarding/:prid?',
        element: (
            <RegisterBoardingProvider>
                <RegisterBoardingView />
            </RegisterBoardingProvider>
        ),
    },
]
