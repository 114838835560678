import { PageHeading } from 'components'
import { stepsTemplate } from './constants'
import useRegistrationBoarding from './useRegistrationBoarding'

const RegisterPageHeading = () => {

  const { location, uid, maxStep, user } = useRegistrationBoarding()

  return (
    <>
      <PageHeading
        shorter
        title={stepsTemplate[location].title}
        description={stepsTemplate[location].description}
        content={stepsTemplate[location].content}
        processing={user?.processing}
        breadcrumb={{ homeLink: '/', items: [{ text: 'Continuar cadastro' }] }}
        stepper={{
          currentIndex: stepsTemplate[location].index,
          uid,
          maxStep,
          items: [
            { text: 'Cadastro', href: 'details' },
            { text: 'Renda', href: 'income' },
            { text: 'Perfil', href: 'suitability' },
            { text: 'Projetos', href: 'projects' },
            { text: 'CCV', href: 'cvv' },
            { text: 'Carteira', href: 'wallet' },
            { text: 'Produtos', href: 'products' },
            { text: 'Completo', href: 'complete' },
          ]
        }}
      />
    </>
  )
}

export default RegisterPageHeading
